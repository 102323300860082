import PaytmChecksum from '../../components/Paytm/paytmChecksum';
const https = require('https');

  export  const initialize = (callback,price,phone,user_id) => 
  {
        let orderId = 'CRS_DRU_WEB_'+new Date().getTime();
        let mobile = phone;
        let userid= user_id;
        let amount =price;
            
        // Sandbox Credentials
          let mid = process.env.REACT_APP_PAYTM_MID_KEY_LIVE; // Merchant ID
          let mkey = process.env.REACT_APP_PAYTM_M_KEY_LIVE; // Merhcant Key

          // let mid = process.env.REACT_APP_PAYTM_MID_KEY_DEV; // Merchant ID
          // let mkey = process.env.REACT_APP_PAYTM_M_KEY_DEV; // Merhcant Key

        var paytmParams = {};
    
        paytmParams.body = {
         "requestType": "Payment",
         "mid": mid,
         "websiteName" : "DEFAULT",
         "orderId": orderId,
         "callbackUrl" : "https://securegw.paytm.in/theia/paytmCallback?ORDER_ID="+orderId,
         "txnAmount": {
         "value" : price,
         "currency": "INR",
         },
         "userInfo": {
         "custId": userid,
         "mobile":mobile
         }
        };
    
        PaytmChecksum.generateSignature(JSON.stringify(paytmParams.body), mkey).then(function(checksum){
        paytmParams.head = {
           "signature": checksum
         };
    
        var post_data = JSON.stringify(paytmParams);
        
    
        var options = {
        /* for Staging */
         hostname: 'securegw.paytm.in',
    
        /* for Production */
        // hostname: 'securegw-stage.paytm.in',
         port: 443,
         path: `/theia/api/v1/initiateTransaction?mid=${mid}&orderId=${orderId}`,
         method: 'POST',
         headers: {
         'Content-Type': 'application/json',
         'Content-Length': post_data.length
         }
         };
    
         var response = "";
         var post_req = https.request(options, function(post_res) {
         post_res.on('data', function (chunk) {
         response += chunk;
        });


        post_res.on('end', function(){
            // res.json({data: JSON.parse(response), orderId: orderId, mid: mid, amount: amount});
            callback({
                token: JSON.parse(response).body.txnToken, 
                order: orderId, 
                mid: mid,
                amount: amount
            })
         });
         });
    
         post_req.write(post_data);
         post_req.end();
        });
    }
    
   export const makePayment = (paymentData,callback) => {
            var config = {
                "root":"",
                "style": {
                  "bodyBackgroundColor": "#fafafb",
                  "bodyColor": "",
                  "themeBackgroundColor": "#0FB8C9",
                  "themeColor": "#ffffff",
                  "headerBackgroundColor": "#284055",
                  "headerColor": "#ffffff",
                  "errorColor": "",
                  "successColor": "",
                  "card": {
                    "padding": "",
                    "backgroundColor": ""
                  }
                },
                "data": {
                  "orderId": paymentData.order,
                  "token": paymentData.token,
                  "tokenType": "TXN_TOKEN",
                  "amount": paymentData.amount /* update amount */
                },
                "payMode": {
                  "labels": {},
                  "filter": {
                    "exclude": []
                  },
                  "order": [
                      "CC",
                      "DC",
                      "NB",
                      "UPI",
                      "PPBL",
                      "PPI",
                      "BALANCE"
                  ]
                },
                "website": "DEFAULT",
                "flow": "DEFAULT",
                "merchant": {
                  "mid": paymentData.mid,
                  "redirect": false
                },
                "handler": {
                  "transactionStatus":function transactionStatus(paymentStatus){
                    document.getElementById('paytm-checkoutjs').style.display='none'; 
                    callback(paymentStatus,true)
                  },
                  "notifyMerchant":function notifyMerchant(eventName,data){
                    // setLoading(true);
                    callback({},false)
                    console.log("Closed");
                  }
                }
            };
          
        if (window.Paytm && window.Paytm.CheckoutJS) {
            // initialze configuration using init method
            window.Paytm.CheckoutJS.init(config).then(function onSuccess() {
                // after successfully update configuration invoke checkoutjs
                window.Paytm.CheckoutJS.invoke();
            }).catch(function onError(error) {
                console.log("Error => ", error);
            });
        }
    }

    // const check_status = (paymentStatus) =>{

    //   // if(paymentStatus['STATUS']=='TXN_SUCCESS'){
    //     console.log(paymentStatus);
    //     // navigate('/payment-verfication')
    //   // }
    // }



export default {initialize,makePayment};