/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import jQuery from "jquery";
import { Helmet } from "react-helmet";
import LearnEnglish from "./LearnEnglish";
import Batches from "./Batches";
import Products from "./Products";
import Resources from "./Resources";
import Enroll from "./Enroll";
import Students from "./Students";
import Hero from "./Hero";

const Home = () => {
  if (typeof window !== "undefined") {
    window.jQuery = jQuery;
  }

  return (
    <div>
      <>
        <Helmet>
          <title>
            Dhurina - India's Top Government Job Preparation platform
          </title>
          <meta
            name="robots"
            content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
          />
          <link rel="canonical" href="https://dhurina.net/" />
          <meta
            name="title"
            content="Dhurina - India's Top Government Job Preparation platform"
          />
          <meta
            name="description"
            content="Prepare for competitive exam with Dhurina. Dhurina the top affordable competitive exam preparation platform provides you high quality content. Best Exam Preparation App.."
          />
          <meta name="publish-date" content="2022-04-22T11:24:28+05:30" />
          <meta name="created-date" content="2022-12-14T11:24:28+05:30" />
          <meta name="modified-date" content="2022-12-14T11:24:29+05:30" />
          <meta
            name="twitter:description"
            content="Prepare for competitive exam with Dhurina. Dhurina the top affordable competitive exam preparation platform provides you high quality content. Best Exam Preparation App.."
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="Dhurina - India's Top Government Job Preparation platform"
          />
          <meta name="twitter:site" content="@Dhurinalive" />
          <meta name="twitter:creator" content="@Dhurinalive" />
          <meta name="twitter:app:id:googleplay" content="com.dhurina" />
          <meta name="twitter:app:id:iphone" content="1479858722" />
          <meta
            name="twitter:app:name:googleplay"
            content="Dhurina - Learning Made Easy"
          />
          <meta
            name="twitter:app:name:ipad"
            content="Dhurina - Learning Made Easy"
          />
          <meta
            name="twitter:app:name:iphone"
            content="Dhurina - Learning Made Easy"
          />
          <meta name="twitter:domain" content="dhurina.net" />
          <meta name="twitter:google-play-app" content="com.dhurina" />
          <meta
            property="og:description"
            content="Prepare for competitive exam with Dhurina. Dhurina the top affordable competitive exam preparation platform provides you high quality content. Best Exam Preparation App.."
          />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="webpage" />
          <meta
            property="og:title"
            content="Dhurina - India's Top Government Job Preparation platform"
          />
          <meta property="og:url" content="https://dhurina.net/" />
          <meta property="og:site_name" content="Dhurina" />
          <meta
            property="og:updated_time"
            content="2022-01-28T13:36:39+05:30"
          />
          <meta
            property="article:published_time"
            content="2022-04-22T05:54:28.000Z"
          />
          <meta
            property="article:modified_time"
            content="2022-04-22T05:54:29.000Z"
          />
          <meta property="article:section" content="Home" />
          <meta
            property="article:publisher"
            content="https://www.facebook.com/dhurinalive"
          />
          <meta
            property="article:author"
            content="https://fb.com/dhurinalive"
          />
          <meta property="al:android:url" content="https://dhurina.net/" />
          <meta
            property="al:android:app_name"
            content="Dhurina - Learning Made Easy"
          />
          <meta property="al:android:package" content="com.dhurina" />
          <meta property="fb:app_id" content="facebook-app-id-in-number" />
          <meta property="fb:pages" content="facebook-page-in-number" />
        </Helmet>

        <Hero />
        <LearnEnglish />
        <Batches />
        <Products />
        <Resources />
        <Enroll />
        <Students />
      </>
    </div>
  );
};

export default Home;
