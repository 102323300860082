import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ApiCall from "../../api/callApi";
import {
  Col,
  Row,
  Image,
  Modal,
  Button,
} from "react-bootstrap";
const Image_URL = process.env.REACT_APP_Bucket_URL;

const AttemptTestSeries = ({ setLoginPopup }) => {
  const [testSerie, setTestSerie] = useState("");
  const [mainloading, setMainLoading] = useState(true);
  const navigate = useNavigate();
  const [testData, setTestData] = useState({
    id: "",
    name: "",
    instruction: "",
  });
  const [instructionmodelStatus, SetInstructionModelStatus] = useState(false);
  const [instructions, SetInstructions] = useState("");
  useEffect(() => {
    if (testSerie && testSerie.purchase_status === "false") {
      navigate(-1);
    }
  }, [testSerie]);

  const { id } = useParams();
  useEffect(() => {
    const body = {
      test_series_id: id,
    };
    ApiCall(body, "post", "test_series_details", test_series_details);
  }, [id]);
  const test_series_details = useCallback((response) => {
    setMainLoading(false);
    if (response.data.status === 200) {
      setTestSerie(response.data.combos);
    } else {
      console.log("error");
    }
  });
  const showInstructions = (id, name, instruction) => {
    setTestData({ id: id, name: name, instruction: instruction });
    SetInstructionModelStatus(true);
  };
  if (mainloading === true) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <img
          src={Image_URL + "website/priloader_icon.svg"}
          style={{ alignSelf: "center" }}
        />{" "}
      </div>
    );
  }
  return (
    <>
      <section className="py-5">
        <div className="container custom_container">
          <div className="row">
            {testSerie &&
              testSerie.tests.map((item, index) => {
                return item.price == 0 ? (
                  <div key={index} className="col-lg-4 col-sm-6 mt-3">
                    <div className="mock_box mx-3 p-3 d-flex flex-column justify-content-between">
                      <div className="mb-0">
                        <div key={index}>
                          <h2 className="ff_inter fw-bold text_gradient fs-4 mb-0">
                            {item.name}
                          </h2>

                          <p className="mb-0 text_grey border_brown_bottom pb-3 ff_inter mt-1"></p>
                        </div>
                      </div>
                      <div>
                        <div>
                          <div>
                            <div className="d-flex justify-content-between">
                              <p className="mb-0 mt-2 ff_inter text_grey fw-semibold">
                                Questions
                              </p>
                              <p className="mb-0 mt-2 ff_inter text_grey fw-semibold">
                                {item.no_of_questions}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="mb-0 ff_inter text_grey fw-semibold">
                                Max Marks
                              </p>
                              <p className="mb-0 ff_inter text_grey fw-semibold">
                                {item.marks}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="mb-0 ff_inter text_grey fw-semibold">
                                Time
                              </p>
                              <p className="mb-0 ff_inter text_grey fw-semibold">
                                {item.duration} Mins
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="text-center col-12">
                          <button
                            onClick={() => {
                              SetInstructions("Test should be submit on time.");
                              showInstructions(
                                item.id,
                                item.name,
                                "Test should be submit on time."
                              );
                            }}
                            className="ff_inter bg_gradient mt-3 px-4 py-1 text-white border-0 fs-6 bg-transparent"
                          >
                            Attempt
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                );
              })}
          </div>
        </div>
      </section>
      <Modal
        size="lg"
        id="switchpopup"
        show={instructionmodelStatus}
        onHide={() => SetInstructionModelStatus(false)}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row className="align-items-center">
            <Col md={12} className="switchlnguageox">
              <h4 className="f-25 mb-0 f-22mob">Instructions</h4>
              <ol type="1" className="ps-0">
                <li>
                  1. There is only one correct answer to each question. Click on
                  the most appropriate option to mark it as your answer.
                </li>
                <li>
                  2. You can change your answer by clicking on some other
                  option.
                </li>
                <li>
                  3. You can move on the next question by clicking on
                  the"Next"button.
                </li>
                <li>
                  4. You can access the questions in any order within a section
                  or across sections by clicking on the question number given on
                  the number list.
                </li>
                <li>
                  5. You can use rough sheets while taking the test. Do not use
                  calculators, log tables, dictionaries, or any other
                  printed/online reference material during the test.
                </li>
                <li>
                  6. Do not click the button "Submit Test" before
                  completing the test.
                </li>
              </ol>

              <div className="text-center">
                <Button
                  variant="base-light"
                  className="ff_inter bg_gradient mt-3 px-4 py-1 text-white border-0 fs-6 bg-transparent rounded-4"
                  onClick={() => {
                    navigate("/play-test", { state: testData });
                    window.scrollTo(0, 0);
                  }}
                >
                  Play Test
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AttemptTestSeries;
