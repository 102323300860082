import React, { useState, useCallback, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ApiCall from "../../api/callApi";
const Image_URL = process.env.REACT_APP_Bucket_URL;

const Careers = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const formData = {
    firstName: "",
    lastname: "",
    select: "",
    phonenumber: "",
    email: "",
    totalexperience: "",
    Location: "",
    file: "",
  };
  const [value, setvalue] = useState(formData);

  const submited = async (e) => {
    e.preventDefault();

    if (
      value.firstName !== "" &&
      value.lastname !== "" &&
      value.select !== "" &&
      value.phonenumber !== "" &&
      value.email !== "" &&
      value.totalexperience !== "" &&
      value.Location !== "" &&
      value.file !== ""
    ) {
      const formData = new FormData();
      formData.append("f_name", value.firstName);
      formData.append("l_name", value.lastname);
      formData.append("position", value.select);
      formData.append("phone", value.phonenumber);
      formData.append("email", value.email);
      formData.append("experience", value.totalexperience);
      formData.append("location", value.Location);
      formData.append("resume", value.file);

      try {
        await ApiCall(formData, "post", "job_apply", job_applyCallback);
      } catch (error) {
        console.error("Error submitting application:", error);
        alert("An error occurred. Please try again later.");
      }
    } else {
      alert("Please fill in all the required fields.");
    }

    setvalue(formData);
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const job_applyCallback = useCallback((response) => {
    if (response.status === 200) {
      console.log(response);
      const formData = {
        firstName: "",
        lastname: "",
        select: "",
        phonenumber: "",
        email: "",
        totalexperience: "",
        Location: "",
        file: "",
      };
      setvalue(formData);
      alert(response.data.message);
      // console.log(response.data.data);
    } else {
      console.log("API error:", response.status);
      // setNoDataFound(true);
    }
  });
  return (
    <>
      <div className="custom_container container py-5 my-4">
        <h2 className="fw-bold font_size text_gradient ff_inter text-center fs-1 mb-0">
          Start Your Career With Dhurina !
        </h2>
        <p className="ff_inter text-center text_dark_grey fs-5 mb-0 mt-3">
          Feel free to submit your resume to: 
          <a className="text_gradient fs-5" href="mailTo:careers@dhurina.net">
            careers@dhurina.net
          </a>
        </p>
        <div className="custom_image_class mt-5 mx-auto">
          <img
            className="w-100"
            src={Image_URL + "website/career_image.png"}
            alt="bpsc_crack"
          />
        </div>
        <div className="careers_box mx-auto ">
          <h6 className="ff_inter text_dark_grey fs-6 fs-normal fw-semibold mb-0">
            Dhurina is Hiring Teachers for an Online Education Platform.
          </h6>
          <h6 className="ff_inter text_dark_grey fs-6 fs-normal fw-semibold mt-4">
            Personal Details
          </h6>
          <form onSubmit={(e) => submited(e)} action="">
            <div className="row mt-4">
              <div className="col-md-6">
                <input
                  required
                  className="w-100 careers_input"
                  type="text"
                  placeholder="First Name"
                  onChange={(e) =>
                    setvalue({ ...value, firstName: e.target.value })
                  }
                  value={value.firstName}
                />
              </div>
              <div className="col-md-6 mt-4 mt-md-0">
                <input
                  required
                  className="w-100 careers_input"
                  type="text"
                  placeholder="Last Name"
                  onChange={(e) =>
                    setvalue({ ...value, lastname: e.target.value })
                  }
                  value={value.lastname}
                />
              </div>
            </div>
            <div className="w-100 mt-4">
              <input
                required
                className="w-100 careers_input"
                type="number"
                placeholder="+(91) Phone Number"
                onChange={(e) =>
                  setvalue({ ...value, phonenumber: e.target.value })
                }
                value={value.phonenumber}
              />
            </div>
            <div className="w-100 mt-4">
              <input
                required
                className="w-100 careers_input"
                type="email"
                placeholder="Email"
                onChange={(e) => setvalue({ ...value, email: e.target.value })}
                value={value.email}
              />
            </div>
            <h6 className="ff_intfs-4 fw-semibold mb-0 mt-4">
              Which positions do you want to apply
            </h6>

            <div className="w-100 mt-4">
              <div>
                <select
                  className="w-100 careers_input custom_padding text_size"
                  name=""
                  id=""
                  onChange={(e) =>
                    setvalue({ ...value, select: e.target.value })
                  }
                  value={value.select}
                >
                  <option className="text_size pt-2" value="Select">
                    Select
                  </option>
                  <option className="text_size pt-2" value="BDA">
                    BDA
                  </option>
                  <option
                    className="text_size pt-2"
                    value="Social Media Handler"
                  >
                    Social Media Handler
                  </option>
                  <option className="text_size pt-2" value="Content Creator">
                    Content Creator
                  </option>
                  <option className="text_size pt-2" value="Video Editor">
                    Video Editor
                  </option>
                  <option className="text_size pt-2" value="Graphic Designer">
                    Graphic Designer
                  </option>
                  <option
                    className="text_size pt-2"
                    value="Customer Support Team"
                  >
                    Customer Support Team
                  </option>
                  <option className="text_size pt-2" value="Backend Operations">
                    Backend Operations
                  </option>
                  <option className="text_size pt-2" value="Studio Technician">
                    Studio Technician
                  </option>
                  <option
                    className="text_size pt-2"
                    value="Academic Counsellor"
                  >
                    Academic Counsellor
                  </option>
                  <option
                    className="text_size pt-2"
                    value="Offline sales person - Field Sales"
                  >
                    Offline sales person - Field Sales
                  </option>
                  <option
                    className="text_size pt-2"
                    value="Operational Manager."
                  >
                    Operational Manager.
                  </option>
                  <option className="text_size pt-2" value="HR executive">
                    HR executive
                  </option>
                  <option
                    className="text_size pt-2"
                    value="Social Media Manager"
                  >
                    Social Media Manager
                  </option>
                  <option className="text_size pt-2" value="BDM">
                    BDM
                  </option>
                  <option className="text_size pt-2" value="Online Bidder">
                    Online Bidder
                  </option>
                  <option className="text_size pt-2" value="SEO Specialist">
                    SEO Specialist
                  </option>
                </select>
              </div>
              <div className="w-100 mt-4">
                <input
                  required
                  className="w-100 careers_input"
                  type="text"
                  placeholder="Total Experience"
                  onChange={(e) =>
                    setvalue({ ...value, totalexperience: e.target.value })
                  }
                  value={value.totalexperience}
                />
              </div>
              <div className="w-100 mt-4">
                <input
                  required
                  className="w-100 careers_input"
                  type="text"
                  placeholder="Current Location"
                  onChange={(e) =>
                    setvalue({ ...value, Location: e.target.value })
                  }
                  value={value.Location}
                />
              </div>
              {/* <div className="w-100 d-flex careers_input justify-content-between mt-4">
                <input
                  className="cursor_pointer"
                  id="fileInput"
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => setvalue({ ...value, file: e.target.value })}
                  value={value.file}
                />
                <div
                  className="w-100 cursor-pointer"
                  onClick={openFileInput}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="mr-2 text_size">
                    {selectedFile ? selectedFile.name : "Upload File"}
                  </span>
                  <img
                    className="cursor_pointer"
                    width={40}
                    height={10}
                    src="https://cutewallpaper.org/24/download-icon-png/download-from-cloud-comments-download-from-cloud-icon-free-transparent-png-clipart-images-download.png"
                    alt="Download"
                  />
                </div>
              </div> */}
              <input
                className="careers_input w-100 mt-4"
                type="file"
                accept=".pdf"
                // style={{ display: "none" }}
                onChange={(e) =>
                  setvalue({ ...value, file: e.target.files[0] })
                }
              />
              <p className="ff_inter text_dark_grey fs-6 fs-normal mb-0 mt-3">
                Candidate can visit for walk-in : Monday to Saturday 10 Am to 5
                Pm
              </p>
              <p className="ff_inter text_dark_grey fs-6 fs-normal mb-0 mt-3">
                Note : Please Bring a copy of Your Resume.{" "}
              </p>
              <p className="ff_inter text_dark_grey fs-6 fs-normal mb-0 mt-3">
                Candidate Age Limit - 35 years (Maximum)
              </p>
              <p className="ff_inter text_dark_grey fs-6 fs-normal mb-0 mt-3">
                Job Type : Full Time (6 to 8 Hr.)
              </p>
              <p className="ff_inter text_dark_grey fs-6 fs-normal mb-0 mt-3">
                Job Location - Hisar / Gurugram / Jaipur ( from Office )
              </p>
              <p className="ff_inter text_dark_grey fs-6 fs-normal mb-0 mt-3">
                Freshers can Also Apply
              </p>
              <p className="ff_inter text_dark_grey fs-6 fs-normal mb-0 mt-3">
                Salary : Best in Industry
              </p>
              <p className="ff_inter text_dark_grey fs-6 fs-normal mb-0 mt-3">
                Office Address -
              </p>
              <p className="ff_inter text_dark_grey fs-6 fs-normal mb-0 mt-3">
                Dhurina,
              </p>
              <p className="ff_inter text_dark_grey fs-6 fs-normal mb-0 mt-3">
                Dhurina Ventures Pvt Ltd. - DSS - 113, Ground Floor, Kaimri-
                Mangali Rd, Sector 15A, Hisar, Haryana 125001
              </p>
              <p className="ff_inter text_dark_grey fs-6 fs-normal mb-0 mt-3">
                Mob.: +(91) 88378-05325
              </p>
              <p className="ff_inter text_dark_grey fs-6 fs-normal mb-0 mt-3">
                Dhurina Ventures Pvt Ltd. - Ground Floor, MGF Metropolis Mall,
                Mehrauli-Gurgaon Rd, Gurugram- Haryana 122002
              </p>
              <p className="ff_inter text_dark_grey fs-6 fs-normal mb-0 mt-3">
                Google Map Link for address -
              </p>

              <a
                className=""
                href="https://www.google.com/maps/place/Dhurina+Ventures+Pvt+Ltd/@29.133342,75.716735,17z/data=!3m1!4b1!4m6!3m5!1s0x391233f7172f6297:0x94e634dcf13a1a0c!8m2!3d29.1333373!4d75.7193099!16s%2Fg%2F11t7vqk9rf?entry=ttu"
                target="_blank"
              >
                <p className="text_gradient mt-1 fs-6 fs-normal cursor_pointer">
                  https://www.google.com/maps/place/Dhurina+Ventures+Pvt+Ltd/@29.133342,75.716735,17z/data=!3m1!4b1!4m6!3m5!1s0x391233f7172f6297:0x94e634dcf13a1a0c!8m2!3d29.1333373!4d75.7193099!16s%2Fg%2F11t7vqk9rf?entry=ttu
                </p>
              </a>

              <button
                type="submit"
                className="mt-4 btn_login text-white w-100 ff_inter bg_gradient border-0 p-2 px-lg-3 py-lg-2"
              >
                Apply Now
              </button>
            </div>
          </form>
        </div>
        <h2 className="fw-bold font_size text_gradient ff_inter text-center fs-3 mb-4 mt-5">
          Life at Dhurina !
        </h2>
        <Slider {...settings}>
          <div className="px-2">
            <img
              className="custom_image"
              src={Image_URL + "website/career image 1.jpg"}
              alt="bpsc_crack"
            />
          </div>
          <div className="px-2">
            <img
              className="custom_image"
              src={Image_URL + "website/career image 2.jpg"}
              alt="bpsc_crack"
            />
          </div>

          <div className="px-2">
            <img
              className="custom_image"
              src={Image_URL + "website/career image 6.jpg"}
              alt="bpsc_crack"
            />
          </div>
          <div className="px-2">
            <img
              className="custom_image"
              src={Image_URL + "website/career image 4.jpg"}
              alt="bpsc_crack"
            />
          </div>

          <div className="px-2">
            <img
              className="custom_image"
              src={Image_URL + "website/career slider image 1.png"}
              alt="bpsc_crack"
            />
          </div>
          <div className="px-2">
            <img
              className="custom_image"
              src={Image_URL + "website/career slider image 2.png"}
              alt="bpsc_crack"
            />
          </div>

          <div className="px-2">
            <img
              className="custom_image"
              src={Image_URL + "website/career image 7.jpg"}
              alt="bpsc_crack"
            />
          </div>
          <div className="px-2">
            <img
              className="custom_image"
              src={Image_URL + "website/career image 8.jpg"}
              alt="bpsc_crack"
            />
          </div>

          <div className="px-2">
            <img
              className="custom_image"
              src={Image_URL + "website/career image 9.jpg"}
              alt="bpsc_crack"
            />
          </div>
          <div className="px-2">
            <img
              className="custom_image"
              src={Image_URL + "website/career image 10.jpg"}
              alt="bpsc_crack"
            />
          </div>

          <div className="px-2">
            <img
              className="custom_image"
              src={Image_URL + "website/career image 12.jpg"}
              alt="bpsc_crack"
            />
          </div>
          <div className="px-2">
            <img
              className="custom_image"
              src={Image_URL + "website/career image 18.jpg"}
              alt="bpsc_crack"
            />
          </div>

          <div className="px-2">
            <img
              className="custom_image"
              src={Image_URL + "website/career slider image 3.png"}
              alt="bpsc_crack"
            />
          </div>
          <div className="px-2">
            <img
              className="custom_image"
              src={Image_URL + "website/career slider image 4.png"}
              alt="bpsc_crack"
            />
          </div>

          <div className="px-2">
            <img
              className="custom_image"
              src={Image_URL + "website/career image 13.jpg"}
              alt="bpsc_crack"
            />
          </div>
          <div className="px-2">
            <img
              className="custom_image"
              src={Image_URL + "website/career slider image 5.png"}
              alt="bpsc_crack"
            />
          </div>
        </Slider>
      </div>
    </>
  );
};

export default Careers;
