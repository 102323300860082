import { useState, useEffect, React } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Container, Col, Row, Image, Modal } from "react-bootstrap";
import MainLogo from "./../../assets/img/logo.png";
import FailedImage from "./../../assets/img/failed.png";
import SuccessImage from "./../../assets/img/success.png";
import Applink from "./../../include/applink/googleApple";

const PaymentVerification = () => {
  let location = useLocation();
  const [popup, setPopUp] = useState(false);

  useEffect(() => {
    if (
      location.state.transaction_status == 1 &&
      location.state.course_name != "Dhurina collabration with you"
    ) {
      setPopUp(true);
    }
    window.scrollTo(0, 0);
  }, []);

  const hideModal = () => {
    setPopUp(false);
  };

  // set datalayer google tags
  window.dataLayer.push({
    event: "payment_success",
    price: location.state.amount,
    order_value: location.state.amount,
    order_id: location.state.txnid,
    currency: "INR",
  });

  return (
    <>
      <section className="pt-3 map-bg">
        <Container>
          <Row>
            <Col md={12}>
              <div
                className="card-price-menu"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <div className="card-price-menu__item card-price--33">
                  <div className="card-price-top">
                    {location.state.transaction_status === 1 ? (
                      <div className="card-price-body">
                        <div className="card-price-top__nature">
                          Transaction Detail
                        </div>
                        <Image src={SuccessImage} />
                        <div className="card-price-body__log">
                          Course : {location.state.course_name}
                        </div>
                        <div className="card-price-body__log">
                          Transaction Id : {location.state.txnid}
                        </div>
                        <div className="card-price-body__log">
                          Amount : {location.state.amount}
                        </div>
                        {location.state.course_name !=
                        "Dhurina collabration with you" ? (
                          <div className="card-price-body__button">
                            <Link to="/" className="btn btn-warning mt-3">
                              Go Home
                            </Link>
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <Image src={FailedImage} />
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Modal size="md" id="" show={popup} onClick={hideModal}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row className="align-items-center">
            <Col md={12} style={{ textAlign: "center" }}>
              <Image alt="img" src={MainLogo} className="mb-2" />
            </Col>
          </Row>
          <br />
          <Row className="justify-content-center align-items-center">
            <Col
              md={12}
              className="downloadappbtnmob mb-4 schoolinner-mob-app"
              style={{ textAlign: "center" }}
            >
              <div className="section-title mb-4 lefth2border">
                <h6>How To Use Course</h6>
                <p style={{ textAlign: "left", fontWeight: 600 }}>
                  1. Download Dhurina App from Playstore and AppStore
                  <br />
                  2. Login with Your Course Purchased Mobile No. and Go to
                  MyCourse Section.
                  <br />
                  3. How To Use{" "}
                </p>
                <br />
                <h3 className="f-15 mb-4 mb-sm-5">
                  Install Dhurina Mobile App to Access the Courses and
                  Experience Better Service{" "}
                </h3>
              </div>
              <Applink></Applink>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PaymentVerification;
