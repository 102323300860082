import React from "react";
import { Link } from "react-router-dom";
import NotFound from "./../../assets/img/404.jpg";
import { Helmet } from "react-helmet";

const PageNotFound = () => {
  return (
    <>
      <Helmet>
        <title>Dhurina - Page Not Found</title>
        <meta name="robots" content="noindex, follow" />
        <meta name="errortype" content="404 - Not Found" />
        <meta name="prerender-status-code" content="404" />
      </Helmet>
      <div id="wrapper" style={{ alignSelf: "center", textAlign: "center" }}>
        <Link to="/">
          <img
            src={NotFound}
            alt="404"
            height={250}
            width={700}
            style={{ marginTop: "50px" }}
          />
        </Link>
        <div
          id="info"
          style={{ texAlign: "center", alignSelf: "center" }}
        ></div>
      </div>
    </>
  );
};

export default PageNotFound;
