import React from 'react';
import { useState, useEffect } from "react"; 
// import { Link } from "react-router-dom";
import { Navbar, Container, ListGroup} from 'react-bootstrap';
import Innermobheader from './innermobHeader';
import Loginbtn from '../headercommonlink/loginbtn';
import Toplogo from '../headercommonlink/toplogo';
// import Homelink from '../headercommonlink/homelink';

function SchoolRegisterHeader () {
    
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
    window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 100);
    });
    }, []); 

  return (
    <>
       <header className="navbar-area"> 

            <Navbar expand="lg" fixed="top" className={"innerHeader d-none d-lg-block " + (scroll ? "darkHeader" : "")}> 
                <Container className="nav-container"> 
                    <div className="logo">
                        <Toplogo></Toplogo>
                    </div>
                    
                    <Navbar.Collapse className="test-seriesbanner" id="themefie_main_menu">
                    </Navbar.Collapse>
                    <div className="nav-right-part nav-right-part-desktop">
                        <ListGroup as="ul">
                            <ListGroup.Item as="li">
                                <Loginbtn></Loginbtn>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                </Container>
            </Navbar>
            
             
            <Innermobheader></Innermobheader>
             
            
        </header>
        
    </>
  );
}

export default SchoolRegisterHeader;
