import React, { useState, useEffect, useCallback } from "react";
import { Container, Col, Row, Breadcrumb } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import VideoJS from "./VideoJsPlayer";
import Iframe from "react-iframe";
import Footer from "../../include/footer/footer";
import ApiCall from "../../api/callApi";
import Innerheader from "../../include/header/innerHeader";
import MainLoaderImage from "./../../assets/img/loading-buffering.gif";
const Image_URL = process.env.REACT_APP_Bucket_URL;

function Livestream() {
  var { id } = useParams();

  const [data, setData] = useState([]);
  const [event, setEvent] = useState({ title: "Live", url: "", image: "" });
  const [searchInput, setSearchInput] = useState("");
  const [hlsurl, SetHls] = useState("");
  const [name, SetName] = useState("User");
  const [user_id, SetUserId] = useState("");

  const [expiryTime, setExpiryTime] = useState("");
  const [countdownTime, setCountdownTime] = useState({
    countdownDays: "",
    countdownHours: "",
    countdownlMinutes: "",
    countdownSeconds: "",
  });

  const countdownTimer = () => {
    const timeInterval = setInterval(() => {
      // console.log("ech cth expiry time",expiryTime);
      var countdownDateTime = new Date(expiryTime).getTime();
      var currentTime = new Date().getTime();
      var remainingDayTime = countdownDateTime - currentTime;
      var totalDays = Math.floor(remainingDayTime / (1000 * 60 * 60 * 24));
      var totalHours = Math.floor(
        (remainingDayTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var totalMinutes = Math.floor(
        (remainingDayTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      var totalSeconds = Math.floor((remainingDayTime % (1000 * 60)) / 1000);

      const runningCountdownTime = {
        countdownDays: totalDays,
        countdownHours: totalHours,
        countdownMinutes: totalMinutes,
        countdownSeconds: totalSeconds,
      };

      setCountdownTime(runningCountdownTime);

      if (remainingDayTime <= 0) {
        clearInterval(timeInterval);
        setExpiryTime(false);
      }
    }, 1000);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (
      localStorage.getItem("token") === "" ||
      localStorage.getItem("token") === "null" ||
      localStorage.getItem("token") === undefined ||
      localStorage.getItem("token") === null
    ) {
      navigate("/login");
    } else {
      getData();
    }
  }, []);

  useEffect(() => {
    if (expiryTime != false) {
      countdownTimer();
    }
  });

  function getData() {
    ApiCall({ id: id }, "post", "live_streaming", courseareacallback);
  }

  const courseareacallback = useCallback((response) => {
    setData(response.data.events);
    if (response.data.status === "true") {
      setEvent(response.data.event);
      SetHls(response.data.event.url);
      setExpiryTime(response.data.event.time_from);
      SetName(response.data.name);
      SetUserId(response.data.user_id);
    }
  });

  if (hlsurl == "") {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <img
          src={Image_URL + "website/priloader_icon.svg"}
          style={{ alignSelf: "center" }}
        />{" "}
      </div>
    );
  }

  const RouteChange = (e) => {
    navigate("/search-result?keyword=" + searchInput, {
      state: { course: searchInput },
    });
  };

  return (
    <>
      {/* <Innerheader onSearch = {(e)=>RouteChange(e)}   ></Innerheader>  */}

      <section className="banner-area booksheadbanner course-detailinner">
        <Container>
          <Row>
            <Col md={12}>
              <Breadcrumb className="mb-3">
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/live-classes">
                  Live Classes
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Live</Breadcrumb.Item>
              </Breadcrumb>
            </Col>

            <Col xs={12}>
              <div className="main-banner-txt banner-inner course-detailbanner">
                <div className="section-title mb-2 mb-sm-3">
                  <h5 className="fw-medium f-18">{event.title}</h5>
                </div>
              </div>
            </Col>

            <Col sm={8} xs={12}>
              <div className="main-banner-txt banner-inner course-detailbanner">
                {expiryTime != false ? (
                  <h4 style={{ color: "red" }}>
                    Class will be started in: {countdownTime.countdownDays}:
                    {countdownTime.countdownHours}:
                    {countdownTime.countdownMinutes}:
                    {countdownTime.countdownSeconds}
                  </h4>
                ) : hlsurl != "" ? (
                  <VideoJS url={hlsurl} video={event} />
                ) : (
                  <h4>
                    Something went wrong!! Refresh the page and try again.
                  </h4>
                )}
              </div>
            </Col>

            <Col sm={4} xs={12}>
              <div className="recomdednd-section d-none d-lg-block">
                {expiryTime === false ? (
                  <Iframe
                    url={
                      "https://devchatadmin.dhurina.net/ChatWebsite?uid=" +
                      user_id +
                      "&name=" +
                      name +
                      "&role=user&room=Room:" +
                      id
                    }
                    width="100%"
                    height="400px"
                    display="block"
                    position="relative"
                  />
                ) : null}
              </div>
            </Col>

            {/* <Col sm={4} xs={12}> 
                        <div className="recomdednd-section d-none d-sm-block">
                            <p>Recommended Classes</p>
                            
                            {data.length<1? <h6 className="mt-4 mb-3" style={{margin: '5%'}}>No more live classes found!!</h6>:
                                data.map((value,key)=>{
                                    return (
                                        <Link to={"/live-streaming/"+value.id} key={key}>
                                            <Row>
                                                <Col xs={4} className="pe-0" >
                                                    <div className="single-course-wrap video-bgdemo recommendclass">
                                                        <div className="thumb"> 
                                                            <Image src={`${IMAGE_URL}${value.image}`} alt="img" />
                                                        </div> 
                                                    </div> 
                                                </Col>
                                                <Col xs={8} className="ps-0">
                                                    <div className="single-course-wrap video-bgdemo recommendclass">
                                                        <div className="wrap-details">
                                                            <h6 className="mb-1">{value.title}</h6>
                                                            <div className="price-wrap pt-0">
                                                                <Row className="align-items-center">
                                                                    <Col xs={12}> 
                                                                        <span className="f-11 mt-1 text-muted fw-bold mob-linefacultyheight"><i class="far fa-clock"></i> {value.time}</span>
                                                                    </Col> 
                                                                </Row>
                                                            </div> 
                                                        </div>
                                                    </div> 
                                                </Col>
                                            </Row>
                                        </Link>
                                    )
                                })
                            }
                        </div>
                    </Col> */}
          </Row>
        </Container>
      </section>

      {/* <section className="py-5">
            <Container className="contact-bg">
                <Row className="justify-content-center">
                    <Col lg={12}>  
                        <div className="contactform">
                            <div className="recomdednd-section"><p className="mt-0">Add your Comments</p></div>
                            <Row>                                     
                                <Col md={10}>
                                    <div className="mb-3">
                                        <Form.Control type="text" as="textarea" placeholder="Enter your Comments"  />
                                    </div>
                                </Col> 
                                <Col md={2}>
                                    <Button variant="danger" className="mt-3f-16 shadow-none">
                                        <i className="far fa-paper-plane"></i> Submit
                                    </Button>
                                </Col>
                            </Row>


                            <div className="recomdednd-section"><p>Previous Comments</p></div>

                            <Row className="ourfacilitiesflex">							  
                                <Col xs={12} md={8}>
                                    <div className="facilitybg mb-3 p-3">
                                        <Row className="align-items-center"> 
                                            <Col xs={12} md={2}>
                                                <Image src={Coursefacultyimg} className="newcourse-teach mt-2" alt="" />
                                            </Col>
                                            <Col xs={12} md={10} className="ps-sm-0">
                                                <p className="rev-usernewname mb-0 normal-line-height mt-2">Tanishq Kapoor</p>
                                                <p className="couewcarddesp"><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</span></p>
                                            </Col>
                                        </Row> 
                                    </div>
                                </Col>

                                <Col xs={12} md={8}>
                                    <div className="facilitybg mb-3 p-3">
                                        <Row className="align-items-center"> 
                                            <Col xs={12} md={2}>
                                                <Image src={Coursefacultyimg} className="newcourse-teach mt-2" alt="" />
                                            </Col>
                                            <Col xs={12} md={10} className="ps-sm-0">
                                                <p className="rev-usernewname mb-0 normal-line-height mt-2">Tanishq Kapoor</p>
                                                <p className="couewcarddesp"><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</span></p>
                                            </Col>
                                        </Row> 
                                    </div>
                                </Col>

                                <Col xs={12} md={8}>
                                    <div className="facilitybg mb-3 p-3">
                                        <Row className="align-items-center"> 
                                            <Col xs={12} md={2}>
                                                <Image src={Coursefacultyimg} className="newcourse-teach mt-2" alt="" />
                                            </Col>
                                            <Col xs={12} md={10} className="ps-sm-0">
                                                <p className="rev-usernewname mb-0 normal-line-height mt-2">Tanishq Kapoor</p>
                                                <p className="couewcarddesp"><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</span></p>
                                            </Col>
                                        </Row> 
                                    </div>
                                </Col>

                                <Col xs={12} md={8}>
                                    <div className="facilitybg mb-3 p-3">
                                        <Row className="align-items-center"> 
                                            <Col xs={12} md={2}>
                                                <Image src={Coursefacultyimg} className="newcourse-teach mt-2" alt="" />
                                            </Col>
                                            <Col xs={12} md={10} className="ps-sm-0">
                                                <p className="rev-usernewname mb-0 normal-line-height mt-2">Tanishq Kapoor</p>
                                                <p className="couewcarddesp"><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</span></p>
                                            </Col>
                                        </Row> 
                                    </div>
                                </Col>

                                <Col xs={12} md={8}>
                                    <div className="facilitybg mb-3 p-3">
                                        <Row className="align-items-center"> 
                                            <Col xs={12} md={2}>
                                                <Image src={Coursefacultyimg} className="newcourse-teach mt-2" alt="" />
                                            </Col>
                                            <Col xs={12} md={10} className="ps-sm-0">
                                                <p className="rev-usernewname mb-0 normal-line-height mt-2">Tanishq Kapoor</p>
                                                <p className="couewcarddesp"><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</span></p>
                                            </Col>
                                        </Row> 
                                    </div>
                                </Col>
                                
                            </Row>




                        </div>
                    </Col>
                </Row>
            </Container>
        </section> */}

      {/* <section className="pt-3 map-bg bg-theme-red">
            <Container> 
                <Row className="justify-content-center align-items-center"> 
                    <Col md={3}>
                        <Image alt="img" src={MobappImage} />
                    </Col>
                    <Col md={6} className="downloadappbtnmob mb-4 schoolinner-mob-app"> 
                        <div className="section-title mb-4"> 
                            <h2 className=" text-start text-white pb-2">Download Dhurina App</h2>
                            <h5 className="text-white fw-medium f-22">Get Amazing Offers, Free Notes and scholarship</h5>
                        </div> 
                        <Applink></Applink>
                    </Col> 
                </Row>
            </Container>
        </section> */}

      {/* <Footer></Footer> */}
    </>
  );
}

export default Livestream;
