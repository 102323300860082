import React from "react";
import Register from "../../components/statedata/Register";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import ApiCall from "../../api/callApi";
import { useCallback } from "react";
const COURSE_IMAGE_URL = process.env.REACT_APP_Bucket_URL + "hardcopy/image";
const Image_URL = process.env.REACT_APP_Bucket_URL;

const AllBook = () => {
  const [pageCount, setPageCount] = useState(1);
  const [allCourse, setAllCourse] = useState([]);
  const [mainloading, setMainLoading] = useState(true);
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  useEffect(() => {
    const body = {
      page: pageCount,
    };
    ApiCall(body, "post", "all_books", category_data);
  }, [pageCount]);

  const category_data = useCallback((response) => {
    setMainLoading(false);
    if (response.data.status === 200) {
      setAllCourse(response.data.data.data);
      // console.log(response);
    } else {
      console.log("error");
    }
  });
  if (mainloading === true) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <img
          src={Image_URL + "website/priloader_icon.svg"}
          style={{ alignSelf: "center" }}
        />{" "}
      </div>
    );
  }

  const SingleBook = (title, bookId) => {
    // Replace "/" with " " in the input string
    const modifiedString = title.replace(/\//g, " ");
    navigate(`/book-detail/${modifiedString}/${bookId}`);
    window.scrollTo(0, 0);
  };
  window.scrollTo(0, 0);

  return (
    <>
      <div className="custom_container container py-5">
        <h3 className="text_gradient fw-bold mb-0">E-Books & Notes</h3>
        <div className="row mt-2 border-end justify-content-between">
          <div className="col-lg-8">
            <div className="row ">
              {allCourse.length === 0 ? (
                <p className="mb-0 ff_inter fs-3 fw-semibold text-black mt-3">
                  No Data Found.
                </p>
              ) : (
                allCourse?.map((obj, i) => {
                  return (
                    <div key={i} className="col-md-6 mt-4">
                      <div
                        className="cursor_pointer h-100 border_light_brown d-flex flex-column justify-content-between"
                        onClick={() => SingleBook(obj.title, obj.id)}
                      >
                        <div className="">
                          <div className="d-flex px-0 bg_books align-items-center justify-content-center">
                            <img
                              className="book_fit1 obj_fit1 w-100"
                              src={`${COURSE_IMAGE_URL}/${obj.image}`}
                              alt={obj.title}
                            />
                          </div>
                          <h2 className="ff_inter fw-bolder px-4 fs-6 text_gradient mb-0 mt-3">
                            {obj.title}
                          </h2>
                        </div>
                        <div className="mt-3 d-flex justify-content-between gap-2 align-items-center px-4 pb-3">
                          <p className="mb-0 text_gradient fw-bold mb-0">
                            {" "}
                            Price :{" "}
                            <span className="mb-0 text_gradient fw-bold mb-0">
                              ₹{obj.hardcopy_price}
                            </span>
                          </p>
                          <p className="fs_desc text_dark_grey ff_inter fw-bold mb-0">
                            {obj.sub_type}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
            <div className="d-flex justify-content-between">
              {/* {allCourse.length > 2 ? (
                <button
                  className="ff_inter fw-semi-bold text-white fs_md bg_gradient border-0 mt-4 mb-3 mb-lg-0  py-2 px-4"
                  onClick={() => {
                    setPageCount(pageCount + 1);
                  }}
                >
                  View More &nbsp;&nbsp; &rarr;
                </button>
              ) : (
                ""
              )} */}
              {pageCount > 1 && (
                <button
                  className="ff_inter fw-semi-bold text-white fs_md bg_gradient border-0 mt-3 mr-3 mb-lg-0 py-2 px-4"
                  onClick={() => {
                    setPageCount(pageCount - 1);
                  }}
                >
                  &larr; Previous
                </button>
              )}
              {allCourse.length > 0 && (
                <button
                  className="ff_inter fw-semi-bold text-white fs_md bg_gradient border-0 mt-3 mb-lg-0 py-2 px-4"
                  onClick={() => {
                    setPageCount(pageCount + 1);
                  }}
                >
                  Next &rarr;
                </button>
              )}
            </div>
          </div>
          <div className="col-lg-4 mt-3 ">
            <Register />
          </div>
        </div>
      </div>
    </>
  );
};

export default AllBook;
