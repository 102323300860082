import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Image,
  Breadcrumb,
  Accordion,
} from "react-bootstrap";
import Applink from "../../include/applink/googleApple";
import MobappImage from "./../../assets/img/mob-app.png";
import ApiCall from "../../api/callApi";
import Innerheader from "../../include/header/innerHeader";
import MainLoaderImage from "./../../assets/img/loading-buffering.gif";
import { Parser } from "html-to-react";
import { useNavigate } from "react-router-dom";
const TEST_IMAGE_URL = process.env.REACT_APP_Bucket_URL + "combo/";
const Image_URL = process.env.REACT_APP_Bucket_URL;

function MyTestSeries({ setLoginPopup }) {
  const [status, setStatus] = useState(1);
  const [data, setData] = useState([]);
  const [mainloading, setMainLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (
      localStorage.getItem("token") === "" ||
      localStorage.getItem("token") === "null" ||
      localStorage.getItem("token") === undefined ||
      localStorage.getItem("token") === null
    ) {
      navigate(-1);
      setLoginPopup(true);
      document.body.parentElement.classList.toggle("overflow-hidden");
    } else {
      getData();
    }
  }, [setLoginPopup]);

  function getData() {
    ApiCall("", "get", "my_test_series", testSeriesCallback);
  }

  const testSeriesCallback = useCallback((response) => {
    setMainLoading(false);
    if (response.data.status === 200) {
      console.log(response.data.combos);
      setData(response.data.combos);
    } else {
      setStatus(0);
    }
  });

  if (mainloading === true) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <img
          src={Image_URL + "website/priloader_icon.svg"}
          style={{ alignSelf: "center" }}
        />{" "}
      </div>
    );
  }

  //   const RouteChange = (e) => {
  //     navigate("/search-result?keyword=" + searchInput, {
  //       state: { course: searchInput },
  //     });
  //   };
  console.log(data);
  return (
    <>
      {/* <Innerheader onSearch={(e) => RouteChange(e)}></Innerheader> */}

      <section className="pt-5">
        <div className="container custom_container">
          <Row className="justify-content-start">
            <Col lg={6} className="align-self-center">
              <Breadcrumb className="mb-1">
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item className="text_gradient" active>
                  My Test Series
                </Breadcrumb.Item>
              </Breadcrumb>
              <Row>
                <Col md={12}>
                  <h3 className="mb-0 f-25mob">My Test Series</h3>
                </Col>
              </Row>
            </Col>
            {/* <Col lg={6} className="text-sm-end"> */}
            {/* <Link to="/test-list" className="btn btn-warning mt-sm-0">
                                <i className="fa fa-file-pdf"></i> Mock Test
                            </Link> */}
            {/* <Link
                to="/live-classes"
                className="btn bg_gradient text-white mt-sm-0"
              >
                <i className="fa fa-desktop"></i> Live Classes
              </Link>
            </Col> */}
          </Row>
        </div>
      </section>

      <section className="trending-courses-area py-5">
        <Container className="custom_container">
          <Row>
            <Col lg={12} className="sidebar-area">
              <div className="widget-accordion-inner">
                {data == 0 ? (
                  <h6 className="mt-4 mb-3" style={{ margin: "5%" }}>
                    No Test Series found!. Buy Test Series from Dhurina
                    App/Website to attempt Test Series here......
                  </h6>
                ) : (
                  <div className="row">
                    {data.map((test, index) => {
                      return (
                        <div
                          onClick={() => {
                            navigate(
                              `/attempt-test-series/${test.title}/${test.id}`
                            );
                            window.scrollTo({
                              top: 0,
                              behavior: "smooth",
                            });
                          }}
                          key={index}
                          className="col-sm-6 col-md-4 mt-4"
                        >
                          <div className="border_light_brown cursor_pointer h-100">
                            <img
                              className="img-fluid"
                              src={`${TEST_IMAGE_URL}${test.image}`}
                              alt="bpsc_logo "
                            />
                            <div className="ms-2 mt-3 mb-0 pb-1">
                              <h2 className="ff_inter fw-bolder fs-6 text_gradient mb-0">
                                {test.title}
                              </h2>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* <section className="pt-3 map-bg bg-theme-red">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col md={3}>
              <Image alt="img" src={MobappImage} />
            </Col>
            <Col md={6} className="downloadappbtnmob mb-4 schoolinner-mob-app">
              <div className="section-title mb-4">
                <h2 className=" text-start text-white pb-2">
                  Download Dhurina App
                </h2>
                <h5 className="text-white fw-medium f-22">
                  Get Amazing Offers, Free Notes and scholarship
                </h5>
              </div>
              <Applink></Applink>
            </Col>
          </Row>
        </Container>
      </section> */}
    </>
  );
}

export default MyTestSeries;
