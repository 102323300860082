import React, { useState, useCallback } from "react";
import {
  Button,
  Container,
  Col,
  Row,
  Image,
  Form,
  Alert,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import Homeheader from "../../include/header/homeHeader";
import Footer from "../../include/footer/footer";
import Applink from "../../include/applink/googleApple";
import MobappImage from "./../../assets/img/mob-app.png";
import ApiCall from "../../api/callApi";

const Image_URL = process.env.REACT_APP_Bucket_URL;

const FreeTestSeries = () => {
  const [loading, setLoading] = useState(false);
  const [show_succ, setShowSucc] = useState(false);
  const [show_fail, setShowFail] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);

  // Form Data
  const [username, setUserName] = useState("");
  const [userphone, setUserPhone] = useState("");

  function handleSubmitForm(e) {
    e.preventDefault();
    setLoading(true);
    ApiCall(
      { phone: userphone, name: username },
      "post",
      "generate_lead",
      GetMyOfferCallback
    );
  }

  const GetMyOfferCallback = useCallback((response) => {
    setLoading(false);
    console.log(response);
    if (response.data.status === 200) {
      setUserPhone("");
      setUserName("");
      setSubmitStatus(true);
    } else {
      notify("Something went wrong please try again!!");
    }
  });

  const notify = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  return loading === true ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      {" "}
      <img
        src={Image_URL + "website/png/dhurina-logo.png"}
        style={{ alignSelf: "center" }}
      />{" "}
    </div>
  ) : (
    <>
      <Homeheader></Homeheader>

      <section className="breadcrumb-area pb-4 banner-area innerpagesbanner"></section>

      <section className="contact-newbg  py-5 pt-5">
        <main>
          <Container className="contact-bg">
            <Row className="justify-content-center">
              <Col lg={12} className="text-center">
                <div className="section-title">
                  <h2>Get Free Test Series</h2>
                </div>
              </Col>

              {submitStatus === false ? (
                <>
                  <Col lg={12} className="text-center">
                    <p className="text-theme f-15 mb-0">
                      Enter below details to get Free Test Series
                    </p>
                  </Col>
                  <Col lg={7}>
                    <div className="contactform">
                      <form onSubmit={(e) => handleSubmitForm(e)}>
                        <Row>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Form.Control
                                type="text"
                                placeholder="Your Name"
                                onChange={(e) => setUserName(e.target.value)}
                                name="username"
                                required
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Form.Control
                                type="number"
                                min="0"
                                placeholder="Your Phone"
                                onChange={(e) => setUserPhone(e.target.value)}
                                required
                              />
                            </div>
                          </Col>
                          {/* <Col lg={12}>
                                                <div className="mb-3">
                                                    <Form.Control as="textarea" placeholder="Your Message"  onChange={(e)=>setUserMessage(e.target.value)} required />
                                                </div>
                                            </Col> */}
                          <Col lg={12}>
                            <Alert
                              variant="danger"
                              show={show_fail}
                              onClose={() => setShowSucc(false)}
                              dismissible
                              className="px-3 py-2 f-13"
                            >
                              <Alert.Heading className="f-13 mb-0">
                                <i className="fas fa-exclamation-triangle me-2"></i>
                                <strong>Try Again</strong>
                              </Alert.Heading>
                              Failed to submit your query. Refresh the page and
                              try again!
                            </Alert>
                            <Alert
                              variant="success"
                              show={show_succ}
                              onClose={() => setShowFail(false)}
                              dismissible
                              className="px-3 py-2 f-13"
                            >
                              <Alert.Heading className="f-13 mb-0">
                                <i className="fas fa-check me-2"></i>
                                <strong>Thanks</strong>
                              </Alert.Heading>
                              Your query Submitted successfully. We will contact
                              you soon.
                            </Alert>
                          </Col>
                          <Col lg={12}>
                            <Button
                              variant="danger"
                              className="mt-3 f-16 shadow-none"
                              type="submit"
                            >
                              <i className="far fa-paper-plane"> Submit </i>
                            </Button>
                          </Col>
                        </Row>
                      </form>
                    </div>
                  </Col>
                </>
              ) : (
                <Col lg={12} className="text-center">
                  <Row>
                    <Col xs={12}>
                      <i className="fa fa-2x f-50 fa-check text-success"></i>
                    </Col>
                  </Row>
                  <div className="pt-3 mt-auto">
                    <Row>
                      <p>Download Dhurina App And Play Free Test Series</p>
                    </Row>
                  </div>
                  <div className="main-banner-txt banner-inner text-md-start text-center mb-0 mb-sm-5">
                    <div className="download-btn ms-0 ms-sm-2 mt-4">
                      <p className="f-15 text-black-new align-items-center d-flex">
                        <span>Download App Now</span>{" "}
                      </p>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.dhurina"
                        target="_blank"
                        rel="noreferrer"
                        className="downloadappbtnmob mt- mb-3 d-inline-block me-3"
                      >
                        <Image
                          alt="Google Play"
                          src={Image_URL + "img/google-play-button.png"}
                        />
                      </a>
                      <a
                        href="https://apps.apple.com/in/app/dhurina/id1479858722"
                        target="_blank"
                        className="downloadappbtnmob"
                        rel="noreferrer"
                      >
                        <Image
                          alt="App Store"
                          src={Image_URL + "img/app-store-button.png"}
                        />
                      </a>
                    </div>
                  </div>
                </Col>
              )}
            </Row>
          </Container>
        </main>
      </section>

      <section className="pt-3 map-bg bg-theme-red">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col md={3}>
              <Image alt="dhurina" src={MobappImage} />
            </Col>
            <Col md={6} className="downloadappbtnmob mb-4 schoolinner-mob-app">
              <div className="section-title mb-4">
                <h2 className=" text-start text-white pb-2">
                  Download Dhurina App
                </h2>
                <h5 className="text-white fw-medium f-22">
                  Get Amazing Offers, Free Notes and scholarship
                </h5>
              </div>
              <Applink></Applink>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer></Footer>

      <div>
        <ToastContainer />
      </div>
    </>
  );
};

export default FreeTestSeries;
