import React,{useState,useEffect,useRef} from 'react';
import { Container, Col, Row,Button  } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import ReactPlayer from 'react-player'
import './external.css';

const VIDEO_URL = process.env.REACT_APP_Bucket_URL+"videos/Janhavi_maam_Ad_Video_eba.mp4";

const Janhavi4=()=> {

    const executeScroll = () => myRef.current.scrollIntoView();
    const playVideo = () => {
        setShowImage('none');
        setShowVideo('block');
    }

    const myRef = useRef(null);
    const [showVideo,setShowVideo] = useState('none')
    const [showImage,setShowImage] = useState('block')
    useEffect(() => {
    },[]);

    const testslideroptions = {
        loop:true,
        margin: 20,
        items:1,
        nav: true,
        dots: true,
        smartSpeed:1000,
        autoplay:true,     
    }

    return (<div>
      <>
    
        <section className="pt-2">
            <Container>
                <Row>
                    <Col lg={12}>
                        <div style={{backgroundColor:'rgba(0, 0, 0, 0.5)'}}>
                           <h2 className='f-30 p-3' style={{color:'white'}}>English Spoken Courses</h2> 
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
       
        <section className="">
            <Container>  
                <Row className="position-relative">
                    <Col sm={12} className="">
                    <Col sm={12} className="" style={{display:showImage}}>
                        <div className="main-banner-txt text-center"> 
                            <img src='https://pubcdn.dhurina.net/jhanvi.png' alt='jahanvi' />
                            <i class="fa fa-play-circle janhvi" onClick={() => playVideo()}></i>
                        </div>
                    </Col>


                    <Col sm="12" className='text-center' style={{display:showVideo}}>
                        <ReactPlayer playing url={VIDEO_URL} controls={true} width="100%" height="100%" />
                    </Col>
                    </Col>
                    <div className='text-center'>
                        <Button  className="btn btn-danger mt-3" onClick={executeScroll} style={{padding:'0px 80px'}}><i className="fa fa-rupee-sign"></i>&nbsp;Enroll Now</Button> <br/>
                    </div>
                    
                </Row>
            
            </Container>
        </section>
        
        
        <section className="test-series py-5 mt-5">
            <Container>
                <Row>
                    <Col lg={12}>
                        <div>
                           
                           <h5 className='f-20'  style={{color:'black',fontWeight:800}}>About the Course</h5> 
                           <p style={{color:'black'}}>It is a Spoken English online learning course where you will learn to speak English fluently starting from basic to advanced level. English is a global language that is required in every aspect of life including a job interview, for maintaining communication with a foreign client, for living a respectable life in any abroad country, etc. That is why, Janhavi Panwar is here to help you improve your English-speaking skills at your comfort.</p>
                           <p style={{color:'black'}}>It doesn’t matter you know the basics of the language or not. What matters is your zeal to learn and practice speaking.</p>
                            <h5 className='f-20' style={{fontWeight:800}}>What You’ll Get Inside this Course:</h5>
                            <ul>
                                <li>Day-wise Videos</li>
                                <li>Easy Tips & secret to learn English Faster</li>
                                <li>English Conversation Exercises</li>
                                <li>Translation Exercises</li>
                                <li>Story Listening Module</li>
                                <li>English Speaking Practice Videos</li>
                                <li>PDF Notes for English Practice</li>
                                <li>No. of Classes: Basics, Intermediate and Advance</li>
                                <li>Validity: 1 Year</li>
                            </ul>
                           
                           <h5 className='f-20' style={{fontWeight:800}}>Who is this course for?</h5>
                           <ul>
                                <li>✓ Any student who wants to learn and improve English speaking and grammar</li>
                                <li> ✓ Working Professional- To improve English speaking and get promotion and growth in career</li>
                                <li> ✓ House Wife- To boost the confidence and help kids in homework</li>
                                <li> ✓ Interview Preparation- Learn English speaking and get your dream job</li>
                           </ul>

                           <h5 className='f-20' style={{fontWeight:800}}>What you will learn after Complete This Course?</h5>
                           <p style={{color:'black'}}><b>Speaking:</b> You will able to speak English fluently and improve your communication.</p>
                           <p style={{color:'black'}}><b>Accents:</b> Not only English language, you can learn different accents of this language that will help you maintain foreign clients and foreign relations.</p>
                           <p style={{color:'black'}}><b>Certificate:</b> Yes</p>

                           
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        
        
        <section className="py-5 bg-light mt-5">
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="section-title text-center mt-4 mt-sm-0 mb-4" ref={myRef}> 
                            <h4>Choose Your Plan</h4>
                          
                        </div>
                        <div className="card-price-menu">
                            <div className="card-price-menu__item card-price--25" style={{backgroundColor:'lightgreen'}}>
                                <div className="card-price-top">
                                    <div className="card-price-top__price"><b>Basic Course</b></div>
                                    <div className="card-price-top__price"><span className="value">499<span className="sign"><i className="fa fa-rupee"></i></span></span></div>
                                    <div className="card-price-top__time">For 1 Year</div>
                                    </div>
                                    <div className="card-price-body">
                                    <div className="card-price-body__log">No of Lectures 15</div>
                                    <div className="card-price-body__button">
                                    <a target="_blank" rel="noreferrer" href="https://drepapp.com/view-course/3931/Pa2237"><Button variant="green">Buy Now</Button></a>
                                    </div>
                                </div>
                            </div>
                            <div className="card-price-menu__item card-price--25" style={{backgroundColor:'lightblue'}}>
                                <div className="card-price-top">
                                    <div className="card-price-top__price"><b>Intermediate Course</b></div>
                                    <div className="card-price-top__price"><span className="value">799<span className="sign"><i className="fa fa-rupee"></i></span></span></div>
                                    <div className="card-price-top__time">For 1 Year</div>
                                    </div>
                                    <div className="card-price-body">
                                    <div className="card-price-body__log">No of Lectures. 15</div>
                                    <div className="card-price-body__button">
                                    <a target="_blank" rel="noreferrer" href="https://drepapp.com/view-course/3943/Pa2237"><Button variant="green">Buy Now</Button></a>
                                    </div>
                                </div>
                            </div>
                            <div className="card-price-menu__item card-price--25" style={{backgroundColor:'lightpink'}}>
                                <div className="card-price-top">
                                    <div className="card-price-top__price"><b>Advance Course</b></div>
                                    <div className="card-price-top__price"><span className="value">999<span className="sign"><i className="fa fa-rupee"></i></span></span></div>
                                    <div className="card-price-top__time">For 1 Year</div>
                                    </div>
                                    <div className="card-price-body">
                                    <div className="card-price-body__log">No of Lectures. 15</div> 
                                    <div className="card-price-body__button">
                                    <a target="_blank" rel="noreferrer" href="https://drepapp.com/view-course/3949/Pa2237"><Button variant="green">Buy Now</Button></a>
                                    </div>
                                </div>
                            </div>
                            <div className="card-price-menu__item card-price--25" style={{backgroundColor:'lightyellow'}}>
                                <div className="card-price-top">
                                    <div className="card-price-top__price"><b>Combo Course</b></div>
                                    <div className="card-price-top__price"><span className="value">1299<span className="sign"><i className="fa fa-rupee"></i></span></span></div>
                                    <div className="card-price-top__time">For 1 Year</div>
                                    </div>
                                    <div className="card-price-body">
                                    <div className="card-price-body__log">No of Lectures. 45</div> 
                                    <div className="card-price-body__button">
                                        <a target="_blank" rel="noreferrer" href="https://drepapp.com/view-course/3937/Pa2237"><Button variant="green">Buy Now</Button></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className='test-series py-5'>
            <Container>  
                <h5>Meet Janhavi Panwar</h5>
                <p>Today, janhavi is a master in 9 foreign accents of English language and some other languages like Japanese, French, Spanish, etc. She is a fabulous accent and IELTS Trainer and has more than 100000 associated students.</p>
                <p>She has completed her Master's Degree (result awaited) in M.A. English from Delhi University at the age of nineteen itself when most students of her age are just started with their graduation degrees.</p>
                <p>Above all this, she is also a motivational and inspirational speaker and has received a number of awards on different national and international stages. She has been interviewed by a number of renowned entrepreneurs and speakers like Sandeep Maheshwari, Vivek Bindra, Pardeep Bishnoi, and many more who are themselves a motivation for crores of people.</p>
                <p>She has been invited as a Chief Guest at several events organized all over the nation. Not only this, she has been giving motivational speeches to a number of IAS Officers at a very prestigious event once.</p>
                <p>How did this small-town girl become the “Wonder Girl of India” at such an early stage?</p>
                <p>People generally see Janhavi as an extraordinary teenager hailing from a small town of Haryana. But what makes her different is her ability and zeal to learn more things even at an early age.</p>
                <p>At the age of one, Janhavi’s memory was equipped with 500-550 vocabulary words. It was her father who struggled with her every now and then and supported her despite limited means. When she turned three, she wasn’t admitted to Nursery but directly to Senior KG because she had picked most things at home. As years passed, when we spoke to the school management, they realized Janhavi’s potential by looking at her scores, and she was given special permission to clear two classes in the same year.</p>
                <p>That is how, this nineteen-year-old girl, Janhavi, is the real “Wonder Girl of India!”</p>
            </Container>
        </section>
        

        
        <section className="bg-light py-5">
            <Container className="test-newbg">
                <Row>
                    <Col lg={12} className="text-center">
                        <p className="text-theme f-15 mb-0">Feedback</p>
                        <div className="section-title">
                            <h1>Quick Testimonials</h1>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <OwlCarousel className="test-slider" {...testslideroptions}>
                            <div className="item">
                                <Row className="justify-content-center">
                                    <Col md={7}>
                                        <div className="testwrap">
                                            <p className="text-center my-4">
                                                Janhavi is my favorite teacher and an inspiration. I am 22 years old. Although I am elder than her in age but she is truly an amazing individual with such great knowledge. She teaches so well. And due to the affordability of the course, I could achieve my dream of going to Canada so easily. I got 8 bands in my IELTS Exam.
                                           </p>
                                           <p className='text-center'><i class="fa fa-star text-center" aria-hidden="true" style={{color:'#ffb607'}}></i><i class="fa fa-star" aria-hidden="true" style={{color:'#ffb607'}}></i>
                                           <i class="fa fa-star" aria-hidden="true" style={{color:'#ffb607'}}></i><i class="fa fa-star" aria-hidden="true" style={{color:'#ffb607'}}></i><i class="fa fa-star" aria-hidden="true" style={{color:'#ffb607'}}></i>
                                           </p>
                                        </div> 
                                    </Col>
                                </Row>
                            </div>
                            <div className="item">
                                <Row className="justify-content-center">
                                    <Col md={7}>
                                        <div className="testwrap">
                                            <p className="text-center my-4">Thank you Janhavi for explaining everything so well. You are a great teacher. I am so proud to be your student. You are doing wonders at this age. I couldn’t only learn speaking but the accent of American English too. </p>
                                                <Row className="my-3 my-sm-5 justify-content-center align-items-center"> 
                                                <p className='text-center'><i class="fa fa-star text-center" aria-hidden="true" style={{color:'#ffb607'}}></i><i class="fa fa-star" aria-hidden="true" style={{color:'#ffb607'}}></i>
                                                    <i class="fa fa-star" aria-hidden="true" style={{color:'#ffb607'}}></i><i class="fa fa-star" aria-hidden="true" style={{color:'#ffb607'}}></i><i class="fa fa-star" aria-hidden="true" style={{color:'#ffb607'}}></i>
                                                </p>
                                            </Row> 
                                        </div> 
                                    </Col>
                                </Row>
                            </div>
                            <div className="item">
                                <Row className="justify-content-center">
                                    <Col md={7}>
                                        <div className="testwrap">
                                            <p className="text-center my-4">I feel so proud whenever I interact with you during the live classes. You are an inspiration, Ma’am. I would be very happy if I ever get a chance to meet you offline. You are such a wonderful teacher. I am proud to be your student.
                                            </p>
                                            <p className='text-center'><i class="fa fa-star text-center" aria-hidden="true" style={{color:'#ffb607'}}></i><i class="fa fa-star" aria-hidden="true" style={{color:'#ffb607'}}></i>
                                                <i class="fa fa-star" aria-hidden="true" style={{color:'#ffb607'}}></i><i class="fa fa-star" aria-hidden="true" style={{color:'#ffb607'}}></i>
                                            </p>
                                        </div> 
                                    </Col>
                                </Row>
                            </div>
                            <div className="item">
                                <Row className="justify-content-center">
                                    <Col md={7}>
                                        <div className="testwrap">
                                            <p className="text-center my-4">Janhavi is such an amazing teacher even at an early age. Earlier, I couldn't believe ki koi 18 saal ka bacha itna acha padha sakta hai. Lekin she is so amazing. I am so proud ki humare India ke ps itna mhaan talent hai
                                            </p>
                                            <p className='text-center'><i class="fa fa-star text-center" aria-hidden="true" style={{color:'#ffb607'}}></i><i class="fa fa-star" aria-hidden="true" style={{color:'#ffb607'}}></i>
                                                <i class="fa fa-star" aria-hidden="true" style={{color:'#ffb607'}}></i><i class="fa fa-star" aria-hidden="true" style={{color:'#ffb607'}}></i><i class="fa fa-star" aria-hidden="true" style={{color:'#ffb607'}}></i>
                                            </p>
                                        </div> 
                                    </Col>
                                </Row>
                            </div>
                            <div className="item">
                                <Row className="justify-content-center">
                                    <Col md={7}>
                                        <div className="testwrap">
                                            <p className="text-center my-4">She is truly a Wonder for our country. I am so happy ki m Janhavi ka student hu. If I'll ever get a chance to meet her, that would be the proudest moment for me. Thank you Janhavi for helping me improve my confidence while speaking English.
                                            </p>
                                            <p className='text-center'><i class="fa fa-star text-center" aria-hidden="true" style={{color:'#ffb607'}}></i><i class="fa fa-star" aria-hidden="true" style={{color:'#ffb607'}}></i>
                                                    <i class="fa fa-star" aria-hidden="true" style={{color:'#ffb607'}}></i><i class="fa fa-star" aria-hidden="true" style={{color:'#ffb607'}}></i><i class="fa fa-star" aria-hidden="true" style={{color:'#ffb607'}}></i>
                                                </p>
                                        </div> 
                                    </Col>
                                </Row>
                            </div>
                            <div className="item">
                                <Row className="justify-content-center">
                                    <Col md={7}>
                                        <div className="testwrap">
                                            <p className="text-center my-4">I could write English well, but still couldn't communicate well in English while talking to someone. Janhavi's course helped me improve my speaking skills so well that my fear of speaking English just faded away. She is just amazing.
                                            </p>
                                            <p className='text-center'><i class="fa fa-star text-center" aria-hidden="true" style={{color:'#ffb607'}}></i><i class="fa fa-star" aria-hidden="true" style={{color:'#ffb607'}}></i>
                                                    <i class="fa fa-star" aria-hidden="true" style={{color:'#ffb607'}}></i><i class="fa fa-star" aria-hidden="true" style={{color:'#ffb607'}}></i><i class="fa fa-star" aria-hidden="true" style={{color:'#ffb607'}}></i>
                                                </p>
                                        </div> 
                                    </Col>
                                </Row>
                            </div>
                        </OwlCarousel>
                    </Col>
                </Row>
            </Container>
        </section>

        <section className='test-series py-5'>
            <Container>  
                <Row>
                    <Col lg={12} className="text-center">
                        <div className="section-title">
                            <h1>FAQ</h1>
                        </div>
                    </Col>
                    <Col>
                   <h6> Q1: YAH COURSE KIN LOGON KE LIYE HAIN?</h6>
                   <p> This course is for– Students, Housewives, Job Seekers, Job goers, Businessmen, Entrepreneurs, Teachers etc. Who wants to speak English. </p>
                   <h6> Q2: IS COURSE KI VALIDITY KITNI HAI?</h6>
                   <p>  1 YEAR</p>
                   <h6> Q3: IS COURSE KO KAISE ACCESS KAR SAKTE HAIN?</h6>
                   <p>   Jab aap join kar lete hain, to aapko ek username aur password diya jata hai. Iski madad se aap course ko access kar sakte hain.</p>
                   <h6> Q4: KYA IS COURSE KO ACCESS KARNE KE LIYE COMPUTER HONA ZARURI HAI?</h6>
                   <p>  Nahi. Aap apne mobile phone se easily access kar sakte hain. Yah course poori tarah se mobile user friendly banaya gaya hai.</p>

                    </Col>
                </Row>
                
            </Container>
        </section>
             
        </>
    </div>
  );
}

export default Janhavi4;
