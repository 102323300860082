import { HeroBihar } from "../../include/helper/Helper";
import Hero from "../../components/statedata/Hero";
import Register from "../../components/statedata/Register";
import Test from "../statedata/Test";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import ApiCall from "../../api/callApi";
import { useCallback } from "react";
const Image_URL = process.env.REACT_APP_Bucket_URL;

const AllTests = () => {
  const [testSeries, setTestSeries] = useState([]);
  const [mainloading, setMainLoading] = useState(true);
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  const catId = searchParams.get("catId");

  useEffect(() => {
    if (type === "sub") {
      const body = {
        type: "sub_category",
        type_id: id,
      };
  
      ApiCall(body, "post", "all_data", category_data);
    } else if (type === "test-series") {
      const body = {
        cat_id: catId,
        sub_cat: id,
      };

      ApiCall(body, "post", "test_series", test_series);
    } else {
      const body = {
        type: "course_area",
        type_id: id,
      };
  
      ApiCall(body, "post", "all_data", category_data);
    }
  }, [id]);


  const category_data = useCallback((response) => {
    setMainLoading(false);
    if (response.data.status === 200) {
      setTestSeries(response.data.combos);
    } else {
      console.log("error");
    }
  });
  const test_series = useCallback((response) => {
    setMainLoading(false);
    if (response.data.status === 200) {
      setTestSeries(response.data.combo);
    } else {
      console.log("error");
    }
  });
  if (mainloading === true) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <img
          src={Image_URL + "website/priloader_icon.svg"}
          style={{ alignSelf: "center" }}
        />{" "}
      </div>
    );
  }
  return (
    <>
      <div className="custom_container container py-5">
        <div className="row mt-5 justify-content-between">
          <div className="col-lg-8 border-end">
            <Hero display={false} Hero={HeroBihar} />
            <Test display={false} testSeries={testSeries} />
          </div>
          <div className="col-lg-4">
            <Register />
          </div>
        </div>
      </div>
    </>
  );
};

export default AllTests;
