import React, { useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useEffect, useCallback } from "react";
import {
  Button,
  Container,
  Col,
  Row,
  Image,
  Breadcrumb,
  ListGroup,
} from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import Innerheader from "../../include/header/innerHeader";
import Footer from "../../include/footer/footer";
import Applink from "../../include/applink/googleApple";
import MobappImage from "./../../assets/img/mob-app.png";
import { initialize, makePayment } from "../../components/Payment";
import ApiCall from "../../api/callApi";
import Loading from "../../components/Loader";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { Parser } from "html-to-react";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";

const TEST_SERIES_IMAGE_URL = process.env.REACT_APP_Bucket_URL + "combo/";
const Image_URL = process.env.REACT_APP_Bucket_URL;

const lessionSlideroptions = {
  loop: true,
  margin: 20,
  nav: true,
  dots: true,
  smartSpeed: 500,
  autoplay: true,
  responsive: {
    0: {
      items: 1.3,
      margin: 10,
    },
    768: {
      items: 4,
    },
    1440: {
      items: 4,
    },
  },
};

const Testseriesdetail = () => {
  var course_url = window.location.href;

  const notify = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const [paymentData, setPaymentData] = useState({
    token: "",
    order: "",
    mid: "",
    amount: "",
  });

  // function handleSubmitForm(e,mobile,name){
  //     console.log("check value of order id",paymentData.order)
  //     e.preventDefault();
  //     var data={
  //         "phone":mobile,
  //         "name":name,
  //         "course_id":id,
  //         "type":'combo',
  //         "order_id":paymentData.order,

  //     }
  //      ApiCall(data,"post","webhook",checkphonecallback);
  // }

  function webhook() {
    var data = {
      phone: localStorage.getItem("mobile"),
      course_id: id,
      type: "combo",
      order_id: paymentData.order,
    };
    ApiCall(data, "post", "webhook", checkphonecallback);
  }

  var test_series_url = window.location.href;
  const navigate = useNavigate();
  let { id } = useParams();

  const [mainloading, setMainLoading] = useState(true);
  const [testSeriesDetail, setTestSeiresDetail] = useState(true);
  const [testSeriesData, setTestSeries] = useState([]);
  console.log(testSeriesData);
  let url = "test_series_detail/" + id;
  const test_series_detail = (id) => {
    let url = "test_series_detail/" + id;
    getData(url);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getData(url);
  }, []);

  function getData(url) {
    ApiCall("", "post", url, testSeriesCallback);
  }

  const testSeriesCallback = useCallback((response) => {
    if (response.data.status === 200) {
      window.scrollTo(0, 0);
      setTestSeries(response.data.all);
      setTestSeiresDetail(response.data.data);
      initialize(
        initializeCallback,
        response.data.data.price,
        localStorage.getItem("mobile"),
        localStorage.getItem("id")
      );
      setMainLoading(false);
    } else {
      console.log("error");
    }
  });

  const initializeCallback = useCallback((response) => {
    let obj = {
      token: response.token,
      order: response.order,
      mid: response.mid,
      amount: response.amount,
    };
    setPaymentData(obj);
  });

  const checkphonecallback = useCallback((response) => {
    if (response.data.status === 200) {
      setMainLoading(true);
      makePayment(paymentData, myCallback);
    } else if (response.data.status === 500) {
      notify(response.data.message);
    }
  });

  const myCallback = useCallback((response, status) => {
    if (status) {
      if (response.STATUS === "TXN_SUCCESS") {
        updatepayment(response);
      } else {
        updatepayment(response);
      }
    } else {
      setMainLoading(false);
    }
  });

  const updatepayment = (response) => {
    var data = {
      status: response.STATUS,
      price: response.TXNAMOUNT,
      order_id: paymentData.order,
    };
    ApiCall(data, "post", "update_payment", updatepaymentcallback);
  };

  const updatepaymentcallback = useCallback((response) => {
    var state = {
      txnid: "",
      amount: "",
      course_name: "",
      transaction_status: 0,
    };
    if (response.data.status === 200) {
      state = {
        txnid: response.data.course_data.txnid,
        amount: response.data.course_data.amount,
        course_name: response.data.course_name,
        transaction_status: response.data.transaction_status,
      };
    }
    navigate("/payment-verification", { state });
  });

  return (
    <div>
      {mainloading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <img
            src={Image_URL + "website/priloader_icon.svg"}
            style={{ alignSelf: "center" }}
          />
        </div>
      ) : (
        <>
          <Innerheader></Innerheader>

          <Helmet>
            <title>{testSeriesDetail.title}</title>
            <meta
              name="robots"
              content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
            />
            <link rel="canonical" href={course_url} />
            <meta name="title" content={testSeriesDetail.title} />
            <meta name="description" content={testSeriesDetail.detail} />
            <meta
              name="twitter:description"
              content={testSeriesDetail.detail}
            />
            <meta
              name="twitter:image"
              content={`${TEST_SERIES_IMAGE_URL}${testSeriesDetail.image}`}
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={testSeriesDetail.title} />
            <meta name="twitter:site" content="@Dhurinalive" />
            <meta name="twitter:creator" content="@Dhurinalive" />
            <meta name="twitter:app:id:googleplay" content="com.dhurina" />
            <meta name="twitter:app:id:iphone" content="1479858722" />
            <meta
              name="twitter:app:name:googleplay"
              content="Dhurina - Learning Made Easy"
            />
            <meta
              name="twitter:app:name:ipad"
              content="Dhurina - Learning Made Easy"
            />
            <meta
              name="twitter:app:name:iphone"
              content="Dhurina - Learning Made Easy"
            />
            <meta name="twitter:domain" content="https://dhurina.net" />
            <meta name="twitter:google-play-app" content="app-id=com.dhurina" />
            <meta name="og:image:type" content="image/jpeg" />
            <meta property="og:description" content={testSeriesDetail.detail} />
            <meta
              property="og:image"
              content={`${TEST_SERIES_IMAGE_URL}${testSeriesDetail.image}`}
            />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={testSeriesDetail.detail} />
            <meta property="og:url" content={course_url} />
            <meta property="og:site_name" content="Dhurina" />
            <meta property="article:section" content="online-course" />
            <meta
              property="article:publisher"
              content="https://www.facebook.com/dhurinaclass"
            />
            <meta
              property="article:author"
              content="https://www.facebook.com/dhurinaclass"
            />
            <meta property="article:section" content="Online-Course" />
            <meta property="al:android:url" content={course_url} />
            <meta
              property="al:android:app_name"
              content="Dhurina - Learning Made Easy"
            />
            <meta property="al:android:package" content="com.dhurina" />
            <meta property="fb:app_id" content="facebook-app-id-in-number" />
            <meta property="fb:pages" content="facebook-page-in-number" />
          </Helmet>

          <section className="banner-area booksheadbanner course-detailinner">
            <main>
              <Container>
                <article>
                  <Row className="justify-content-center">
                    <Col md={12}>
                      <Breadcrumb className="mb-3">
                        <Breadcrumb.Item href="#">
                          <Link to="/">Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item href="#">
                          <Link to="/test-series">Test Series</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                          {testSeriesDetail.title}
                        </Breadcrumb.Item>
                      </Breadcrumb>
                    </Col>

                    <Col sm={{ span: 8, order: 2 }} xs={{ span: 12, order: 2 }}>
                      <Image
                        className="rounded-3"
                        src={`${TEST_SERIES_IMAGE_URL}${testSeriesDetail.image}`}
                        alt={testSeriesDetail.title}
                        height="720"
                        width="1280"
                      />
                    </Col>

                    <Col
                      className="text-center"
                      sm={{ span: 8, order: 2 }}
                      xs={{ span: 12, order: 2 }}
                    >
                      <div className="main-banner-txt banner-inner course-detailbanner">
                        <div className="section-title mb-2 mb-sm-3">
                          <h1 className="text-center">
                            {testSeriesDetail.title}
                          </h1>
                        </div>

                        <p className="schoolbannerdesp mb-4">
                          {Parser().parse(testSeriesDetail.detail)}
                        </p>

                        {/* <div className="d-none d-sm-block"> 
                                            <Row>
                                                <Col md={6}>
                                                    <div className="offermainbox offersuccess">
                                                        <Row>
                                                            <Col xs={7}>
                                                                <h4>Get Rs. 10% off</h4>
                                                            </Col>
                                                            <Col xs={5} className="text-end">
                                                                <Button variant="base-light">Apply Now</Button>
                                                            </Col>
                                                            <Col xs={12}>
                                                                <p>Get Rs 300 off on first Purchase</p>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="offermainbox offerwarning">
                                                        <Row>
                                                            <Col xs={7}>
                                                                <h4>Get Rs. 10% off</h4>
                                                            </Col>
                                                            <Col xs={5} className="text-end">
                                                                <Button variant="base-light">Refer Now</Button>
                                                            </Col>
                                                            <Col xs={12}>
                                                                <p>Get Rs 300 off on first Purchase</p>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col> 
                                            </Row> 

                                        </div> */}

                        <Row className="align-items-end">
                          <Col
                            xs={12}
                            className="course-price-strike d-none d-sm-block"
                          >
                            <span>₹ {testSeriesDetail.price}</span>
                            <br></br>
                            {/* <del><i className="fa fa-rupee"></i>600</del><br />
                                                <Link to="/" className="btn btn-danger mt-2 me-2">Add to Cart</Link> */}
                            {localStorage.getItem("token") ? (
                              <Button
                                className="btn btn-warning mt-2"
                                onClick={webhook}
                              >
                                Buy Now
                              </Button>
                            ) : (
                              <Button
                                className="btn btn-warning mt-2"
                                onClick={(e) => navigate("/login")}
                              >
                                Buy Now
                              </Button>
                            )}
                          </Col>
                          <Col xs={12} className="mt-2 mt-sm-4">
                            <span className="cou-share-new">
                              <i className="fa fa-share-alt mr-2"></i> Share
                              Test Seires
                            </span>
                            <ListGroup
                              as="ul"
                              className="social-link cou-share-newl d-block"
                            >
                              <FacebookShareButton
                                url={test_series_url}
                                quote="Dhurina"
                                hashtag="#course"
                              >
                                <FacebookIcon logoFillColor="white" size={35} />
                              </FacebookShareButton>
                              <span>&nbsp;&nbsp;</span>
                              <WhatsappShareButton
                                url={test_series_url}
                                quote="Dhurina"
                                hashtag="#course"
                              >
                                <WhatsappIcon logoFillColor="white" size={35} />
                              </WhatsappShareButton>
                              <span>&nbsp;&nbsp;</span>
                              <TelegramShareButton
                                url={test_series_url}
                                imageURL={`${TEST_SERIES_IMAGE_URL}${testSeriesDetail.image}`}
                                quote="Dhurina"
                                hashtag="#course"
                              >
                                <TelegramIcon logoFillColor="white" size={35} />
                              </TelegramShareButton>
                              <span>&nbsp;&nbsp;</span>
                              <EmailShareButton
                                url={test_series_url}
                                imageURL={`${TEST_SERIES_IMAGE_URL}${testSeriesDetail.image}`}
                                quote="Dhurina"
                                hashtag="#course"
                              >
                                <EmailIcon logoFillColor="white" size={35} />
                              </EmailShareButton>
                            </ListGroup>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </article>
              </Container>
            </main>
          </section>

          <section className="d-block d-sm-none mob-price-section pt-2">
            <Container>
              <Row>
                <Col md={12}>
                  {/* <Row>
                                <Col md={6}>
                                    <div className="offermainbox offersuccess">
                                        <Row>
                                            <Col xs={7}>
                                                <h4>Get Rs. 10% off</h4>
                                            </Col>
                                            <Col xs={5} className="text-end">
                                                <Button variant="base-light">Apply Now</Button>
                                            </Col> 
                                        </Row>
                                    </div>
                                </Col>  
                            </Row> */}

                  <div className="d-flex justify-content-between mt-2 course-price-strike">
                    <span>
                      <span>₹ {testSeriesDetail.price}</span>
                      <br></br>
                      {/* <del><i className="fa fa-rupee"></i>600</del> */}
                    </span>
                    {localStorage.getItem("token") ? (
                      <Button
                        className="btn btn-warning mt-2"
                        onClick={webhook}
                      >
                        Buy Now
                      </Button>
                    ) : (
                      <Button
                        className="btn btn-warning mt-2"
                        onClick={(e) => navigate("/login")}
                      >
                        Buy Now
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="test-series py-5">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="section-title">
                    <h2>Trending Test Series</h2>
                  </div>
                </Col>
                <Col lg={12}>
                  <OwlCarousel
                    className="lesson-slider mt-2"
                    {...lessionSlideroptions}
                  >
                    {testSeriesData.length === 0 ? (
                      <Loading />
                    ) : (
                      testSeriesData.map((item) => {
                        return (
                          <div
                            className="item"
                            onClick={() => test_series_detail(item.id)}
                            style={{ cursor: "pointer" }}
                          >
                            <div className="test-seriesbox">
                              <h6>{item.title}</h6>
                              <div className="price f-15">
                                Offer:{" "}
                                <span className="f-15 fw-semi-bold me-1">
                                  {" "}
                                  <i class="fa fa-rupee"></i>
                                  {item.price}
                                </span>
                                {/* <del className="text-black-50 f-15"><i class="fa fa-rupee"></i>600</del> */}
                              </div>
                              <div className="testaction">
                                <i class="fa fa-arrow-right"></i>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    )}
                  </OwlCarousel>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="pt-3 map-bg">
            <Container>
              <Row className="justify-content-center align-items-center">
                <Col md={3}>
                  <Image src={MobappImage} alt="dhurina app image" />
                </Col>
                <Col
                  md={5}
                  className="downloadappbtnmob mb-4 schoolinner-mob-app"
                >
                  <div className="section-title mb-4 lefth2border">
                    <h2 className=" text-start mb-3 pb-3">
                      Download Dhurina App
                    </h2>
                    <h5 className=" fw-medium f-22">
                      Get Amazing Offers, Free Notes and scholarship
                    </h5>
                  </div>
                  <Applink></Applink>
                </Col>
              </Row>
            </Container>
          </section>

          <Footer></Footer>
        </>
      )}
    </div>
  );
};

export default Testseriesdetail;
