import React from "react";
import { GoogleApiWrapper } from "google-maps-react";
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
const Image_URL = process.env.REACT_APP_Bucket_URL;

const google = window.google;

const InstitutesAddress = ({ selectedInstitute }) => {
  const Map = withGoogleMap((props) => (
    <GoogleMap defaultZoom={5} defaultCenter={{ lat: 21.7679, lng: 78.8718 }}>
      <Marker
        position={{ lat: selectedInstitute.lat, lng: selectedInstitute.lng }}
      />
    </GoogleMap>
  ));
  console.log(selectedInstitute);
  return (
    <div>
      {selectedInstitute ? (
        <div>
          <h2 className="fw-bold  text-black position-relative line d-inline">
            {selectedInstitute.heading}
          </h2>
          <div className="d-flex justify-content-between mt-3 align-items-center">
            <p className="mb-0 custom_address_width">
              {selectedInstitute.address}
            </p>
            <a
              className="btn_login text-white ff_inter bg_gradient cursor_pointer border-0 px-3 py-2 rounded-5"
              href={selectedInstitute.phoneNo}
            >
              Call Now
            </a>
          </div>
          <p className="mb-0 mt-2">{selectedInstitute.para}</p>
        </div>
      ) : (
        <p>Select an institute to see its address.</p>
      )}

      <div className="pt-3">
        <div className=" custom_class">
          <Map
            className=" w-100 h-100"
            containerElement={<div className="h-100 overflow-auto" />}
            mapElement={<div className="h-100 " />}
          />
        </div>
      </div>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyANk4K4P69SdYLgrp6ZJZDSOPE-7OMSoFs",
})(InstitutesAddress);
