import React from "react";
import Hero from "../../components/statedata/Hero";
import LiveClasses from "../../components/statedata/LiveClasses";
import RecordedVideos from "../../components/statedata/RecordedVideos";
import Test from "../../components/statedata/Test";
import Ebooks from "../../components/statedata/Ebooks";
import Books from "../../components/statedata/Books";
import Register from "../../components/statedata/Register";
import { HeroBpsc } from "../../include/helper/Helper";
import { BpscEbooks } from "../../include/helper/Helper";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import ApiCall from "../../api/callApi";
import { useCallback } from "react";
import MainLoaderImage from "./../../assets/img/loading-buffering.gif";
const COURSE_IMAGE_URL = process.env.REACT_APP_Bucket_URL + "combo/";
const Image_URL = process.env.REACT_APP_Bucket_URL;

const All_Test_Series = () => {
  const [classes, setClasses] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [books, setBooks] = useState([]);
  const [allCourse, setAllCourse] = useState([]);
  const [testSeries, setTestSeries] = useState([]);
  const [mainloading, setMainLoading] = useState(true);
  const { id } = useParams();
  const { stateName } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const filterSerach = searchParams.get("filter");
  const type = searchParams.get("type");
  const navigate = useNavigate();

  useEffect(() => {
    const body = {
      page: pageCount,
    };
    ApiCall(body, "post", "all_test_series", category_data);
  }, [pageCount]);

  const category_data = useCallback((response) => {
    setMainLoading(false);
    if (response.data.status === 200) {
      setAllCourse(response.data.combo.data);
    } else {
      console.log("error");
    }
  });

  window.scrollTo(0, 0);

  const SingleBook = (title, bookId) => {
    // Replace "/" with " " in the input string
    const modifiedString = title.replace(/\//g, " ");
    navigate(`/test-series/${modifiedString}/${bookId}`);
    window.scrollTo(0, 0);
  };

  const [searchKeyword, setSearchKeyword] = useState("");
  let url = "search_course";
  const body = {
    name: searchKeyword,
  };

  useEffect(() => {
    if (searchKeyword) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword]);

  function getData() {
    ApiCall(body, "post", url, filterCourseCallback);
  }

  const filterCourseCallback = useCallback((response) => {
    if (response.data.status === 200) {
      setAllCourse(response.data.test_series);
    } else {
      console.log("error");
    }
  }, []);
  if (mainloading === true) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <img
          src={Image_URL + "website/priloader_icon.svg"}
          style={{ alignSelf: "center" }}
        />{" "}
      </div>
    );
  }
  return (
    <>
      <div className="custom_container container py-5">
        <h3 className="text_gradient fw-bold mb-0">All Test Series</h3>
        <div className="row mt-2 border-end justify-content-between">
          {/* <Hero display={true} Hero={HeroBpsc} /> */}
          {/* <LiveClasses display={false} classes={classes} /> */}
          <div className="col-lg-8">
            <div className="row ">
              {allCourse.length === 0 ? (
                <p className="mb-0 ff_inter fs-3 fw-semibold text-black mt-3">
                  No Data Found.
                </p>
              ) : (
                allCourse?.map((obj, i) => {
                  return (
                    <div key={i} className="col-md-6 mt-4">
                      <div
                        className="cursor_pointer h-100 border_light_brown d-flex flex-column justify-content-between"
                        onClick={() => SingleBook(obj.title, obj.id)}
                      >
                        <div className="">
                          <div className="d-flex px-0 bg_books align-items-center justify-content-center">
                            <img
                              className="book_fit2 obj_fit2 w-100"
                              src={`${COURSE_IMAGE_URL}${obj.image}`}
                              alt={obj.title}
                            />
                          </div>
                          <h2 className="ff_inter fw-bolder px-4 fs-5 text_gradient mb-0 mt-3">
                            {obj.title}
                          </h2>
                        </div>
                        <div className="mt-3 d-flex gap-2 align-items-center px-4 pb-3">
                          <p className="mb-0 text_gradient fw-bold mb-0">
                            {" "}
                            Price :{" "}
                            <span className="mb-0 text_gradient fw-bold mb-0">
                              ₹{obj.price}
                            </span>
                          </p>
                          <p className="fs_desc text_dark_grey ff_inter text-decoration-line-through mb-0">
                            <del className="">{obj.discount}</del>
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
              <div className="d-flex justify-content-between ">
                {/* {allCourse.length > 2 ? (
                  <button
                    className="ff_inter fw-semi-bold text-white fs_md bg_gradient border-0 mt-3 mb-3 mb-lg-0  py-2 px-4 mt-4"
                    onClick={() => {
                      setPageCount(pageCount + 1);
                    }}
                  >
                    View More &nbsp;&nbsp; &rarr;
                  </button>
                ) : (
                  ""
                )}{" "} */}
                {pageCount > 1 && (
                  <button
                    className="ff_inter fw-semi-bold text-white fs_md bg_gradient border-0 mt-3 mr-3 mb-lg-0 py-2 px-4"
                    onClick={() => {
                      setPageCount(pageCount - 1);
                    }}
                  >
                    &larr; Previous
                  </button>
                )}
                {allCourse.length > 0 && (
                  <button
                    className="ff_inter fw-semi-bold text-white fs_md bg_gradient border-0 mt-3 mb-lg-0 py-2 px-4"
                    onClick={() => {
                      setPageCount(pageCount + 1);
                    }}
                  >
                    Next &rarr;
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-4 mt-3 ">
            <Register setSearchKeyword={setSearchKeyword} />
          </div>
        </div>
      </div>
    </>
  );
};

export default All_Test_Series;
