import React, { useEffect, useState, useRef } from "react";
import ApiCall from "../../api/callApi";
import { useCallback } from "react";
import { Link } from "react-router-dom";
const Image_URL = process.env.REACT_APP_Bucket_URL;

const CurentAffair = ({ setLoginPopup }) => {
  const [allCourse, setAllCourse] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [currentFileUrl, setCurrentFileUrl] = useState("");
  const [noDataFound, setNoDataFound] = useState(false);

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  const openPopup = (fileUrl) => {
    console.log("Opening popup with file URL:", fileUrl);
    setCurrentFileUrl(fileUrl);
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };
  const handleSocialMediaClick = (socialMedia) => {
    console.log(`Share on ${socialMedia}: ${currentFileUrl}`);
  };

  // useEffect(() => {
  //   if (
  //     localStorage.getItem("token") === "" ||
  //     localStorage.getItem("token") === "null" ||
  //     localStorage.getItem("token") === undefined ||
  //     localStorage.getItem("token") === null
  //   ) {
  //     setLoginPopup(true);
  //   } else {
  //     setLoginPopup(false);
  //   }
  // }, [setLoginPopup]);
  // if (mainloading === true) {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         height: "100vh",
  //       }}
  //     >
  //       <img
  //         src={Image_URL + "website/priloader_icon.svg"}
  //         style={{ alignSelf: "center" }}
  //       />
  //     </div>
  //   );
  // }
  useEffect(() => {
    ApiCall("", "get", "current_affairs", category_data);
  }, []);

  const category_data = useCallback((response) => {
    if (response.status === 200) {
      setAllCourse(response.data.data);
      // console.log(response.data.data);
    } else {
      console.log("API error:", response.status);
      setNoDataFound(true);
    }
  });
  window.scrollTo(0, 0);
  return (
    <div className="position-relative">
      <div className="custom_container container py-5 ">
        <h3 className="fw-bold font_size text_gradient ff_inter fs-1 mb-0">
          Weekly Current Affairs
        </h3>
        <h6 className="ff_inter position-relative text_dark_grey fs-5 mb-0 mt-3">
          CURRENT AFFAIRS
          <hr className=" position-absolute w-100 " />
        </h6>
        <div className="mt-5">
          {noDataFound ? (
            <p className="text-black fw-bold">No data found</p>
          ) : (
            <>
              {allCourse.map((obj, i) => {
                return (
                  <>
                    <div
                      key={i}
                      className="d-sm-flex justify-content-between align-items-center "
                    >
                      <div className="d-flex align-items-cente gap-3">
                        <p className="ff_inter position-relative text_dark_grey  mb-0 ">
                          {obj.title}
                        </p>
                        <img
                          onClick={() =>
                            openPopup(Image_URL + "currentaffairs/" + obj?.file)
                          }
                          className="cursor_pointer"
                          width={20}
                          height={10}
                          src="https://www.freeiconspng.com/thumbs/www-icon/vector-illustration-of-simple-share-icon--public-domain-vectors-23.png"
                          alt=""
                        />
                      </div>
                      {/* <button
                    onClick={() => {
                      if (
                        localStorage.getItem("token") === "" ||
                        localStorage.getItem("token") === "null" ||
                        localStorage.getItem("token") === undefined ||
                        localStorage.getItem("token") === null
                      ) {
                        setLoginPopup(true);
                        document.body.parentElement.classList.toggle(
                          "overflow-hidden"
                        );
                      }
                    }}
                    className="btn_login text-white ff_inter bg_gradient mt-3 mt-sm-0 border-0 p-2 px-lg-3 py-lg-1"
                  >
                    View Details &nbsp; &rarr;
                  </button> */}
                      {localStorage.getItem("token") ? (
                        <a
                          target="_blank"
                          href={Image_URL + "currentaffairs/" + obj?.file}
                        >
                          <button className="btn_login text-white ff_inter bg_gradient mt-3 mt-sm-0 border-0 p-2 px-lg-3 py-lg-1">
                            View Details &nbsp; &rarr;
                          </button>
                        </a>
                      ) : (
                        <button
                          onClick={() => {
                            setLoginPopup(true);
                            document.body.parentElement.classList.toggle(
                              "overflow-hidden"
                            );
                          }}
                          className="btn_login text-white ff_inter bg_gradient mt-3 mt-sm-0 border-0 p-2 px-lg-3 py-lg-1"
                        >
                          View Details &nbsp; &rarr;
                        </button>
                      )}
                    </div>
                    <div className="border my-3"></div>
                  </>
                );
              })}
            </>
          )}
        </div>
      </div>
      <div className="position-absolute  top-50 translate-middle start-50 ">
        {isPopupOpen && (
          <div className="popup-container position-relative pop_bg">
            <div className="popup-content">
              <a target="_blank" href="https://wa.me/917404960061">
                <div className="d-flex mb-3 gap-2 align-items-center">
                  &nbsp;
                  <img
                    className=""
                    width={40}
                    src="https://static.vecteezy.com/system/resources/thumbnails/023/986/631/small/whatsapp-logo-whatsapp-logo-transparent-whatsapp-icon-transparent-free-free-png.png"
                    alt=""
                  />
                  <p className="mb-0">Whatsapp</p>
                </div>
              </a>
              <a
                href="https://www.facebook.com/Dhurinaclass?mibextid=ZbWKwL "
                target="_blank ps-3"
                rel="noreferrer"
              >
                <div className="d-flex gap-2 ms-3 align-items-center">
                  <img
                    width={30}
                    className=""
                    src="https://static-00.iconduck.com/assets.00/facebook-icon-512x512-seb542ju.png"
                    alt="FbIcon"
                  />
                  <p className="mb-0 ps-1">Facebook</p>
                </div>
              </a>

              <a
                href="https://www.instagram.com/edu.dhurina_/"
                target="_blank"
                className="ms-3"
                rel="noreferrer"
              >
                <div className="d-flex ms-3 gap-2 align-items-center">
                  <img
                    width={30}
                    className=""
                    src="https://www.unipile.com/wp-content/uploads/2022/09/logo_instagram.png"
                    alt="InstaIcon"
                  />
                  <p className="mb-0 ps-1">Instagram</p>
                </div>
              </a>
              <a
                href="https://t.me/dhurina"
                target="_blank"
                className="ms-3"
                rel="noreferrer"
              >
                <div className="d-flex ms-3 gap-2 align-items-center">
                  <img
                    width={30}
                    className=""
                    src="https://cdn0.iconfinder.com/data/icons/social-flat-rounded-rects/512/telegram-512.png"
                    alt="TeleIcon"
                  />
                  <p className="mb-0 ps-1">Telegram</p>
                </div>
              </a>

              <img
                width={20}
                onClick={closePopup}
                className="cursor_pointer position-absolute cross_icon"
                src="https://icons.veryicon.com/png/o/miscellaneous/medium-thin-linear-icon/cross-23.png"
                alt=""
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CurentAffair;
