import React, {useState,  useCallback} from 'react';
// import { useNavigate } from "react-router-dom";
import { Container, Col, Row, Image, Form, Button, Alert, Spinner } from 'react-bootstrap';
import LoginImage from './../../assets/img/login.jpg';
// import OTPverify from './OTP-verify';
import MainLogo from '../../assets/img/logo.png';
import ApiCall from '../../api/callApi';



const ChangeNumber=()=>{

	const [otp_status,setOtpStatus]=useState(false)
	const [otp_modal,setOtpModal]=useState("none")
	const [number_modal,setNumberModal]=useState("block")
	const [otp,setOtp]=useState('')
	const [phone , setPhone] = useState('')
	const [gurukulno , setGurukulno] = useState('')
    const [submitStatus,setSubmitStatus] = useState(false) 
	const [show_succ, setShowSucc] = useState(false);
	const [show_fail, setShowFail] = useState(false); 
	const [title , setTitle] = useState('')
	const [message , setMessage] = useState('')
	
	function check_gurukul_number(e){
        e.preventDefault();

		setShowSucc(false)
		setShowFail(false)

        var data={
            "gurukulno":gurukulno,
            "phone":phone,
            "otp":otp,
        }
        setSubmitStatus(true)
        ApiCall(data,"post","check_gurukul_number",checkgurukulcallback);
    }

    const checkgurukulcallback = useCallback(response => 
    {
        console.log(response);
        if(response.data.Status==='Success'){
            setOtpModal("block")
            setOtpStatus(true)
            setTitle("Success")

            if (response.data.Details!=="Success") {
                setMessage("OTP has been sent successfully to your new phone number")
            } else {
                setOtpModal("none")
                setNumberModal("none")
                setMessage("Phone number updated successfully")
            }
            setShowSucc(true)
        }
        else{
            setTitle("Failed!!")
            setMessage(response.data.message)
            setShowFail(true)
        }
        setSubmitStatus(false)
    })


  return (
   
	 
	 <Container fluid className="login-bg minheightlogin">
		<Row className="align-items-center">
			<Col md={6} className="d-none d-sm-block text-center"> 
				<Image src={LoginImage} alt="login image" />
				<p className="f-11 mb-0 mt-5  text-muted">
					© 2021 Dhurina Ventures Pvt Ltd. All Right Reserved.
				</p>
			</Col>
			<Col md={6}>
			
				<div className="p-3 p-sm-5 my-2 m-sm-5 bg-white loginboxshadow">

				<form onSubmit={(e)=>check_gurukul_number(e)}>
					<Image src={MainLogo} alt="logo image" className="mb-2" />
					<h3 className="f-25 mt-4 mb-4">Update Gurukul Number!<br /></h3>
					<div className="row">	
                        <Col md={12} style={{display:number_modal}}>
                            <div className="mb-2">
                                <Form.Label className="f-12 fw-medium mb-1">Gurukul Registered No <span className="text-danger">*</span></Form.Label>
                                <div className="login-form">
                                    <Form.Control type="number" min="0" placeholder="Enter your Gurukul No "  onChange={(e)=>setGurukulno(e.target.value)} required onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                    }}
                                    disabled={otp_status}
                                    maxlength="10"/>
                                    <i className="fa fa-mobile-alt"></i>
                                </div> 
                            </div>  
                        </Col>
                        <Col md={12} style={{display:number_modal}}>
                            <div className="mb-2">
                                <Form.Label className="f-12 fw-medium mb-1">New Mobile Number<span className="text-danger">*</span></Form.Label>
                                <div className="login-form">
                                    <Form.Control type="number" min="0" placeholder="Enter New Mobile Number"  onChange={(e)=>setPhone(e.target.value)} required onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                    }}
                                    disabled={otp_status}
                                    maxlength="10"/>
                                    <i className="fa fa-mobile-alt"></i>
                                </div> 
                            </div>  
                        </Col>
                        <Col md={12} style={{display:otp_modal}}>
						<div className="mb-3">
							<Form.Label className="f-12 fw-medium mb-1">Enter OTP <span className="text-danger">*</span></Form.Label>
							<div className="d-flex justify-content-between">
								<Form.Control onKeyPress={(event) => {
									if (!/[0-9]/.test(event.key)) {
									event.preventDefault();
									}
								}} maxlength="5"  onChange={(e)=>setOtp(e.target.value)} />
							</div> 
						</div> 
                        </Col>
                        <Alert variant="danger"  show={show_fail} onClose={() => setShowFail(false)} dismissible className="px-3 py-2 f-13">
                            <Alert.Heading className="f-13 mb-0">
                                <i className="fas fa-exclamation-triangle me-2"></i><strong>{title}</strong>
                            </Alert.Heading>
                            {message}
                        </Alert>
                        <Alert variant="success"  show={show_succ} onClose={() => setShowSucc(false)} dismissible className="px-3 py-2 f-13">
                            <Alert.Heading className="f-13 mb-0">
                                <i className="fas fa-check me-2"></i><strong>{title}</strong>
                            </Alert.Heading>
                            {message}
                        </Alert>
                        
                        <Button variant="base-light" className="w-100 mt-4 btn-block loginshadowmain" type="submit">
                            {submitStatus?<Spinner as="span" animation="border" size="sm" />:<i className="far fa-paper-plane"> Submit </i> }
                        </Button>
					</div>
				</form>
				
				</div>  
			
				
			</Col> 
		</Row> 
	</Container>
	

  );
}

export default ChangeNumber;