import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Container,
  Col,
  Row,
  Form,
  ListGroup,
  Accordion,
  Modal,
  Tab,
  Tabs,
  Button,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ApiCall from "../api/callApi";
import MainLoaderImage from "./../assets/img/loading-buffering.gif";
import { initialize, makePayment } from "../components/Payment";

const SkillCourses = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [skill, setSkill] = useState([]);
  const [skillplus, setSkillPlus] = useState([]);
  const [package_id, setPackageId] = useState("");
  const [price, setPrice] = useState(1);
  const [myskill, setMySkill] = useState(false);
  const [myPlus, setMyPlus] = useState(false);
  const [usercourse, setUserCourse] = useState(false);
  const [phone, setPhone] = useState(false);
  const [show, setShow] = useState(false);

  const [skillcount, setSkillCount] = useState(0);
  const [pluscount, setPlusCount] = useState(0);

  const didMount = useRef(false);

  var paymentData = {
    token: "",
    order: "",
    mid: "",
    amount: "",
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    // Return early, if this is the first render:
    if (!didMount.current) {
      return (didMount.current = true);
    }

    setLoading(true);
    initialize(
      initializeCallback,
      price,
      localStorage.getItem("mobile"),
      localStorage.getItem("id")
    );
  }, [package_id]);

  function getData() {
    setLoading(true);
    ApiCall("", "get", "skill_courses", skillHome);
  }

  const skillHome = useCallback((response) => {
    setLoading(false);
    if (response.data.status === "true") {
      setSkill(response.data.skill_courses);
      setSkillPlus(response.data.skill_plus_courses);
      setMySkill(response.data.skill);
      setMyPlus(response.data.plus);
      setSkillCount(response.data.skill_count);
      setPlusCount(response.data.plus_count);
    } else {
      console.log("error");
    }
  }, []);

  const buyPackage = (type_id, amount) => {
    if (
      localStorage.getItem("mobile") == "" ||
      localStorage.getItem("mobile") == null ||
      localStorage.getItem("mobile") == undefined
    ) {
      navigate("/login");
    } else {
      setPrice(amount);
      setPackageId(type_id);
    }
  };

  const initializeCallback = useCallback((response) => {
    let obj = {
      token: response.token,
      order: response.order,
      mid: response.mid,
      amount: response.amount,
    };

    paymentData = obj;
    if (localStorage.getItem("token") === null) {
      navigate("/login");
    } else {
      var data = {
        phone: localStorage.getItem("mobile"),
        type: "skill",
        order_id: obj.order,
        price: 0,
        course_id: package_id,
      };

      // console.log(data);
      ApiCall(data, "post", "webhook", webhookcallback);
    }
  });

  const webhookcallback = useCallback((response) => {
    if (response.data.status === 200) {
      setLoading(true);
      paymentData.amount = response.data.amount;
      makePayment(paymentData, paycallback);
    } else {
      notify(response.data.message);
    }
  });

  const paycallback = useCallback((response, status) => {
    if (status) {
      updatepayment(response);
    } else {
      setLoading(false);
    }
  });

  const updatepayment = (response) => {
    var data = {
      status: response.STATUS,
      price: response.TXNAMOUNT,
      order_id: paymentData.order,
    };
    ApiCall(data, "post", "update_payment", updatepaymentcallback);
  };

  const updatepaymentcallback = useCallback((response) => {
    var state = {
      txnid: "",
      amount: "",
      course_name: "",
      transaction_status: 0,
    };
    if (response.data.status === 200) {
      state = {
        txnid: response.data.course_data.txnid,
        amount: response.data.course_data.amount,
        course_name: "Dhurina collabration with you",
        transaction_status: response.data.transaction_status,
      };
    }
    navigate("/payment-verification", { state });
  });

  const notify = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  function handleSubmitForm(e) {
    e.preventDefault();

    var obj = Object();
    obj.phone = phone;
    obj.course_id = usercourse;

    ApiCall(obj, "post", "activate_course", ActivateCallback);
  }

  const ActivateCallback = useCallback((response) => {
    setShow(false);
    if (response.data.status === 200) {
      notify("Course assigned successfully!");
      setPhone("");
    } else {
      notify(response.data.message);
    }
  });

  if (loading === true) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <img src={MainLoaderImage} style={{ alignSelf: "center" }} />
      </div>
    );
  }

  return (
    <>
      <section className="test-series py-5 bg-light-dark">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="section-title text-center">
                <h2>Dhurina Skill Courses</h2>
                {/* <p className="text-theme f-18 mb-0 mt-0">Are you Individual Student</p> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="bg-white">
        <Container>
          {skillcount > 0 || pluscount > 0 ? (
            <Row>
              <Button variant="base-light" onClick={() => setShow(true)}>
                Activate Student Courses
              </Button>
            </Row>
          ) : null}

          <Row>
            <Col md={4} className="py-5">
              <div className="section-title mt-4 mt-sm-0 mb-4 lefth2border">
                <h2 className="text-start">Courses</h2>
              </div>

              <div className="widget widget-accordion-inner">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <i className="fa fa-book me-2"></i>Skill Courses
                    </Accordion.Header>
                    <Accordion.Body className="widget-select-inner">
                      <ListGroup as="ul" className="all-courseleftsect">
                        {skill.map((item) => {
                          return (
                            <ListGroup.Item as="li" key={item.id}>
                              <div className="single-form-check form-check">
                                <Form.Label
                                  className="form-check-label mb-0"
                                  htmlFor="Allteach"
                                >
                                  {item.name}
                                </Form.Label>
                              </div>
                            </ListGroup.Item>
                          );
                        })}
                      </ListGroup>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Col>

            <Col md={8}>
              <div className="bg-light p-5 mobserachskill">
                <div className="navbar-area">
                  <div className="text-center">
                    <p className="text-theme f-18 mb-0 mt-0 p-2">
                      Dhurina Skill
                    </p>
                  </div>
                </div>

                <Row className="mt-4 pt-3 mob-skilliconhead">
                  <Col
                    xs={6}
                    onClick={() => {
                      buyPackage(1, 9900);
                    }}
                  >
                    <div className="item" style={{ textAlign: "center" }}>
                      <div className="single-course-wrap">
                        <div className="wrap-details">
                          <h6 className="text-theme f-18 fw-semi-bold me-2">
                            Plan 100
                          </h6>
                          <div className="price-wrap pt-0">
                            <Row className="align-items-center">
                              <Col xs={12} className="mb-2">
                                <span className="text-black-50 f-15">
                                  Min. Student 100
                                </span>
                              </Col>
                              <Col xs={12} className="mb-2">
                                <span className="text-black-50 f-15">
                                  <i class="fa fa-rupee"></i>99 X 100
                                </span>
                              </Col>
                              <Col xs={12} className="mb-2">
                                <span className="f-11 mt-1 ms-1 text-muted fw-bold mob-linefacultyheight">
                                  <i class="fa fa-rupee"></i>9900/-
                                </span>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col xs={6} onClick={() => buyPackage(2, 14850)}>
                    <div className="item" style={{ textAlign: "center" }}>
                      <div className="single-course-wrap">
                        <div className="wrap-details">
                          <h6 className="text-theme f-18 fw-semi-bold me-2">
                            Plan 200
                          </h6>
                          <div className="price-wrap pt-0">
                            <Row className="align-items-center">
                              <Col xs={12} className="mb-2">
                                <span className="text-black-50 f-15">
                                  Min. Student 200
                                </span>
                              </Col>
                              <Col xs={12} className="mb-2">
                                <span className="text-black-50 f-15">
                                  <i class="fa fa-rupee"></i>99X100 +
                                  99X100(50%)
                                </span>
                              </Col>
                              <Col xs={12} className="mb-2">
                                <span className="f-11 mt-1 ms-1 text-muted fw-bold mob-linefacultyheight">
                                  <i class="fa fa-rupee"></i>14850/-
                                </span>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col xs={6} onClick={() => buyPackage(3, 31680)}>
                    <div className="item" style={{ textAlign: "center" }}>
                      <div className="single-course-wrap">
                        <div className="wrap-details">
                          <h6 className="text-theme f-18 fw-semi-bold me-2">
                            Plan 500
                          </h6>
                          <div className="price-wrap pt-0">
                            <Row className="align-items-center">
                              <Col xs={12} className="mb-2">
                                <span className="text-black-50 f-15">
                                  Min. Student 500
                                </span>
                              </Col>
                              <Col xs={12} className="mb-2">
                                <span className="text-black-50 f-15">
                                  <i class="fa fa-rupee"></i>99X200 +
                                  99X300(60%)
                                </span>
                              </Col>
                              <Col xs={12} className="mb-2">
                                <span className="f-11 mt-1 ms-1 text-muted fw-bold mob-linefacultyheight">
                                  <i class="fa fa-rupee"></i>31680/-
                                </span>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col xs={6} onClick={() => buyPackage(4, 49000)}>
                    <div className="item" style={{ textAlign: "center" }}>
                      <div className="single-course-wrap">
                        <div className="wrap-details">
                          <h6 className="text-theme f-18 fw-semi-bold me-2">
                            Plan 1000
                          </h6>
                          <div className="price-wrap pt-0">
                            <Row className="align-items-center">
                              <Col xs={12} className="mb-2">
                                <span className="text-black-50 f-15">
                                  Min. Student 1000
                                </span>
                              </Col>
                              <Col xs={12} className="mb-2">
                                <span className="text-black-50 f-15">
                                  <i class="fa fa-rupee"></i>49 X 1000
                                </span>
                              </Col>
                              <Col xs={12} className="mb-2">
                                <span className="f-11 mt-1 ms-1 text-muted fw-bold mob-linefacultyheight">
                                  <i class="fa fa-rupee"></i>49000/-
                                </span>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={4} className="py-5">
              <div className="widget widget-accordion-inner">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <i className="fa fa-book me-2"></i>Skill Plus Courses
                    </Accordion.Header>
                    <Accordion.Body className="widget-select-inner">
                      <ListGroup as="ul" className="all-courseleftsect">
                        {skillplus.map((item) => {
                          return (
                            <ListGroup.Item as="li" key={item.id}>
                              <div className="single-form-check form-check">
                                <Form.Label
                                  className="form-check-label mb-0"
                                  htmlFor="Allteach"
                                >
                                  {item.name}
                                </Form.Label>
                              </div>
                            </ListGroup.Item>
                          );
                        })}
                      </ListGroup>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Col>

            <Col md={8}>
              <div className="bg-light p-5 mobserachskill">
                <div className="navbar-area">
                  <div className="text-center">
                    <p className="text-theme f-18 mb-0 mt-0 p-2">
                      Dhurina Skill Plus
                    </p>
                  </div>
                </div>

                <Row className="mt-4 pt-3 mob-skilliconhead">
                  <Col xs={6} onClick={() => buyPackage(5, 99950)}>
                    <div className="item" style={{ textAlign: "center" }}>
                      <div className="single-course-wrap">
                        <div className="wrap-details">
                          <h6 className="text-theme f-18 fw-semi-bold me-2">
                            Plan 50
                          </h6>
                          <div className="price-wrap pt-0">
                            <Row className="align-items-center">
                              <Col xs={12} className="mb-2">
                                <span className="text-black-50 f-15">
                                  Min. Student 50
                                </span>
                              </Col>
                              <Col xs={12} className="mb-2">
                                <span className="text-black-50 f-15">
                                  <i class="fa fa-rupee"></i>1999 X 50
                                </span>
                              </Col>
                              <Col xs={12} className="mb-2">
                                <span className="f-11 mt-1 ms-1 text-muted fw-bold mob-linefacultyheight">
                                  <i class="fa fa-rupee"></i>99,950/-
                                </span>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col xs={6} onClick={() => buyPackage(6, 149900)}>
                    <div className="item" style={{ textAlign: "center" }}>
                      <div className="single-course-wrap">
                        <div className="wrap-details">
                          <h6 className="text-theme f-18 fw-semi-bold me-2">
                            Plan 100
                          </h6>
                          <div className="price-wrap pt-0">
                            <Row className="align-items-center">
                              <Col xs={12} className="mb-2">
                                <span className="text-black-50 f-15">
                                  Min. Student 100
                                </span>
                              </Col>
                              <Col xs={12} className="mb-2">
                                <span className="text-black-50 f-15">
                                  <i class="fa fa-rupee"></i>1499X100
                                </span>
                              </Col>
                              <Col xs={12} className="mb-2">
                                <span className="f-11 mt-1 ms-1 text-muted fw-bold mob-linefacultyheight">
                                  <i class="fa fa-rupee"></i>1,49,900/-
                                </span>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col xs={6} onClick={() => buyPackage(7, 999000)}>
                    <div className="item" style={{ textAlign: "center" }}>
                      <div className="single-course-wrap">
                        <div className="wrap-details">
                          <h6 className="text-theme f-18 fw-semi-bold me-2">
                            Plan 1000
                          </h6>
                          <div className="price-wrap pt-0">
                            <Row className="align-items-center">
                              <Col xs={12} className="mb-2">
                                <span className="text-black-50 f-15">
                                  Min. Student 1000
                                </span>
                              </Col>
                              <Col xs={12} className="mb-2">
                                <span className="text-black-50 f-15">
                                  <i class="fa fa-rupee"></i>999X1000
                                </span>
                              </Col>
                              <Col xs={12} className="mb-2">
                                <span className="f-11 mt-1 ms-1 text-muted fw-bold mob-linefacultyheight">
                                  <i class="fa fa-rupee"></i>999000
                                </span>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col
                    xs={6}
                    onClick={() =>
                      notify(
                        "Contact our sales team for 1000+ course activation. Thanks"
                      )
                    }
                  >
                    <div className="item" style={{ textAlign: "center" }}>
                      <div className="single-course-wrap">
                        <div className="wrap-details">
                          <h6 className="text-theme f-18 fw-semi-bold me-2">
                            Plan 1000+
                          </h6>
                          <div className="price-wrap pt-0">
                            <Row className="align-items-center">
                              <Col xs={12} className="mb-2">
                                <span className="text-black-50 f-15">
                                  Min. Student 1000+
                                </span>
                              </Col>
                              <Col xs={12} className="mb-2">
                                <span className="text-black-50 f-15">
                                  <i class="fa fa-rupee"></i>999 X 1000 + 499 X
                                  More
                                </span>
                              </Col>
                              <Col xs={12} className="mb-2">
                                <span className="f-11 mt-1 ms-1 text-muted fw-bold mob-linefacultyheight">
                                  <i class="fa fa-rupee"></i>10 Lakh+
                                </span>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <div>
            <ToastContainer />
          </div>
        </Container>
      </section>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        className="checkoutpopup"
      >
        <Modal.Header className="ms-3 pb-2 ps-0 border-bottom" closeButton>
          <Modal.Title>Activate Student Course</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Tabs
            defaultActiveKey="addnew"
            id="Checkoutaddress"
            className="edl-nav nav-pills new-trending-course"
          >
            <Tab
              eventKey="addnew"
              title={`Skill (${skillcount}), Plus(${pluscount})`}
            >
              <div className="contactform p-0 shadow-none ">
                <form onSubmit={(e) => handleSubmitForm(e)}>
                  <input
                    type="text"
                    class="form-control"
                    max="10"
                    placeholder="10 Digit Mobile Number"
                    required
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key) || e.target.value.length >= 10) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  <Form.Select
                    className="form-control"
                    onChange={(e) => setUserCourse(e.target.value)}
                    required
                  >
                    <option value="">Select Course</option>
                    {myskill === true
                      ? skill.map((value) => {
                          return (
                            <option value={value.id} key={value.id}>
                              {value.name}
                            </option>
                          );
                        })
                      : null}

                    {myPlus === true
                      ? skillplus.map((value) => {
                          return (
                            <option value={value.id} key={value.id}>
                              {value.name}
                            </option>
                          );
                        })
                      : null}
                  </Form.Select>
                  <div className="border-top pt-3 mt-auto">
                    <Row>
                      <Col xs={12}>
                        <Button
                          variant="base-light"
                          className="w-100 mt-4 btn-block loginshadowmain"
                          type="submit"
                        >
                          <i className="far fa-paper-plane"> Submit </i>
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </form>
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SkillCourses;
