import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Col,
  Row,
  Image,
  Breadcrumb,
  Pagination,
} from "react-bootstrap";
import Applink from "../../include/applink/googleApple";
import MobappImage from "./../../assets/img/mob-app.png";

import { useParams, useNavigate } from "react-router-dom";
import ApiCall from "../../api/callApi";
import Video from "./video_component";
const Image_URL = process.env.REACT_APP_Bucket_URL;

function Myvideos() {
  var { course_id, subject_id } = useParams();

  const myarr = [];
  const [status, setStatus] = useState(1);
  const [page, setPage] = useState(1);
  const [data, setData] = useState(myarr);
  const [mainloading, setMainLoading] = useState(true);
  const [loadMoreText, setLoadMoreText] = useState("Load More");
  const [searchInput, setSearchInput] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (
      localStorage.getItem("token") === "" ||
      localStorage.getItem("token") === "null" ||
      localStorage.getItem("token") === undefined ||
      localStorage.getItem("token") === null
    ) {
      navigate("/login");
    } else {
      getData();
    }
  }, []);

  function getData() {
    ApiCall(
      { course_id: course_id, subject_id: subject_id, page: page },
      "post",
      "course_videos",
      courseareacallback
    );
    setLoadMoreText("Loading wait...");
  }

  const courseareacallback = useCallback((response) => {
    setMainLoading(false);
    setLoadMoreText("Load More");

    if (response.data.status === "true") {
      const res_data = response.data.data.data;
      setData([...data, ...res_data]);
      setPage(page + 1);

      if (res_data.length < 8) {
        setStatus(0);
      }
    } else {
      setStatus(0);
    }
  });

  const RouteChange = (e) => {
    navigate("/search-result?keyword=" + searchInput, {
      state: { course: searchInput },
    });
  };

  if (mainloading === true) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <img
          src={Image_URL + "website/priloader_icon.svg"}
          style={{ alignSelf: "center" }}
        />{" "}
      </div>
    );
  }

  return (
    <>
      {/* <Innerheader onSearch={(e) => RouteChange(e)}></Innerheader> */}

      <section className="breadcrumb-area pb-4 banner-area innerpagesbanner">
        <Container>
          <Row className="justify-content-center">
            <Col lg={12} className="align-self-center">
              <Breadcrumb className="mb-1">
                <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/my-courses">My Courses</Breadcrumb.Item>
                <Breadcrumb.Item active>Videos</Breadcrumb.Item>
              </Breadcrumb>
              <Row>
                <Col md={12}>
                  <h3 className="mb-0 f-25mob">Videos</h3>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="trending-courses-area py-5">
        <Container>
          {data.length < 1 ? (
            <h6 className="mt-4 mb-3" style={{ margin: "5%" }}>
              No videos found!!.
            </h6>
          ) : (
            <Row>
              {data.map((video) => {
                video.subject_id = subject_id;
                video.course_id = course_id;
                return <Video video={video} key={video.id} />;
              })}
            </Row>
          )}
          {status === 1 ? (
            <Pagination className="text-center d-block">
              <button
                className="btn-load-more mt-3 f-16 shadow-none btn btn-danger"
                onClick={() => getData()}
              >
                {loadMoreText}
              </button>
            </Pagination>
          ) : null}
        </Container>
      </section>

      <section className="pt-3 map-bg bg-theme-red">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col md={3}>
              <Image alt="img" src={MobappImage} />
            </Col>
            <Col md={6} className="downloadappbtnmob mb-4 schoolinner-mob-app">
              <div className="section-title mb-4">
                <h2 className=" text-start text-white pb-2">
                  Download Dhurina App
                </h2>
                <h5 className="text-white fw-medium f-22">
                  Get Amazing Offers, Free Notes and scholarship
                </h5>
              </div>
              <Applink></Applink>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Myvideos;
