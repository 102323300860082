import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Container,
  Col,
  Row,
  Image,
  Badge,
  Form,
  Modal,
  FormControl,
} from "react-bootstrap";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import MainLogo from "../../assets/img/logo.png";
import MainLoaderImage from "./../../assets/img/loading-buffering.gif";
import ApiCall from "../../api/callApi";
import SwitchMainleft from "../../assets/img/switchmain.png";
import { Link } from "react-router-dom";
const Image_URL = process.env.REACT_APP_Bucket_URL;

const PlayTest = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      localStorage.getItem("token") === "" ||
      localStorage.getItem("token") === "null" ||
      localStorage.getItem("token") === undefined ||
      localStorage.getItem("token") === null
    ) {
      navigate("/login");
    } else {
      getData();
    }
  }, []);

  const { id, name, instruction } = state;
  const [modelStatus, SetModelStatus] = useState(false);
  const [instructionmodelStatus, SetInstructionModelStatus] = useState(false);

  const [data, setData] = useState([
    {
      id: 0,
      option_1: "",
      option_2: "",
      option_3: "",
      option_4: "",
      option_5: "",
      question: "",
      answer: "",
      mark: "",
      user_ans: "",
      answered: false,
      marked: false,
      visited: false,
      markedAnswered: false,
    },
  ]);
  const [test, setTest] = useState({
    id: id,
    name: name,
    negative_mark: 0,
    marking_point: 0,
    marks: 0,
  });
  const [question, setQuestion] = useState({
    id: 0,
    question: "",
    option_1: "",
    option_2: "",
    option_3: "",
    option_4: "",
    option_5: "",
    mark: 0,
    user_ans: "",
    marked: false,
    visited: false,
    markedAnswered: false,
  });
  const [loading, setLoading] = useState(true);
  const [current, setCurrent] = useState(1);
  const [answered, setAnswered] = useState(0);
  const [marked, setMarked] = useState(0);
  const [notVisited, setNotVisited] = useState(0);
  const [markedAnswered, setMarkedAnswered] = useState(0);
  const [notAnswered, setNotAnswered] = useState(0);
  const [questionsids, setQuestionIds] = useState([]);
  const [expiryTime, setExpiryTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [teststartstatus, setTestStartStatus] = useState(false);
  const [countdownTime, setCountdownTime] = useState({
    countdownDays: 0,
    countdownHours: 0,
    countdownlMinutes: 0,
    countdownSeconds: 0,
  });
  const [timeremaining, SetTimeRemaining] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const [timetaken, setTimetaken] = useState(0);
  const [marks, setMarks] = useState(0);
  const [intervalname, setIntervalName] = useState("");
  const [reviewStatus, setReviewStatus] = useState(false);

  useEffect(() => {
    if (expiryTime != "") {
      StartTestTimer();
    }
  }, [loading]);

  useEffect(() => {
    if (endTime != "") {
      TestTimeTimer();
    }
  }, [teststartstatus]);

  const StartTestTimer = () => {
    const timeInterval = setInterval(() => {
      var countdownDateTime = new Date(expiryTime).getTime();
      var currentTime = new Date().getTime();
      var remainingDayTime = countdownDateTime - currentTime;
      var totalDays = Math.floor(remainingDayTime / (1000 * 60 * 60 * 24));

      var totalHours = Math.floor(
        (remainingDayTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var totalMinutes = Math.floor(
        (remainingDayTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      var totalSeconds = Math.floor((remainingDayTime % (1000 * 60)) / 1000);

      const runningCountdownTime = {
        countdownDays: totalDays,
        countdownHours: totalHours,
        countdownMinutes: totalMinutes,
        countdownSeconds: totalSeconds,
      };

      setCountdownTime(runningCountdownTime);
      if (remainingDayTime <= 0) {
        clearInterval(timeInterval);
        setExpiryTime("");

        if (teststartstatus === false) {
          setTestStartStatus(true);
        }
      }
    }, 1000);
  };

  const TestTimeTimer = () => {
    let timecount = 1;

    const myInterval = setInterval(() => {
      var countdownDateTime = new Date(endTime).getTime();
      var currentTime = new Date().getTime();
      var remainingDayTime = countdownDateTime - currentTime;

      var Hours = Math.floor(
        (remainingDayTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var Minutes = Math.floor(
        (remainingDayTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      var Seconds = Math.floor((remainingDayTime % (1000 * 60)) / 1000);

      const runningCountdownTime = {
        hours: Hours,
        minutes: Minutes,
        seconds: Seconds,
      };

      SetTimeRemaining(runningCountdownTime);
      if (remainingDayTime <= 0) {
        clearInterval(myInterval);
        submitTest();
      } else {
        timecount++;
        setTimetaken(timecount + 1);
      }
    }, 1000);

    setIntervalName(myInterval);
  };

  // const submitTest = () => {
  //   setEndTime("");
  //   clearInterval(intervalname);
  //   let total = 0;
  //   data.map((item) => {
  //     if (item.answer == item.user_ans) {
  //       total++;
  //     }
  //   });

  //   setMarks(total);

  //   if (total > 0) {
  //     ApiCall(
  //       { test_id: id, marks: total, take_time: timetaken },
  //       "post",
  //       "submit_test",
  //       submitCallback
  //     );
  //   }

  //   SetModelStatus(true);
  // };
  const submitTest = () => {
    const confirmSubmit = window.confirm(
      "Are you sure want to submit the test?"
    );
    if (confirmSubmit) {
      setEndTime("");
      clearInterval(intervalname);
      let total = 0;
      data.forEach((item) => {
        if (item.answer === item.user_ans) {
          total++;
        }
      });

      setMarks(total);

      if (total > 0) {
        ApiCall(
          { test_id: id, marks: total, take_time: timetaken },
          "post",
          "submit_test",
          submitCallback
        );
      }

      SetModelStatus(true);
    }
  };

  const submitCallback = useCallback((response) => {
    console.log(response);
  });

  function getData() {
    ApiCall({ test_id: id }, "post", "get_questions", testcallback);
  }

  const testcallback = useCallback((response) => {
    if (response.data.status === "true") {
      setEndTime(
        new Date(new Date().getTime() + response.data.test.duration * 60000)
      );
      setExpiryTime(response.data.test.test_time);

      var starttime = new Date(response.data.test.test_time).getTime();
      var currentTime = new Date().getTime();
      var remainingDayTime = starttime - currentTime;
      if (remainingDayTime < 1) {
        setTestStartStatus(true);
      }

      setData(response.data.data);
      setTest(response.data.test);
      setQuestion(response.data.data[0]);
      setNotVisited(response.data.data.length - 1);
      setNotAnswered(response.data.data.length);
      questionsids.push(response.data.data[0].id);
      setQuestionIds(questionsids);
      setLoading(false);
    } else {
      console.log("Something went wrong!!");
    }
  });

  const nextData = (type) => {
    var currentQuestion = data[current - 1];

    if (type === "mark") {
      if (currentQuestion.user_ans !== "") {
        currentQuestion.markedAnswered = true;
        currentQuestion.marked = false;
      } else {
        currentQuestion.marked = true;
        currentQuestion.markedAnswered = false;
      }
    } else {
      currentQuestion.marked = false;
      currentQuestion.markedAnswered = false;
    }

    data[current - 1] = currentQuestion;
    setData(data);

    // Check if it's the last question
    if (current < data.length) {
      setQuestion(data[current]);
      setCurrent(current + 1);
    } else {
      const confirmSubmit = window.confirm(
        "Are you sure want to submit the test?"
      );
      if (confirmSubmit) {
        submitTest();
      }
    }

    calculate();
  };

  const setAnswer = (option) => {
    data[current - 1]["user_ans"] = option;
    setData(data);
    setQuestion({ ...question, user_ans: option });

    calculate();
  };

  const calculate = () => {
    var answered_count = 0;
    var notanswered_count = 0;
    var markedcount = 0;
    var markedAnsweredcount = 0;

    data.map((item) => {
      if (item.markedAnswered === true) {
        markedAnsweredcount++;
      } else if (item.user_ans != "") {
        answered_count++;
      } else if (item.user_ans == "") {
        notanswered_count++;
      }

      if (item.marked === true) {
        markedcount++;
      }
    });

    if (questionsids.indexOf(question.id) == -1) {
      questionsids.push(question.id);
      setQuestionIds(questionsids);
    }

    setMarked(markedcount);
    setMarkedAnswered(markedAnsweredcount);
    setAnswered(answered_count);
    setNotAnswered(notanswered_count);
    setNotVisited(data.length - questionsids.length);
  };

  // const jumpToQuestion = async (value, index) => {
  //   setQuestion(value);
  //   setCurrent(index + 1);

  //   if (questionsids.indexOf(value.id) == -1) {
  //     questionsids.push(value.id);
  //     setQuestionIds(questionsids);
  //     setNotVisited(data.length - questionsids.length);
  //   }
  // };
  const jumpToQuestion = async (value, index, source) => {
    let newIndex = current;

    if (source === "badge") {
      newIndex = index + 1;
    } else if (source === "next" && current < data.length) {
      newIndex = current + 1;
    } else if (source === "previous" && current > 1) {
      newIndex = current - 1;
    }

    const newQuestion = data[newIndex - 1];

    setQuestion(newQuestion);
    setCurrent(newIndex);

    if (questionsids.indexOf(newQuestion.id) === -1) {
      questionsids.push(newQuestion.id);
      setQuestionIds([...questionsids]);
      setNotVisited(data.length - questionsids.length);
    }
  };
  const [finishStatus, setfinishStatus] = useState(false);
  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (window.confirm("Do you want to exit the test ?")) {
        setfinishStatus(true);
        // your logic
        // props.history.push("/");
        navigate(-1);
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false);
      }
    }
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);
  if (loading === true) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <img
          src={Image_URL + "website/priloader_icon.svg"}
          style={{ alignSelf: "center" }}
        />{" "}
      </div>
    );
  } else {
    return (
      <div>
        {expiryTime != "" &&
        (countdownTime.countdownDays > 0 ||
          countdownTime.countdownHours > 0 ||
          countdownTime.countdownlMinutes > 0 ||
          countdownTime.countdownSeconds > 0) ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            {" "}
            <h4 style={{ color: "red" }}>
              Test will be started in: {countdownTime.countdownDays}:
              {countdownTime.countdownHours}:{countdownTime.countdownMinutes}:
              {countdownTime.countdownSeconds}
            </h4>
          </div>
        ) : (
          <>
            <div className="bg-light shadow py-2">
              <Container fluid>
                <Row className="align-items-center text-center text-sm-start">
                  <Col md={3}>
                    <Link to="/">
                      <Image
                        src={MainLogo}
                        alt="img"
                        className="mocklogo cursor_pointer"
                      />
                    </Link>
                  </Col>
                  <Col md={6} className="my-3 my-sm-0">
                    <h4 className="text-center pageheadmocktest m-0">
                      {test.name}
                    </h4>
                  </Col>
                  {reviewStatus === false ? (
                    <Col md={3} className="text-end">
                      <button className="btn btn-online lh me-2 mocktimeleft">
                        Time Left{" "}
                        <span>
                          {teststartstatus === true ? timeremaining.hours : 0}
                        </span>{" "}
                        :{" "}
                        <span>
                          {teststartstatus === true ? timeremaining.minutes : 0}
                        </span>{" "}
                        :{" "}
                        <span>
                          {teststartstatus === true ? timeremaining.seconds : 0}
                        </span>
                      </button>
                      {/* <Button variant="base-light" className="loginshadowmain">Pause Test</Button> */}
                    </Col>
                  ) : null}
                </Row>
              </Container>
            </div>

            <Container fluid>
              <Row>
                <Col
                  md={9}
                  className="border-end d-flex flex-column heightsection"
                >
                  <div className="border-bottom pb-2">
                    <Row className="mt-4">
                      {/* <Col md={12}>
                                                <Badge bg="secondary">Quant</Badge>
                                            </Col> */}
                      <Col md={9}>
                        <h6>Question No. {current}</h6>
                      </Col>
                      <Col md={3} className="text-sm-end mt-2 mt-sm-0">
                        <strong className="me-2">Marks</strong>{" "}
                        <Badge
                          bg="success"
                          className="py-1 px-3 fw-normal f-13"
                        >
                          {test.marks}
                        </Badge>{" "}
                        <Badge bg="danger" className="py-1 px-3 fw-normal f-13">
                          {test.marking_point}
                        </Badge>
                      </Col>
                    </Row>
                  </div>

                  {reviewStatus === false ? (
                    <>
                      <Row className="mt-4" style={{ overflowY: "scroll" }}>
                        <Col md={12} className="questlist">
                          <td
                            dangerouslySetInnerHTML={{
                              __html: question.question,
                            }}
                          />
                          <Form.Group>
                            <div className="d-flex align-items-center gap-4 mt-3 pt-2">
                              <Form.Control
                                type="radio"
                                className="with-gap"
                                name="answer"
                                id="option1"
                                checked={
                                  question.user_ans == "A" ? "checked" : null
                                }
                                onClick={() => setAnswer("A")}
                              />
                              <Form.Check.Label
                                className="mb-0"
                                htmlFor="option1 mt-2"
                              >
                                <td
                                  dangerouslySetInnerHTML={{
                                    __html: question.option_1,
                                  }}
                                />
                              </Form.Check.Label>
                            </div>
                          </Form.Group>
                          <Form.Group>
                            <div className="d-flex align-items-center gap-4 mt-3 pt-2">
                              <Form.Control
                                type="radio"
                                className="with-gap"
                                name="answer"
                                id="option2"
                                checked={
                                  question.user_ans == "B" ? "checked" : null
                                }
                                onClick={() => setAnswer("B")}
                              />
                              <Form.Check.Label
                                className="mb-0"
                                htmlFor="option2 mt-2"
                              >
                                <td
                                  dangerouslySetInnerHTML={{
                                    __html: question.option_2,
                                  }}
                                />
                              </Form.Check.Label>
                            </div>
                          </Form.Group>
                          <Form.Group>
                            <div className="d-flex align-items-center gap-4 mt-3 pt-2">
                              <Form.Control
                                type="radio"
                                className="with-gap"
                                name="answer"
                                id="option3"
                                checked={
                                  question.user_ans == "C" ? "checked" : null
                                }
                                onClick={() => setAnswer("C")}
                              />
                              <Form.Check.Label
                                className="mb-0"
                                htmlFor="option3 mt-2"
                              >
                                <td
                                  dangerouslySetInnerHTML={{
                                    __html: question.option_3,
                                  }}
                                />
                              </Form.Check.Label>
                            </div>
                          </Form.Group>
                          <Form.Group>
                            <div className="d-flex align-items-center gap-4 mt-3 pt-2">
                              <Form.Control
                                type="radio"
                                className="with-gap"
                                name="answer"
                                id="option4"
                                checked={
                                  question.user_ans == "D" ? "checked" : null
                                }
                                onClick={() => setAnswer("D")}
                              />
                              <Form.Check.Label
                                className="mb-0"
                                htmlFor="option4 mt-2"
                              >
                                <td
                                  dangerouslySetInnerHTML={{
                                    __html: question.option_4,
                                  }}
                                />
                              </Form.Check.Label>
                            </div>
                          </Form.Group>
                          {question.option_5 != "" &&
                          question.option_5 != null &&
                          question.option_5 != undefined ? (
                            <Form.Group>
                              <div>
                                <Form.Control
                                  type="radio"
                                  className="with-gap"
                                  name="answer"
                                  id="option5"
                                  checked={
                                    question.user_ans == "E" ? "checked" : null
                                  }
                                  onClick={() => setAnswer("E")}
                                />
                                <Form.Check.Label htmlFor="option5 mt-2">
                                  <td
                                    dangerouslySetInnerHTML={{
                                      __html: question.option_5,
                                    }}
                                  />
                                </Form.Check.Label>
                              </div>
                            </Form.Group>
                          ) : null}
                        </Col>
                      </Row>

                      <div className=" mt-auto">
                        <div className="d-flex justify-content-between mb-3 align-items-center">
                          <button
                            onClick={() =>
                              jumpToQuestion(null, null, "previous")
                            }
                            className="btn_login px-4 py-2 rounded-3 text-white ff_inter bg_gradient cursor_pointer border-0"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => jumpToQuestion(null, null, "next")}
                            className="btn_login px-4 py-2 rounded-3 text-white ff_inter bg_gradient cursor_pointer border-0"
                          >
                            Next
                          </button>
                        </div>
                        <div className="border-top">
                          <Row className="my-3">
                            <Col md={9}>
                              <Button
                                variant="btn_login text-white ff_inter bg_gradient cursor_pointer border-0 me-2 my-3 my-sm-0"
                                onClick={() => nextData("mark")}
                              >
                                Mark for Review & Next
                              </Button>
                              <Button
                                variant="btn_login text-white ff_inter bg_gradient cursor_pointer border-0"
                                onClick={() => setAnswer("")}
                              >
                                Clear Response
                              </Button>
                            </Col>
                            <Col md={3} className="text-sm-end">
                              <Button
                                variant=" my-3 my-sm-0 btn_login text-white ff_inter bg_gradient cursor_pointer border-0 "
                                onClick={() => nextData("save")}
                              >
                                Save & Next
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <Row className="mt-4" style={{ overflowY: "scroll" }}>
                        <Col md={12} className="questlist">
                          <td
                            dangerouslySetInnerHTML={{
                              __html: question.question,
                            }}
                          />
                          <Form.Group>
                            <div className="d-flex align-items-center gap-4 mt-3 pt-2">
                              <Form.Control
                                type="radio"
                                className="with-gap"
                                name="answer"
                                id="option1"
                                checked={
                                  question.answer == "A" ? "checked" : null
                                }
                              />
                              <Form.Check.Label htmlFor="option1 mt-2">
                                <td
                                  dangerouslySetInnerHTML={{
                                    __html: question.option_1,
                                  }}
                                />
                              </Form.Check.Label>
                            </div>
                          </Form.Group>
                          <Form.Group>
                            <div className="d-flex align-items-center gap-4 mt-3 pt-2">
                              <Form.Control
                                type="radio"
                                className="with-gap"
                                name="answer"
                                id="option2"
                                checked={
                                  question.answer == "B" ? "checked" : null
                                }
                              />
                              <Form.Check.Label htmlFor="option2 mt-2">
                                <td
                                  dangerouslySetInnerHTML={{
                                    __html: question.option_2,
                                  }}
                                />
                              </Form.Check.Label>
                            </div>
                          </Form.Group>
                          <Form.Group>
                            <div className="d-flex align-items-center gap-4 mt-3 pt-2">
                              <Form.Control
                                type="radio"
                                className="with-gap"
                                name="answer"
                                id="option3"
                                checked={
                                  question.answer == "C" ? "checked" : null
                                }
                              />
                              <Form.Check.Label htmlFor="option3 mt-2">
                                <td
                                  dangerouslySetInnerHTML={{
                                    __html: question.option_3,
                                  }}
                                />
                              </Form.Check.Label>
                            </div>
                          </Form.Group>
                          <Form.Group>
                            <div className="d-flex align-items-center gap-4 mt-3 pt-2">
                              <Form.Control
                                type="radio"
                                className="with-gap"
                                name="answer"
                                id="option4"
                                checked={
                                  question.answer == "D" ? "checked" : null
                                }
                              />
                              <Form.Check.Label htmlFor="option4 mt-2">
                                <td
                                  dangerouslySetInnerHTML={{
                                    __html: question.option_4,
                                  }}
                                />
                              </Form.Check.Label>
                            </div>
                          </Form.Group>
                          {question.option_5 != "" &&
                          question.option_5 != null &&
                          question.option_5 != undefined ? (
                            <Form.Group>
                              <div className="d-flex align-items-center gap-4 mt-3 pt-2">
                                <Form.Control
                                  type="radio"
                                  className="with-gap"
                                  name="answer"
                                  id="option5"
                                  checked={
                                    question.answer == "E" ? "checked" : null
                                  }
                                />
                                <Form.Check.Label htmlFor="option5 mt-2">
                                  <td
                                    dangerouslySetInnerHTML={{
                                      __html: question.option_5,
                                    }}
                                  />
                                </Form.Check.Label>
                              </div>
                            </Form.Group>
                          ) : null}
                        </Col>
                      </Row>
                      <div className="mt-auto">
                        <div className="d-flex align-items-center gap-3 mb-3">
                          {question.explanation ? (
                            <>
                              <h6 className="fw-bold mb-0">Explanation =</h6>
                              <td
                                dangerouslySetInnerHTML={{
                                  __html: question.explanation,
                                }}
                              />
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="d-flex justify-content-between mb-3 mt-4 align-items-center">
                          <button
                            onClick={() =>
                              jumpToQuestion(null, null, "previous")
                            }
                            className="btn_login px-4 py-2 rounded-3 text-white ff_inter bg_gradient cursor_pointer border-0"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => jumpToQuestion(null, null, "next")}
                            className="btn_login px-4 py-2 rounded-3 text-white ff_inter bg_gradient cursor_pointer border-0"
                          >
                            Next
                          </button>
                        </div>
                        <div className="border-top ">
                          <Row className="my-3">
                            <Col md={12}>
                              <strong className="me-2">Your Answer:</strong>{" "}
                              <Badge
                                bg="success"
                                className="py-1 px-3 fw-normal f-13"
                              >
                                {question.user_ans}
                              </Badge>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </>
                  )}
                </Col>
                <Col md={3} className="d-flex flex-column heightsection">
                  <h6 className="mt-4">
                    Hello! {localStorage.getItem("name")}
                  </h6>

                  <Row className="questlables">
                    <Col md={6}>
                      <Badge bg="success">{answered}</Badge> Answered
                    </Col>
                    <Col md={6}>
                      <Badge bg="primary">{marked}</Badge> Marked
                    </Col>
                    <Col md={6}>
                      <Badge bg="dark">{notVisited}</Badge> Not Visited
                    </Col>
                    <Col md={6}>
                      <Badge bg="info">{markedAnswered}</Badge> Marked & Ansered
                    </Col>
                    <Col md={6}>
                      <Badge bg="danger">{notAnswered}</Badge> Not Answered
                    </Col>
                  </Row>

                  <div className=" rounded p-2 mt-3">
                    <h6 className="f-14 m-0">Questions:</h6>
                  </div>

                  <div className="questlnolist" style={{ overflowY: "scroll" }}>
                    {data.map((value, index) => {
                      if (index + 1 === current) {
                        return (
                          <Badge
                            bg="btn_login text-white ff_inter bg_gradient cursor_pointer border-0"
                            onClick={() =>
                              jumpToQuestion(value, index, "badga")
                            }
                            key={index}
                          >
                            {index + 1}
                          </Badge>
                        );
                      } else {
                        return (
                          <Badge
                            className={
                              index + 1 === current
                                ? "cursor_pointer btn_login text-white ff_inter bg_gradient border-0"
                                : "cursor_pointer"
                            }
                            bg={
                              value.markedAnswered === true
                                ? "info"
                                : value.user_ans !== ""
                                ? "success"
                                : value.marked === true
                                ? "primary"
                                : "light"
                            }
                            onClick={() =>
                              jumpToQuestion(value, index, "badge")
                            }
                            key={index}
                          >
                            {index + 1}
                          </Badge>
                        );
                      }
                    })}
                  </div>

                  <div className="border-top mt-auto">
                    <Row className="my-3">
                      {/* <Col md={12}> */}
                      {/* <Button variant="secondary btn-sm-danger me-2 my-3 my-sm-0">Question Papers</Button>   */}
                      {/* <Button variant="secondary btn-sm-danger" onClick={()=>SetInstructionModelStatus(true)}>Instructions</Button>
                                            </Col> */}

                      <Col md={12} className="text-end">
                        {reviewStatus === true ? (
                          <Button
                            variant="danger w-100 btn-sm-danger"
                            onClick={() => navigate("/my-courses")}
                          >
                            Close
                          </Button>
                        ) : (
                          <Button
                            variant="btn_login text-white ff_inter submit_button cursor_pointer border-0 w-100 "
                            onClick={() => submitTest()}
                          >
                            Submit Test
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </>
        )}

        <Modal
          size="lg"
          id="switchpopup"
          show={instructionmodelStatus}
          onHide={() => SetInstructionModelStatus(false)}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Row className="align-items-center">
              <Col md={12} className="switchlnguageox">
                <Image src={MainLogo} alt="img" className="mb-3 mb-sm-5" />
                <h4 className="f-25 mb-0 f-22mob">Instructions</h4>
                <div dangerouslySetInnerHTML={{ __html: instruction }} />

                <Button
                  variant="base-light"
                  className="w-100 mt-4"
                  onClick={() => SetInstructionModelStatus(false)}
                >
                  Close
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          id="switchpopup"
          show={modelStatus}
          onHide={() => navigate("/my-courses")}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Row className="align-items-center">
              <Col md={6} className="d-none d-sm-block">
                <Image src={SwitchMainleft} alt="img" className="mt-5" />
              </Col>
              <Col md={6} className="switchlnguageox">
                <Image src={MainLogo} alt="img" className="mb-3 mb-sm-5" />
                <h4 className="f-25 mb-0 f-22mob">Result</h4>
                <p className="mb-4 mt-1">{test.name}</p>

                <div className="connectoptionmobview bookcourseconnecttop bg-light">
                  <span className="d-flex align-items-center gap-2">
                    <FormControl
                      type="radio"
                      className="with-gap"
                      defaultChecked={true}
                      readOnly={true}
                      checked={true}
                      name="selectpre"
                      id="Competitive"
                      value="Competitive"
                    />
                    <label htmlFor="Competitive" className="f-22">
                      Marks: {marks}/{test.marks}
                    </label>
                  </span>
                </div>
                <div className="connectoptionmobview bookcourseconnecttop bg-light">
                  <span className="d-flex align-items-center gap-2">
                    <FormControl
                      type="radio"
                      className="with-gap"
                      defaultChecked={true}
                      readOnly={true}
                      checked={true}
                      name="selectpre"
                      id="School2"
                      value="School"
                    />
                    <label htmlFor="School2" className="f-22">
                      Time Taken:{" "}
                      {timetaken < 3600
                        ? new Date(timetaken * 1000).toISOString().slice(14, 19)
                        : new Date(timetaken * 1000)
                            .toISOString()
                            .substring(11, 19)}
                    </label>
                  </span>
                </div>

                <Row className="align-items-center">
                  <Col md={6} className="d-none d-sm-block">
                    <Button
                      variant="base-light"
                      className="w-100 mt-4"
                      onClick={() => {
                        jumpToQuestion(data[0], 0);
                        setReviewStatus(true);
                        SetModelStatus(false);
                      }}
                    >
                      Review Test
                    </Button>
                  </Col>
                  <Col md={6} className="d-none d-sm-block">
                    <Button
                      variant="base-light"
                      className="w-100 mt-4"
                      onClick={() => navigate("/my-courses")}
                    >
                      Close Test
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
};

export default PlayTest;
