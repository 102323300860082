import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Image,
  Breadcrumb,
  Accordion,
} from "react-bootstrap";
import Applink from "../../include/applink/googleApple";
import MobappImage from "./../../assets/img/mob-app.png";
import ApiCall from "../../api/callApi";
import Innerheader from "../../include/header/innerHeader";
import MainLoaderImage from "./../../assets/img/loading-buffering.gif";
import { Parser } from "html-to-react";
import { useNavigate } from "react-router-dom";
const Image_URL = process.env.REACT_APP_Bucket_URL;

function MyCourses({ setLoginPopup }) {
  const [status, setStatus] = useState(1);
  const [data, setData] = useState([]);
  const [mainloading, setMainLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (
      localStorage.getItem("token") === "" ||
      localStorage.getItem("token") === "null" ||
      localStorage.getItem("token") === undefined ||
      localStorage.getItem("token") === null
    ) {
      navigate(-1);
      setLoginPopup(true);
      document.body.parentElement.classList.toggle("overflow-hidden");
    } else {
      getData();
    }
  }, [setLoginPopup]);

  function getData() {
    ApiCall("", "get", "my_courses", courseareacallback);
  }

  const courseareacallback = useCallback((response) => {
    setMainLoading(false);
    if (response.data.status === "true") {
      // console.log(response.data.data);
      setData(response.data.data);
    } else {
      setStatus(0);
    }
  });
  window.scrollTo(0, 0);

  if (mainloading === true) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <img
          src={Image_URL + "website/priloader_icon.svg"}
          style={{ alignSelf: "center" }}
        />
      </div>
    );
  }

  //   const RouteChange = (e) => {
  //     navigate("/search-result?keyword=" + searchInput, {
  //       state: { course: searchInput },
  //     });
  //   };

  return (
    <>
      {/* <Innerheader onSearch={(e) => RouteChange(e)}></Innerheader> */}

      <section className="pt-5">
        <div className="container custom_container">
          <Row className="justify-content-center">
            <Col lg={6} className="align-self-center">
              <Breadcrumb className="mb-1">
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item className="text_gradient" active>
                  My Courses
                </Breadcrumb.Item>
              </Breadcrumb>
              <Row>
                <Col md={12}>
                  <h3 className="mb-0 f-25mob">My Courses</h3>
                </Col>
              </Row>
            </Col>
            <Col lg={6} className="text-sm-end">
              {/* <Link to="/test-list" className="btn btn-warning mt-sm-0">
                                <i className="fa fa-file-pdf"></i> Mock Test
                            </Link> */}
              <Link
                to="/live-classes"
                className="btn bg_gradient text-white mt-sm-0"
              >
                <i className="fa fa-desktop"></i> Live Classes
              </Link>
            </Col>
          </Row>
        </div>
      </section>

      <section className="trending-courses-area py-5">
        <Container className="custom_container">
          <Row>
            <Col lg={12} className="sidebar-area">
              <div className="widget-accordion-inner">
                {status == 0 ? (
                  <h6 className="mt-4 mb-3" style={{ margin: "5%" }}>
                    No course found!. Buy courses from Dhurina App/Website to
                    watch live courses here......
                  </h6>
                ) : (
                  <Accordion defaultActiveKey="0">
                    {data.map((value, key) => {
                      return (
                        <Accordion.Item
                          className="mt-3"
                          eventKey={`${key}`}
                          expanded={true}
                        >
                          <Accordion.Header>
                            <i className="fa fa-book me-2"></i> {value.name}
                          </Accordion.Header>
                          <Accordion.Body className="widget-select-inner">
                            <p>{Parser().parse(value.description)}</p>

                            {value.show_status == 1 ? (
                              <>
                                <div className="border-bottom my-4"></div>
                                <h6 className="mt-4 mb-3">Videos</h6>
                                <div className="d-flex flex-wrap align-items-center">
                                  {value.subjects.map((subject, subkey) => {
                                    return (
                                      <Link
                                        to={
                                          "/course-videos/" +
                                          value.id +
                                          "/" +
                                          subject.id
                                        }
                                        className="mb-2 text-nowrap d-flex align-items-center me-2 px-3 py-2 btn-online"
                                        key={subkey}
                                      >
                                        {subject.name}
                                      </Link>
                                    );
                                  })}
                                </div>
                              </>
                            ) : null}

                            <div className="border-bottom my-4"></div>

                            <h6 className="mt-4 mb-3">Mock Test</h6>
                            <div className="d-flex flex-wrap align-items-center">
                              {value.subjects.map((subject, subkey) => {
                                return (
                                  <Link
                                    to={`/test-list/${value.id}/${subject.id}`}
                                    className="mb-2 me-2 d-flex align-items-center text-nowrap px-3 py-2 btn-online"
                                    key={subkey}
                                  >
                                    {subject.name}
                                  </Link>
                                );
                              })}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })}
                  </Accordion>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* <section className="pt-3 map-bg bg-theme-red">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col md={3}>
              <Image alt="img" src={MobappImage} />
            </Col>
            <Col md={6} className="downloadappbtnmob mb-4 schoolinner-mob-app">
              <div className="section-title mb-4">
                <h2 className=" text-start text-white pb-2">
                  Download Dhurina App
                </h2>
                <h5 className="text-white fw-medium f-22">
                  Get Amazing Offers, Free Notes and scholarship
                </h5>
              </div>
              <Applink></Applink>
            </Col>
          </Row>
        </Container>
      </section> */}
    </>
  );
}

export default MyCourses;
