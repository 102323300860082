import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Container,
  Col,
  Row,
  Image,
  Breadcrumb,
  Accordion,
  Modal,
  Button,
} from "react-bootstrap";
import ApiCall from "../../api/callApi";
const IMAGE_URL = process.env.REACT_APP_Bucket_URL;

const Test = ({ setLoginPopup }) => {
  const [searchInput, setSearchInput] = useState("");
  const [error, setError] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [testAvailable, setTestAvailable] = useState("");
  const [test, setTest] = useState([]);
  const [slides, setSlides] = useState(1);
  const [instructionmodelStatus, SetInstructionModelStatus] = useState(false);
  const [instructions, SetInstructions] = useState("");
  const navigate = useNavigate();
  // -------------

  const [isViewPolicy, setIsViewPolicy] = useState(true);
  const [show, setShow] = useState(false);
  const togglePolicy = () => {
    setIsViewPolicy(!isViewPolicy);
  };
  var paymentData = {
    token: "",
    order: "",
    mid: "",
    amount: "",
  };
  const [mainloading, setMainLoading] = useState(true);

  // ----------
  const settings = {
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    // eslint-disable-next-line no-dupe-keys
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [testData, setTestData] = useState({
    id: "",
    name: "",
    instruction: "",
  });

  useEffect(() => {
    if (testSeries && testSeries.purchase_status === "false") {
      navigate(-1);
    }
  }, []);
  const showInstructions = (id, name, instruction) => {
    setTestData({ id: id, name: name, instruction: instruction });
    SetInstructionModelStatus(true);
  };

  useEffect(() => {
    if (searchInput.trim() === "") {
      setSuggestions([]);
      setIsSearching(false);
      return;
    }
    if (searchInput.length >= 3) {
      function filterCourse() {
        const body = {
          keyword: searchInput,
        };
        ApiCall(body, "post", `search_content`, filtercoursecallback);
        setError("");
      }
      filterCourse();
    } else {
      setError("No Data Found");
    }
    if (searchInput.length > 0) {
      setIsSearching(true);
    }
  }, [searchInput]);

  const filtercoursecallback = useCallback((response) => {
    if (response.data.status === true) {
      setSuggestions(response.data.data);
    } else {
      setSuggestions([]);
      console.log("Error fetching suggestions:");
    }
  }, []);

  const RouteChange = () => {
    if (searchInput.trim() === "") {
      setError("Search field cannot be empty");
      setSuggestions([]);
      return;
    }
    navigate("/search-result?keyword=" + searchInput, {
      state: { course: searchInput },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    RouteChange();
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchInput(suggestion.name);
    setSuggestions([]);
  };
  useEffect(() => {
    const handleDocumentClick = (e) => {
      setIsSearching(false);
    };
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);

    getTestseries("");
  }, []);
  // const [latestTestSeries, setLatestTestSeries] = useState([]);
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   getData();
  //   getTestseries("");
  // }, []);

  // function getData() {
  //   ApiCall("", "get", "home_categories", home_categories);
  // }

  // const home_categories = useCallback((response) => {
  //   setMainLoading(false);
  //   if (response.data.status === 200) {
  //     setLatestTestSeries(response.data.latest_sub_category);
  //   } else {
  //     console.log("error");
  //   }
  // });

  const [testSeries, setTestSeries] = useState([]);
  function getTestseries(id) {
    const body = {
      type: "test",
    };
    ApiCall(body, "post", `free_test_ebooks`, test_series);
  }

  const test_series = useCallback((response) => {
    setMainLoading(false);
    if (response.status === 200) {
      setTestSeries(response.data.data);
      // console.log(response.data.data);
    } else {
      console.log("error");
    }
  });

  const handleLatestTestSeriesClick = (id) => {
    getTestseries(id);
  };

  if (mainloading === true) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <img
          src={IMAGE_URL + "website/priloader_icon.svg"}
          style={{ alignSelf: "center" }}
        />{" "}
      </div>
    );
  }
  // ------------------------------------------------------------------------------------------
  return (
    <div>
      <div className="custom_container container py-5">
        <h2 className="fw-medium text-center ff_inter fs_2xl mb-0">
          Precision Prep, Future Success:<br></br>
          <span className="fw-bold text_gradient">
            Elevate Your Journey with
          </span>
        </h2>
        <div className="d-flex justify-content-center">
          <form onSubmit={handleSubmit}>
            <div
              className={`d-flex bg-white mx-auto position-relative mx-lg-0 px-3 py-sm-2 stady_input mt-5 align-items-center ${
                isSearching ? "input-higher-z-index" : ""
              }`}
            >
              <input
                className="border-0 bg-transparent pt-1 w-100 ff_inter fs-6"
                type="text"
                placeholder="Search your Best Courses, Tests & Books..."
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
              <button
                type="submit"
                className="mt-0 d-sm-block d-none bg_gradient text-white border-0 rounded-pill px-3 py-1 px-lg-4 fs_xl"
              >
                Search
              </button>
              <i
                className="fa d-sm-none d-block fa-search fs-4 text_gradient cursor_pointer"
                aria-hidden="true"
              ></i>
            </div>
            <ul className="bg-white ul_box position-relative rounded-4 mt-3">
              {searchInput.length >= 3 && suggestions.length <= 0 ? (
                <>no data found </>
              ) : (
                <>
                  {suggestions.map((suggestion, index) => (
                    <button
                      className="border-0 bg-transparent mt-2 text-start"
                      type="submit"
                      key={index}
                      onClick={() => {
                        if (suggestion.type === "test_series") {
                          navigate(
                            `/test-series/${suggestion.name.replace(
                              /\//g,
                              " "
                            )}/${suggestion.id}`
                          );
                        } else if (suggestion.type === "book") {
                          navigate(
                            `/book-detail/${suggestion.name.replace(
                              /\//g,
                              " "
                            )}/${suggestion.id}`
                          );
                        } else if (suggestion.type === "course") {
                          navigate(
                            `/course-detail/${suggestion.name.replace(
                              /\//g,
                              " "
                            )}/${suggestion.id}`
                          );
                        }
                        window.scrollTo(0, 0);
                      }}
                    >
                      <p className="ff_inter fw-semibold color_dark_blue fs-6l mb-0">
                        {suggestion.name}
                      </p>
                    </button>
                  ))}
                </>
              )}
            </ul>

            <p className="text-danger mb-0 ff_inter ms-4 mt-1">{error}</p>
          </form>
        </div>
        {/* <div className="pt-4">
          <h2 className=" fs_2xl text-center fw-semibold ff_inter">
            Popular Mock Tests
          </h2>
        </div>

        <div className="d-flex flex-wrap align-items-center mt-4">
          {latestTestSeries.map((obj, i) => {
            return (
              <>
                <button
                  key={i}
                  onClick={() => handleLatestTestSeriesClick(obj.id)}
                  className="category_bg mx-1 mt-2"
                >
                  {obj.name}
                </button>
              </>
            );
          })}
        </div> */}
      </div>
      <div className="">
        <div className="">
          <div className="pb-5 mb-5">
            <div className="custom_container container pb-5 mb-5">
              <h2 className=" fs_2xl  mb-0 fw-semibold ff_inter">
                Free Live Mock Tests On Dhurina
              </h2>
            </div>
            <div className="position-relative  mt-5">
              <div className="gradient_after  "></div>
              <div className="custom_container container ">
                <div className="custom_transform">
                  <div className="row ">
                    {testSeries.length >= 3 ? (
                      <>
                        <Slider {...settings}>
                          {testSeries?.map((item, index) => {
                            return (
                              <div key={index} className="col">
                                <div className="mock_box mx-3 p-3 d-flex flex-column justify-content-between">
                                  <div className="mb-0">
                                    <div key={index}>
                                      <h2 className="ff_inter fw-bold text_gradient fs-4 mb-0">
                                        {item.name}
                                      </h2>

                                      <p className="mb-0 text_grey border_brown_bottom pb-3 ff_inter mt-1"></p>
                                    </div>
                                  </div>
                                  <div>
                                    <div>
                                      <div>
                                        <div className="d-flex justify-content-between">
                                          <p className="mb-0 mt-2 ff_inter text_grey fw-semibold">
                                            Questions
                                          </p>
                                          <p className="mb-0 mt-2 ff_inter text_grey fw-semibold">
                                            {item.no_of_questions}
                                          </p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p className="mb-0 ff_inter text_grey fw-semibold">
                                            Max Marks
                                          </p>
                                          <p className="mb-0 ff_inter text_grey fw-semibold">
                                            {item.marks}
                                          </p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p className="mb-0 ff_inter text_grey fw-semibold">
                                            Time
                                          </p>
                                          <p className="mb-0 ff_inter text_grey fw-semibold">
                                            {item.duration} Mins
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="text-center col-12">
                                      {localStorage.getItem("token") ? (
                                        <button
                                          onClick={() => {
                                            SetInstructions(
                                              "Test should be submit on time."
                                            );
                                            showInstructions(
                                              item.id,
                                              item.name,
                                              "Test should be submit on time."
                                            );
                                          }}
                                          className="ff_inter bg_gradient mt-3 px-4 py-1 text-white border-0 fs-6 bg-transparent"
                                        >
                                          Attempt
                                        </button>
                                      ) : (
                                        <button
                                          onClick={() => {
                                            setLoginPopup(true);
                                            document.body.parentElement.classList.toggle(
                                              "overflow-hidden"
                                            );
                                            SetInstructions(
                                              "Test should be submit on time."
                                            );
                                            showInstructions(
                                              item.id,
                                              item.name,
                                              "Test should be submit on time."
                                            );
                                          }}
                                          className="ff_inter bg_gradient mt-3 px-4 py-1 text-white border-0 fs-6 bg-transparent"
                                        >
                                          Attempt
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                      </>
                    ) : testSeries.length >= 1 && testSeries.length <= 2 ? (
                      <>
                        {testSeries?.map((item, index) => {
                          return (
                            <div key={index} className="col">
                              <div className="mock_box mx-3 p-3 d-flex flex-column justify-content-between">
                                <div className="mb-0">
                                  <div key={index}>
                                    <h2 className="ff_inter fw-bold text_gradient fs-4 mb-0">
                                      {item.name}
                                    </h2>

                                    <p className="mb-0 text_grey border_brown_bottom pb-3 ff_inter mt-1"></p>
                                  </div>
                                </div>
                                <div>
                                  <div>
                                    <div>
                                      <div className="d-flex justify-content-between">
                                        <p className="mb-0 mt-2 ff_inter text_grey fw-semibold">
                                          Questions
                                        </p>
                                        <p className="mb-0 mt-2 ff_inter text_grey fw-semibold">
                                          {item.no_of_questions}
                                        </p>
                                      </div>
                                      <div className="d-flex justify-content-between">
                                        <p className="mb-0 ff_inter text_grey fw-semibold">
                                          Max Marks
                                        </p>
                                        <p className="mb-0 ff_inter text_grey fw-semibold">
                                          {item.marks}
                                        </p>
                                      </div>
                                      <div className="d-flex justify-content-between">
                                        <p className="mb-0 ff_inter text_grey fw-semibold">
                                          Time
                                        </p>
                                        <p className="mb-0 ff_inter text_grey fw-semibold">
                                          {item.duration} Mins
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="text-center col-12">
                                    {localStorage.getItem("token") ? (
                                      <button
                                        onClick={() => {
                                          SetInstructions(
                                            "Test should be submit on time."
                                          );
                                          showInstructions(
                                            item.id,
                                            item.name,
                                            "Test should be submit on time."
                                          );
                                        }}
                                        className="ff_inter bg_gradient mt-3 px-4 py-1 text-white border-0 fs-6 bg-transparent d-flex mx-auto justify-content-center align-items-center"
                                      >
                                        Attempt
                                        <img
                                          className="ms-1"
                                          src={
                                            IMAGE_URL +
                                            "website/unlock_icon.png"
                                          }
                                          alt="unlock_test"
                                        />
                                      </button>
                                    ) : (
                                      <button
                                        onClick={() => {
                                          setLoginPopup(true);
                                          document.body.parentElement.classList.toggle(
                                            "overflow-hidden"
                                          );
                                          SetInstructions(
                                            "Test should be submit on time."
                                          );
                                          showInstructions(
                                            item.id,
                                            item.name,
                                            "Test should be submit on time."
                                          );
                                        }}
                                        className="ff_inter bg_gradient mt-3 px-4 py-1 text-white border-0 fs-6 bg-transparent d-flex mx-auto justify-content-center align-items-center"
                                      >
                                        Attempt
                                        <img
                                          className="ms-1"
                                          src={
                                            IMAGE_URL +
                                            "website/unlock_icon.png"
                                          }
                                          alt="unlock_test"
                                        />
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <p className="ff_inter fw-semibold pb-5 text-white fs-5 pt-5 mt-5">
                        No free mock tests available.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {localStorage.getItem("token") ? (
        <Modal
          size="lg"
          id="switchpopup"
          show={instructionmodelStatus}
          onHide={() => SetInstructionModelStatus(false)}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Row className="align-items-center">
              <Col md={12} className="switchlnguageox">
                <h4 className="f-25 mb-0 f-22mob">Instructions</h4>
                {/* <div dangerouslySetInnerHTML={{ __html: "instructions" }} /> */}
                <ol type="1" className="ps-0">
                  <li>
                    1. There is only one correct answer to each question. Click
                    on the most appropriate option to mark it as your answer.
                  </li>
                  <li>
                    2. You can change your answer by clicking on some other
                    option.
                  </li>
                  <li>
                    3. You can move on the next question by clicking on
                    the"Next"button.
                  </li>
                  <li>
                    4. You can access the questions in any order within a
                    section or across sections by clicking on the question
                    number given on the number list.
                  </li>
                  <li>
                    5. You can use rough sheets while taking the test. Do not
                    use calculators, log tables, dictionaries, or any other
                    printed/online reference material during the test.
                  </li>
                  <li>
                    6. Do not click the button "Submit Test" before
                    completing the test.
                  </li>
                </ol>
                <div className="text-center">
                  <Button
                    variant="base-light"
                    className="ff_inter bg_gradient mt-3 px-4 py-1 text-white border-0 fs-6 bg-transparent rounded-4"
                    onClick={() => {
                      navigate("/play-test", { state: testData });
                      window.scrollTo(0, 0);
                    }}
                  >
                    Play Test
                  </Button>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      ) : (
        ""
      )}
    </div>
  );
};

export default Test;
