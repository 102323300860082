import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Image,
  Pagination,
  Modal,
  Button,
} from "react-bootstrap";
import Testseriesheader from "../../include/header/testseriesHeader";
import Footer from "../../include/footer/footer";
import Applink from "../../include/applink/googleApple";
import Skillo from "./../../assets/img/skill1.png";
import Skillt from "./../../assets/img/skill2.png";
import Skillth from "./../../assets/img/skill3.png";
import Skillf from "./../../assets/img/skill4.png";
import MobappImage from "./../../assets/img/mob-app.png";
import MainLoaderImage from "./../../assets/img/loading-buffering.gif";
import ApiCall from "../../api/callApi";
import Loading from "../../components/Loader";
import Item from "./components/item";
import User from "./../../assets/img/user.png";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import OwlCarousel from "react-owl-carousel";

const Image_URL = process.env.REACT_APP_Bucket_URL;
const User_Image_Url = process.env.REACT_APP_Bucket_URL + "users/";
const boardsliderInoptions = {
  loop: true,
  margin: 18,
  nav: true,
  dots: true,
  smartSpeed: 500,
  autoplay: true,
  responsive: {
    0: {
      items: 2,
      margin: 10,
    },
    768: {
      items: 4,
    },
    1100: {
      items: 6,
    },
  },
};

const certificateslideroptions = {
  loop: true,
  margin: 30,
  nav: true,
  dots: true,
  smartSpeed: 1500,
  navText: [
    "<i class='fa fa-arrow-left'></i>",
    "<i class='fa fa-arrow-right'></i>",
  ],
  responsive: {
    0: {
      items: 2,
      dots: true,
      margin: 10,
    },
    575: {
      items: 2,
      margin: 20,
      dots: true,
    },
    769: {
      items: 3,
      dots: true,
    },
    1440: {
      items: 3,
    },
  },
};

const Testserieshome = () => {
  const [loading, setLoading] = useState(true);
  const [length, setLength] = useState(1);
  const [page, setpage] = useState(1);
  const [examDateData, setExamData] = useState([]);
  const [popularData, setPopularData] = useState([]);
  const [topStudents, setTopStudents] = useState([]);
  const [topTeachers, setTopTeachers] = useState([]);
  const [mainloading, setMainLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [showcode, setShowCode] = useState(false);
  const [phone, setPhone] = useState(false);
  const [state, setState] = useState("");
  const [tesrSeriesId, setTestSeriesId] = useState("");
  const [states, setStates] = useState([]);
  useEffect(() => {
    getTestSeries();
    getWinners();
  }, []);

  function getTestSeries() {
    const body = { page: page };
    ApiCall(body, "post", "test_series", test_series);
    setpage(page + 1);
  }

  const test_series = useCallback((response) => {
    if (response.data.status === "true") {
      setExamData((examDateData) => {
        return [...examDateData, ...response.data.test_series.data];
      });

      setMainLoading(false);

      if (response.data.test_series.data.length < 6) {
        setLength(0);
      }

      if (page === 1) {
        setStates(response.data.states);
        setPopularData(response.data.all);
        // console.log(response.data.all);
      }

      setTimeout(() => {
        var offerModelStatus = "false";
        if (localStorage.getItem("testseries_offer_model_time")) {
          var expiretime = new Date(
            localStorage.getItem("testseries_offer_model_time")
          ).getTime();
          var currentTime = new Date().getTime();
          var remainingDayTime = expiretime - currentTime;
          console.log(remainingDayTime);
          if (remainingDayTime < 1) {
            offerModelStatus = "true";
          }
        } else {
          offerModelStatus = "true";
        }
        if (offerModelStatus === "true") {
          setShow(true);
        }
      }, 5000);
    }
  });

  function handleSubmitForm(e) {
    e.preventDefault();
    setLoading(true);
    ApiCall(
      {
        phone: phone,
        state_id: state,
        combo_id: tesrSeriesId,
        type: "test_series",
      },
      "post",
      "generate_lead",
      GetMyOfferCallback
    );
  }

  const notify = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const GetMyOfferCallback = useCallback((response) => {
    setLoading(false);
    console.log(response.data);
    if (response.data.status === 200) {
      setPhone("");
      setShowCode(true);
    } else {
      notify("Something went wrong please try again!!");
    }
  });

  function getWinners() {
    ApiCall("", "get", "our_winners", our_winners);
  }

  const our_winners = useCallback((response) => {
    if (response.data.status === "true") {
      setTopStudents(response.data.users);
      setTopTeachers(response.data.teachers);
      setLoading(false);
    } else {
      console.log("error");
    }
  }, []);

  const TopTeachersData = topTeachers.map((item) => {
    return (
      <div className="item" key={item.id}>
        <Link to="/">
          <div className="thumb">
            <Image
              alt={item.name}
              src={
                item.image !== null && item.image !== "add-member.png"
                  ? `${User_Image_Url}${item.image}`
                  : `${User}`
              }
            />
            <h6 className="mb-3 text-center">{item.name}</h6>
          </div>
        </Link>
      </div>
    );
  });

  const TopStudentsData = topStudents.map((item) => {
    return (
      <div className="item" key={item.id}>
        <Link to="/">
          <div className="single-course-wrap bookscrousel">
            <div className="thumb" style={{ border: 0 }}>
              <Image
                alt={item.name}
                src={
                  item.image !== null && item.image !== "add-member.png"
                    ? `${Image_URL}${item.image}`
                    : `${User}`
                }
              />
              <h6 className="mb-3 text-center">{item.name}</h6>
            </div>
          </div>
        </Link>
      </div>
    );
  });

  return (
    <div>
      {mainloading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <img
            src={MainLoaderImage}
            style={{ alignSelf: "center" }}
            alt="image"
          />
        </div>
      ) : (
        <>
          <Testseriesheader></Testseriesheader>

          <Helmet>
            <title>
              Online Best Test Series Practice For all State and Central Exam
            </title>
            <meta
              name="robots"
              content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
            />
            <link rel="canonical" href="https://dhurina.net/test-series" />
            <meta
              name="title"
              content="Online Best Test Series Practice For all State and Central Exam"
            />
            <meta
              name="description"
              content="The test series in this section have been created by highly experienced teachers with extensive teaching experience."
            />
            <meta
              name="twitter:description"
              content="The test series in this section have been created by highly experienced teachers with extensive teaching experience."
            />
            <meta
              name="twitter:image"
              content="https://dhurina.net/en/wp-content/uploads/2022/05/dhurina-logo.svg"
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
              name="twitter:title"
              content="Online Best Test Series Practice For all State and Central Exam"
            />
            <meta name="twitter:site" content="@Dhurinalive" />
            <meta name="twitter:creator" content="@Dhurinalive" />
            <meta name="twitter:app:id:googleplay" content="com.dhurina" />
            <meta name="twitter:app:id:iphone" content="1479858722" />
            <meta
              name="twitter:app:name:googleplay"
              content="Dhurina - Learning Made Easy"
            />
            <meta
              name="twitter:app:name:ipad"
              content="Dhurina - Learning Made Easy"
            />
            <meta
              name="twitter:app:name:iphone"
              content="Dhurina - Learning Made Easy"
            />
            <meta name="twitter:domain" content="dhurina.net" />
            <meta name="og:image:type" content="image/jpeg" />
            <meta
              property="og:description"
              content="The test series in this section have been created by highly experienced teachers with extensive teaching experience."
            />
            <meta
              property="og:image"
              content="https://dhurina.net/en/wp-content/uploads/2022/05/dhurina-logo.svg"
            />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="article" />
            <meta
              property="og:title"
              content="Online Best Test Series Practice For all State and Central Exam"
            />
            <meta property="og:site_name" content="Dhurina" />
            <meta
              property="article:publisher"
              content="https://www.facebook.com/dhurinalive"
            />
            <meta
              property="article:author"
              content="https://fb.com/dhurinalive"
            />
            <meta
              property="article:section"
              content=">Online Best Test Series Practice For all State and Central Exam"
            />
            <meta
              property="al:android:url"
              content="https://dhurina.net/test-series"
            />
            <meta
              property="al:android:app_name"
              content="Dhurina - Learning Made Easy"
            />
            <meta property="al:android:package" content="com.dhurina" />
            <meta property="fb:app_id" content="facebook-app-id-in-number" />
            <meta property="fb:pages" content="facebook-page-in-number" />
          </Helmet>

          <section className="banner-area  neetmainbanner"></section>

          <section className="test-series py-5">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="section-title">
                    <h2>Most Trending Test Series</h2>
                  </div>
                </Col>
                <Col lg={12}>
                  <Row className="mt-2 testseriessliderbox">
                    {loading ? <Loading /> : <Item data={examDateData} />}
                  </Row>
                  <div className="text-center mt-4">
                    <Pagination className="text-center d-block">
                      {length > 0 && (
                        <button
                          className="btn-load-more mt-3 f-16 shadow-none btn btn-danger"
                          onClick={() => getTestSeries()}
                        >
                          <i className="fa fa-arrow-down"></i>{" "}
                          {loading ? "Loading..." : "Load More"}
                        </button>
                      )}
                    </Pagination>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="trending-courses-area  py-5">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="section-title">
                    <h2>Our Winners</h2>
                  </div>
                </Col>
                <Col lg={12}>
                  <OwlCarousel
                    className="winner-sliders"
                    {...boardsliderInoptions}
                  >
                    {TopStudentsData}
                  </OwlCarousel>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="bg-light py-5">
            <Container>
              <Row>
                <Col md={6}>
                  <div className="section-title mt-4 mt-sm-0 mb-4 lefth2border">
                    <h2 className="text-start">Why Dhurina</h2>
                    <h4 className="text-start mt-4 mobsech4">
                      Top Teachers by Test Contest.
                    </h4>
                  </div>
                  <OwlCarousel
                    className="certificate-sliders"
                    {...certificateslideroptions}
                  >
                    {TopTeachersData}
                  </OwlCarousel>
                </Col>
                <Col md={6}>
                  <div className="px-3 mobserachskill">
                    <Row className="mob-skilliconhead">
                      <Col xs={6}>
                        <Row className="textmon-ce text-center">
                          <Col xs={12} className="mb-3">
                            <Image alt="skill" src={Skillo} />
                          </Col>
                          <Col xs={12}>
                            <h5 className="f-18">Top Rank</h5>
                          </Col>
                        </Row>
                      </Col>

                      <Col xs={6}>
                        <Row className="textmon-ce text-center">
                          <Col xs={12} className="mb-3">
                            <Image alt="skill" src={Skillt} />
                          </Col>
                          <Col xs={12}>
                            <h5 className="f-18">Data Analytics</h5>
                          </Col>
                        </Row>
                      </Col>

                      <Col xs={6}>
                        <Row className="textmon-ce text-center">
                          <Col xs={12} className="mb-3">
                            <Image alt="skill" src={Skillth} />
                          </Col>
                          <Col xs={12}>
                            <h5 className="f-18">Best Score</h5>
                          </Col>
                        </Row>
                      </Col>

                      <Col xs={6}>
                        <Row className="textmon-ce text-center">
                          <Col xs={12} className="mb-3">
                            <Image alt="skill" src={Skillf} />
                          </Col>
                          <Col xs={12}>
                            <h5 className="f-18">Lowest Cost</h5>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="pt-3 map-bg">
            <Container>
              <Row className="justify-content-center align-items-center">
                <Col md={3}>
                  <Image alt="dhurina app image" src={MobappImage} />
                </Col>
                <Col
                  md={5}
                  className="downloadappbtnmob mb-4 schoolinner-mob-app"
                >
                  <div className="section-title mb-4 lefth2border">
                    <h2 className=" text-start mb-3 pb-3">
                      Download Dhurina App
                    </h2>
                    <h5 className=" fw-medium f-22">
                      Get Free Test Series Quiz
                    </h5>
                  </div>
                  <Applink></Applink>
                </Col>
              </Row>
            </Container>
          </section>

          <Footer></Footer>
          <Modal
            show={show}
            onHide={() => {
              setShow(false);
              var today = new Date();
              today.setHours(today.getHours() + 24);
              localStorage.setItem("testseries_offer_model_time", today);
            }}
          >
            <Modal.Header
              className="ms-3 pb-2 ps-0 me-3 border-bottom "
              closeButton
            >
              <Modal.Title
                style={{
                  color: "red",
                  fontWeight: "bold",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                Biggest Giveaway
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className="pt-3 mt-auto ">
                <Row>
                  <span
                    className="text-center"
                    style={{ fontWeight: "bold", fontSize: 30 }}
                  >
                    Register for a Free Mock Test
                  </span>
                </Row>
              </div>
              <div className="contactform p-0 shadow-none ">
                {showcode === false ? (
                  <form onSubmit={(e) => handleSubmitForm(e)}>
                    <div className=" mt-auto" style={{ marginBottom: 20 }}>
                      <Row>
                        <span className="text-center">
                          Get Free Test Series for All Central & State-Level
                          Competitive Exam’s - Upto 100% Off.
                        </span>
                      </Row>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      max="10"
                      placeholder="10 Digit Mobile Number"
                      required
                      onKeyPress={(e) => {
                        if (
                          !/[0-9]/.test(e.key) ||
                          e.target.value.length >= 10
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    <div style={{ marginTop: 15 }}>
                      <select
                        size={"sm"}
                        className="form-control"
                        style={{ backgroundSize: "3%" }}
                        onChange={(e) => setState(e.target.value)}
                        required
                      >
                        <option>Select State</option>
                        {states.map((value) => {
                          return (
                            <option value={value.id} key={value.id}>
                              {value.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div style={{ marginTop: 15 }}>
                      <select
                        size={"sm"}
                        className="form-control"
                        style={{ backgroundSize: "3%" }}
                        onChange={(e) => setTestSeriesId(e.target.value)}
                        required
                      >
                        <option>Test Series</option>
                        {popularData.map((value) => {
                          return (
                            <option value={value.id} key={value.id}>
                              {value.title}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className=" pt-3 mt-auto">
                      <Row>
                        <Col xs={12}>
                          <Button
                            variant="base-light"
                            className="w-100 mt-4 btn-block loginshadowmain"
                            type="submit"
                            id="get_off"
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </div>

                    <div className="pt-3 mt-auto text-center">
                      <Row>
                        <p>Hurry! LIMITED TIME OFFER</p>
                      </Row>
                    </div>
                  </form>
                ) : (
                  <div className="border-top pt-3 mt-auto">
                    <Row>
                      <Col xs={12}>
                        <Button
                          variant="base-light"
                          className="w-100 mt-4 btn-block"
                        >
                          TESTSERIES100
                        </Button>
                      </Col>
                    </Row>
                    <div className="pt-3 mt-auto">
                      <Row>
                        <p>
                          Download Dhurina App. Use this coupon & get upto 100%
                          off on all competitive test series. Coupon Code only
                          applicable in Dhurina App.
                        </p>
                      </Row>
                    </div>
                    <div className="main-banner-txt banner-inner text-md-start text-center mb-0 mb-sm-5">
                      <div className="download-btn ms-0 ms-sm-2 mt-4">
                        <p className="f-15 text-black-new align-items-center d-flex">
                          <span>Download App Now</span>{" "}
                        </p>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.dhurina"
                          target="_blank"
                          rel="noreferrer"
                          className="downloadappbtnmob mt- mb-3 d-inline-block me-3"
                        >
                          <Image
                            alt="Google Play"
                            src={Image_URL + "img/google-play-button.png"}
                          />
                        </a>
                        <a
                          href="https://apps.apple.com/in/app/dhurina/id1479858722"
                          target="_blank"
                          className="downloadappbtnmob"
                          rel="noreferrer"
                        >
                          <Image
                            alt="App Store"
                            src={Image_URL + "img/app-store-button.png"}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </div>
  );
};

export default Testserieshome;
