import React,{useState,useEffect,useCallback} from 'react';
import { Link,useParams } from "react-router-dom";
import { Container, Col, Row, Image } from 'react-bootstrap';  
import ApiCall from './../../../api/callApiCSC';
import MainLoaderImage from './../../../assets/img/loading-buffering.gif'
import Innerheader from '../../../include/header/CSCHeader';

const IMAGE_URL = process.env.REACT_APP_Bucket_URL+"events/";

function LiveClasses () {

    var {token}=useParams();

    const [status, setStatus] = useState(1);
    const [data,setData] = useState([])
    const [mainloading,setMainLoading]=useState(true)

    useEffect(() => {
        getData()
    },[]);

    const getData=async()=>{
        ApiCall("","get","csc_live_classes",courseareacallback,token);
    }

    const courseareacallback = useCallback(response =>{
        setMainLoading(false);
        // console.log(response.data);
        if(response.data.status==='true'){
            setData(response.data.data);
        }
        else{
            setStatus(0);
        }
    })

    if (mainloading===true) {
        return(
            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}><img src={MainLoaderImage} style={{ alignSelf: 'center' }}/></div>
        )
    }

  return (
    <>
        <Innerheader></Innerheader> 
        
        <section className="trending-courses-area py-5">
            <Container>
            {status==0? <h6 className="mt-4 mb-3" style={{margin: '5%'}}>No live class found!!</h6>:

                <Row>
                    <h6 className="mt-4 mb-3" style={{margin: '5%'}}>Live Classes</h6>
                    {data.map((value,key)=>{
                        return (<Col md={3} key={key}>
                            <Link to={"/live-streaming-web/"+token+"/"+value.id}>
                                <div className="single-course-wrap video-bgdemo">
                                    <div className="wrap-details">
                                        <h6 className="mb-1">{value.title}</h6>
                                        <div className="price-wrap pt-0">
                                            <Row className="align-items-center">
                                                <Col xs={6}> 
                                                    <span className="f-11 mt-1 text-muted fw-bold mob-linefacultyheight"><i className="far fa-clock"></i> {value.time}</span>
                                                </Col> 
                                                <Col xs={6} style={{textAlign: 'center'}}>
                                                    {value.live_status==1?<span className="cat cat-green mb-2">Live</span>:<span className="cat cat-blue mb-2">Off</span>}
                                                </Col>
                                            </Row>
                                        </div> 
                                    </div>
                                </div> 
                            </Link>
                        </Col>)
                    })}
                </Row>
            }   
            </Container>
        </section>
        
    </>
  );
}

export default LiveClasses;
