import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import Item from '../../components/item'

const courseSlideroptions = {
    loop:true,
    margin: 30,
    nav:true,
    dots: false,
    smartSpeed:1500,
    autoplay:true,
    navText: ["<i class='fa fa-arrow-left'></i>","<i class='fa fa-arrow-right'></i>"],
    responsive:{
        0:{
            items:1.5, 
            dots: true,
            margin: 10,
        },
        575:{
            items:2,
            margin: 20,
            dots: true,
        },
        769:{
            items:4,
            dots: false,
        }
    }
};

const  allCourse  = (props)=>{

    const { data }=props;
    return(
        <OwlCarousel className="course-slider" {...courseSlideroptions}>
         {data.map((item)=>
         {
              return <Item item={item}  key={item.id}/>
         })}   
           
        </OwlCarousel>
    )
}

export default allCourse;