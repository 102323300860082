import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Tabs, Tab, Container, Col, Row, Image } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Bookheader from "../../include/header/bookHeader";
import SchoolIcon from "./../../assets/img/schoolicon.png";
import StudentIcon from "./../../assets/img/studenticon.png";
import TeacherAmb from "./../../assets/img/teacher.png";
import ApiCall from "../../api/callApi";
import Loading from "../../components/Loader";

const BOOK_IMAGE_URL = process.env.REACT_APP_Bucket_URL + "hardcopy/image/";
const Image_URL = process.env.REACT_APP_Bucket_URL;

const trendingbooksSlideroptions = {
  loop: true,
  center: true,
  margin: 20,
  nav: true,
  dots: true,
  autoplay: true,
  smartSpeed: 700,
  responsive: {
    0: {
      items: 1,
      dots: true,
    },
    575: {
      items: 2,
      margin: 20,
      dots: true,
    },
    769: {
      items: 3,
      dots: true,
    },
  },
};

const homebookSlideroptions = {
  loop: true,
  margin: 30,
  nav: true,
  dots: false,
  smartSpeed: 1500,
  autoplay: true,
  navText: [
    "<i class='fa fa-arrow-left'></i>",
    "<i class='fa fa-arrow-right'></i>",
  ],
  responsive: {
    0: {
      items: 2,
      dots: true,
      margin: 10,
    },
    575: {
      items: 2,
      margin: 20,
      dots: true,
    },
    769: {
      items: 4,
      dots: false,
    },
  },
};

const Bookhome = () => {
  const [mainloading, setMainLoading] = useState(true);
  const [bookItemData, setBookItem] = useState([]);
  const [user_id, setUserId] = useState("");
  const [state, setState] = useState({
    loading: true,
    booksData: [],
    notesData: [],
  });

  useEffect(() => {
    getData();

    if (localStorage.getItem("id") === null) {
    } else {
      setUserId(localStorage.getItem("id"));
    }
  }, []);

  function getData(params) {
    ApiCall("", "get", "books", book_data);
  }

  const book_data = useCallback((response) => {
    if (response.data.status === 200) {
      setBookItem(response.data.books);
      setState({
        ...state,
        notesData: response.data.pdf,
        booksData: response.data.free_book,
        loading: false,
      });
      setMainLoading(false);
    } else {
      console.log("error");
    }
  });

  return (
    <div>
      {mainloading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <img
            src={Image_URL + "website/priloader_icon.svg"}
            style={{ alignSelf: "center" }}
          />{" "}
        </div>
      ) : (
        <>
          <Bookheader></Bookheader>
          <Helmet>
            <title>Best And Top Books And Notes For The Exam Preparation</title>
            <meta
              name="robots"
              content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
            />
            <link rel="canonical" href="https://dhurina.net/books" />
            <meta
              name="title"
              content="Best And Top Books And Notes For The Exam Preparation"
            />
            <meta
              name="description"
              content="Students can use the e-books and notes provided here to prepare for state and central competitive exams."
            />
            <meta name="publish-date" content="2022-04-22T11:24:28+05:30" />
            <meta name="created-date" content="2022-04-22T11:24:28+05:30" />
            <meta name="modified-date" content="2022-04-22T11:24:29+05:30" />
            <meta
              name="twitter:description"
              content="Students can use the e-books and notes provided here to prepare for state and central competitive exams."
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
              name="twitter:title"
              content="Best And Top Books And Notes For The Exam Preparation"
            />
            <meta name="twitter:site" content="@Dhurinalive" />
            <meta name="twitter:creator" content="@Dhurinalive" />
            <meta name="twitter:app:id:googleplay" content="com.dhurina" />
            <meta name="twitter:app:id:iphone" content="1479858722" />
            <meta
              name="twitter:app:name:googleplay"
              content="Dhurina - Learning Made Easy"
            />
            <meta
              name="twitter:app:name:ipad"
              content="Dhurina - Learning Made Easy"
            />
            <meta
              name="twitter:app:name:iphone"
              content="Dhurina - Learning Made Easy"
            />
            <meta name="twitter:domain" content="dhurina.net" />
            <meta name="twitter:google-play-app" content="app-id=com.dhurina" />
            <meta name="og:image:type" content="image/jpeg" />
            <meta
              property="og:description"
              content="Students can use the e-books and notes provided here to prepare for state and central competitive exams."
            />
            <meta property="og:image" content="https://dhurina.net/books" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="article" />
            <meta
              property="og:title"
              content="Best And Top Books And Notes For The Exam Preparation"
            />
            <meta property="og:url" content="https://dhurina.net/books" />
            <meta property="og:site_name" content="Dhurina" />
            <meta
              property="og:updated_time"
              content="2022-01-28T13:36:39+05:30"
            />
            <meta
              property="article:published_time"
              content="2022-04-22T05:54:28.000Z"
            />
            <meta
              property="article:modified_time"
              content="2022-04-22T05:54:29.000Z"
            />
            <meta property="article:section" content="Books" />
            <meta
              property="article:publisher"
              content="https://www.facebook.com/dhurinalive"
            />
            <meta
              property="article:author"
              content="https://fb.com/dhurinalive"
            />
            <meta
              property="al:android:url"
              content="https://dhurina.net/books"
            />
            <meta
              property="al:android:app_name"
              content="Dhurina - Learning Made Easy"
            />
            <meta property="al:android:package" content="com.dhurina" />
            <meta property="fb:app_id" content="facebook-app-id-in-number" />
            <meta property="fb:pages" content="facebook-page-in-number" />
          </Helmet>
          <section className="banner-area booksheadbanner">
            <Container fluid>
              <Row>
                <Col
                  sm={{ span: 3, order: 1, offset: 1 }}
                  xs={{ order: 3 }}
                  className="mt-3 mt-sm-5 mb-4 mb-sm-0"
                >
                  <div className="main-banner-txt banner-inner text-md-start text-center  bookmainbannemobpad">
                    <div className="section-title lefth2border mb-3">
                      <h2 className="text-md-start text-center">
                        Dhurina Publications
                      </h2>
                    </div>
                    <Row className="mt-3 text-start">
                      <Col xs={6} className="mb-3">
                        <Row>
                          <Col xs={4}>
                            <Image alt="img" src={SchoolIcon} />
                          </Col>
                          <Col xs={8} className="ps-0 wrap-details">
                            <h6>
                              <Link to="/">180+</Link>
                            </h6>
                            <p>Books</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={6} className="mb-3">
                        <Row>
                          <Col xs={4}>
                            <Image alt="img" src={StudentIcon} />
                          </Col>
                          <Col xs={8} className="ps-0 wrap-details">
                            <h6>
                              <Link to="/">5+ lakh</Link>
                            </h6>
                            <p>Books Sell</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <Link to="/" className="btn btn-warning mt-3">
                          <i className="fa fa-rupee-sign"></i> Start 100/-
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col sm={{ span: 5, order: 2 }} xs={{ span: 7, order: 1 }}>
                  <h2 className="fw-bold f-22 mb-4 text-center booktrendheadtop">
                    Trending Books & Notes
                  </h2>
                  <OwlCarousel
                    className="trendingbooks"
                    {...trendingbooksSlideroptions}
                  >
                    {bookItemData.map((item, index) => {
                      return (
                        <div className="item" key={item.id}>
                          <Link
                            to={
                              "/book-detail/" +
                              item.book_url +
                              "/" +
                              item.id +
                              "/" +
                              user_id
                            }
                          >
                            <div className="single-course-wrap bookscrousel">
                              <div className="thumb">
                                <Image
                                  alt="img"
                                  src={`${BOOK_IMAGE_URL}${item.image}`}
                                />
                              </div>
                              <div className="wrap-details text-center">
                                <h6>{item.title}</h6>
                                <div className="price-wrap">
                                  <Row className="align-items-end">
                                    <Col xs={12}>
                                      <span className="text-theme f-18 fw-semi-bold">
                                        <i className="fa fa-rupee"></i>
                                        {item.hardcopy_price}
                                      </span>
                                      {/* <del className="text-black-50 f-15 ms-1"><i className="fa fa-rupee"></i>{item.discount}</del> */}
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </OwlCarousel>
                </Col>
                <Col
                  sm={{ span: 3, order: 3 }}
                  xs={{ span: 5, order: 2 }}
                  className="bookteachbannerposition"
                >
                  <div className="bookteacherambas">
                    <Image alt="img" src={TeacherAmb} />
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <div>
            <ToastContainer />
          </div>

          <section className="trending-courses-area py-5">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="section-title">
                    <h2>Top Sellings Books</h2>
                  </div>
                </Col>
                <Col lg={12}>
                  <Tabs
                    defaultActiveKey="notes"
                    id="mostselling-books"
                    className="edl-nav nav-pills new-trending-course"
                  >
                    {/* <Tab eventKey="books" title="Books">
                                        {state.loading ? <Loading /> :
                                            <BooksData data={state.booksData} />
                                        }
                                    </Tab> */}
                    <Tab eventKey="notes">
                      <OwlCarousel
                        className="course-slider"
                        {...homebookSlideroptions}
                      >
                        {state.loading ? (
                          <Loading />
                        ) : (
                          state.booksData.map((item) => {
                            return (
                              <div className="item" key={item.id}>
                                <Link
                                  to={
                                    "/book-detail/" +
                                    item.book_url +
                                    "/" +
                                    item.id +
                                    "/" +
                                    user_id
                                  }
                                >
                                  <div className="single-course-wrap bookscrousel">
                                    <div className="thumb">
                                      <Image
                                        src={`${BOOK_IMAGE_URL}${item.image}`}
                                        alt={item.title}
                                      />
                                    </div>
                                    <div className="wrap-details">
                                      <h6>{item.title}</h6>
                                      <div className="price-wrap">
                                        <Row className="align-items-end">
                                          <Col xs={9} sm={6}>
                                            <span className="text-theme f-18 fw-semi-bold">
                                              <i className="fa fa-rupee"></i>
                                              {item.price}
                                            </span>
                                          </Col>
                                          {/* <Col xs={3} sm={6} className="ps-0 text-sm-end text-start">
                                                                                    <i className="fas fa-cart-plus cartbook"></i>
                                                                                </Col> */}
                                        </Row>
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            );
                          })
                        )}
                      </OwlCarousel>
                    </Tab>
                  </Tabs>
                </Col>
              </Row>
            </Container>
          </section>
        </>
      )}
    </div>
  );
};

export default Bookhome;
