import React,{ useState,useEffect,useCallback} from 'react';
import { Container, Col, Row, Image} from 'react-bootstrap'; 
import VideoJS from './../VideoJsPlayer'
import { Link,useParams} from "react-router-dom";
import ApiCall from './../../../api/callApiCSC';
import MainLoaderImage from './../../../assets/img/loading-buffering.gif'
import Innerheader from '../../../include/header/CSCHeader';
const IMAGE_URL = process.env.REACT_APP_Bucket_URL+"events/";


function Livestream () {

    var {id,token}=useParams();

    const [data,setData] = useState([])
    const [event,setEvent] = useState({title:"Live",url:"",image:""})
    const [mainloading,setMainLoading]=useState(true)
    const [hlsurl, SetHls] = useState('')


    const [expiryTime, setExpiryTime] = useState("");
    const [countdownTime, setCountdownTime] = useState({
        countdownDays: "",
        countdownHours: "",
        countdownlMinutes: "",
        countdownSeconds: "",
    });

    const countdownTimer = () => {
        const timeInterval = setInterval(() => {
            // console.log("ech cth expiry time",expiryTime);
          var countdownDateTime = new Date(expiryTime).getTime();
          var currentTime = new Date().getTime();
          var remainingDayTime = countdownDateTime - currentTime;
          var totalDays = Math.floor(remainingDayTime / (1000 * 60 * 60 * 24));
          var totalHours = Math.floor(
            (remainingDayTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          var totalMinutes = Math.floor(
            (remainingDayTime % (1000 * 60 * 60)) / (1000 * 60)
          );
          var totalSeconds = Math.floor(
            (remainingDayTime % (1000 * 60)) / 1000
          );
      
          const runningCountdownTime = {
            countdownDays: totalDays,
            countdownHours: totalHours,
            countdownMinutes: totalMinutes,
            countdownSeconds: totalSeconds,
          };
      
          setCountdownTime(runningCountdownTime);
      
          if (remainingDayTime <= 0) {
            clearInterval(timeInterval);
            setExpiryTime(false);
          }
        }, 1000);
    };

    useEffect (()=>{
        if(expiryTime!=false){
            countdownTimer();
        }
    });

    useEffect(() => {
        getData()
    },[]);

    const getData=async()=>{
        ApiCall({id:id},"post","csc_live_streaming",courseareacallback,token);
    }

    const courseareacallback = useCallback(response =>{
        console.log(response.data);
        setMainLoading(false);
        setData(response.data.events);
        if(response.data.status==='true'){
            setEvent(response.data.event);
            SetHls(response.data.event.url);
            setExpiryTime(response.data.event.time_from);
        }
    })


    if (hlsurl=="") {
        return(
            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}><img src={MainLoaderImage} style={{ alignSelf: 'center' }}/></div>
        )
    }

  return (
    <>
        <Innerheader></Innerheader> 

        <section className="banner-area booksheadbanner course-detailinner">
            <Container>  
                <Row>

                    <Col xs={12}>
                        <div className="main-banner-txt banner-inner course-detailbanner">
                            <div className="section-title mb-2 mb-sm-3"> 
                                <h5 className="fw-medium f-18">{event.title}</h5>
                            </div> 
                        </div> 
                    </Col>

                    <Col sm={8} xs={12}>
                        <div className="main-banner-txt banner-inner course-detailbanner"> 

                        { expiryTime!=false ?
                                <h4 style={{ color:"red" }}>Class will be started in: {countdownTime.countdownDays}:{countdownTime.countdownHours}:{countdownTime.countdownMinutes}:{countdownTime.countdownSeconds}</h4>
                            :hlsurl!="" ?
                                <VideoJS url={hlsurl} video={event} /> 
                            :<h4>Something went wrong!! Refresh the page and try again.</h4>
                        }
                        </div> 
                    </Col>

                    <Col sm={4} xs={12}> 
                        <div className="recomdednd-section">
                            <p>Recommended Classes</p>
                            
                            {data.length<1? <h6 className="mt-4 mb-3" style={{margin: '5%'}}>No more live classes found!!</h6>:
                                data.map((value,key)=>{
                                    return (
                                        <Link to={"/live-streaming/"+token+"/"+value.id} key={key}>
                                            <Row>
                                                <Col xs={4} className="pe-0" >
                                                    <div className="single-course-wrap video-bgdemo recommendclass">
                                                        <div className="thumb"> 
                                                            <Image src={`${IMAGE_URL}${value.image}`} alt="img" />
                                                        </div> 
                                                    </div> 
                                                </Col>
                                                <Col xs={8} className="ps-0">
                                                    <div className="single-course-wrap video-bgdemo recommendclass">
                                                        <div className="wrap-details">
                                                            <h6 className="mb-1">{value.title}</h6>
                                                            <div className="price-wrap pt-0">
                                                                <Row className="align-items-center">
                                                                    <Col xs={12}> 
                                                                        <span className="f-11 mt-1 text-muted fw-bold mob-linefacultyheight"><i class="far fa-clock"></i> {value.time}</span>
                                                                    </Col> 
                                                                </Row>
                                                            </div> 
                                                        </div>
                                                    </div> 
                                                </Col>
                                            </Row>
                                        </Link>
                                    )
                                })
                            }
                        </div>
                    </Col>
                </Row>                    
            </Container>
        </section>
        
    </>
  );
}

export default Livestream;
