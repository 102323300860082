import React, { useState } from "react";
const Image_URL = process.env.REACT_APP_Bucket_URL;

const InstitutesData = ({ instituteData, onInstituteClick }) => {
  const [selectedInstitute, setSelectedInstitute] = useState("");

  const handleRadioChange = (event) => {
    setSelectedInstitute(event.target.value);
  };
  return (
    <div className="bg_register px-3 py-4">
      <h4 className=" fw-semibold text_gradient ff_inter  fs-4">
        Our Institutes
      </h4>
      {instituteData.map((institute, index) => (
        <div key={index}>
          <div
            onClick={() => onInstituteClick(index)}
            className="d-flex  gap-2 mt-3"
          >
            <input
              className="cursor_pointer mt-2"
              type="radio"
              name=""
              value="Gurukul"
            />
            <label
              className="ff_inter cursor_pointer text_dark_grey custom_fs "
              htmlFor="Gurukul"
            >
              {institute.heading}
            </label>
          </div>
        </div>
      ))}
      {/* <div className="d-flex align-items-center gap-2 mt-3">
        <input
          type="radio"
          id="Gurukul"
          name="institute"
          value="Gurukul"
          checked={selectedInstitute === "Gurukul"}
          onChange={handleRadioChange}
        />
        <label className="ff_inter text_dark_grey custom_fs " htmlFor="Gurukul">
          Gurukul Coaching Centre - Taranagar
        </label>
      </div>
      <div className="d-flex align-items-center gap-2 mt-3">
        <input
          type="radio"
          id="Aryavart"
          name="institute"
          value="Aryavart"
          checked={selectedInstitute === "Aryavart"}
          onChange={handleRadioChange}
        />
        <label
          className="ff_inter text_dark_grey custom_fs "
          htmlFor="Aryavart"
        >
          Aryavart Academy - Jaipur
        </label>
      </div>
      <div className="d-flex align-items-center gap-2 mt-3">
        <input
          type="radio"
          id="Academy"
          name="institute"
          value="Academy"
          checked={selectedInstitute === "Academy"}
          onChange={handleRadioChange}
        />
        <label className="ff_inter text_dark_grey custom_fs " htmlFor="Academy">
          Gurukul Academy - Jharkhand
        </label>
      </div>
      <div className="d-flex align-items-center gap-2 mt-3">
        <input
          type="radio"
          id="Coaching"
          name="institute"
          value="Coaching"
          checked={selectedInstitute === "Coaching"}
          onChange={handleRadioChange}
        />
        <label
          className="ff_inter text_dark_grey custom_fs "
          htmlFor="Coaching"
        >
          SGM Gurukul Coaching Centre - Fatehabad
        </label>
      </div>
      <div className="d-flex align-items-center gap-2 mt-3">
        <input
          type="radio"
          id="Hisar"
          name="institute"
          value="Hisar"
          checked={selectedInstitute === "Hisar"}
          onChange={handleRadioChange}
        />
        <label className="ff_inter text_dark_grey custom_fs " htmlFor="Hisar">
          Kodu - Hisar
        </label>
      </div>
      <div className="d-flex align-items-center gap-2 mt-3">
        <input
          type="radio"
          id="sirsa"
          name="institute"
          value="sirsa"
          checked={selectedInstitute === "sirsa"}
          onChange={handleRadioChange}
        />
        <label className="ff_inter text_dark_grey custom_fs " htmlFor="sirsa">
          Kodu - Sirsa
        </label>
      </div>
      <div className="d-flex align-items-center gap-2 mt-3">
        <input
          type="radio"
          id="Rohtak"
          name="institute"
          value="Rohtak"
          checked={selectedInstitute === "Rohtak"}
          onChange={handleRadioChange}
        />
        <label className="ff_inter text_dark_grey custom_fs " htmlFor="Rohtak">
          Kodu - Rohtak
        </label>
      </div> */}
      <div className=" mt-3 col-md-6 col-lg-12">
        <img
          className=""
          src={Image_URL + "website/Poster1.png"}
          alt="bpsc_crack"
        />
      </div>
    </div>
  );
};

export default InstitutesData;
