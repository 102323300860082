import React from "react";
import Hero from "./Hero";
import FreeMock from "./FreeMock";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import ApiCall from "../../api/callApi";
import { useCallback } from "react";
import { initialize, makePayment } from "../../components/Payment";
import { toast } from "react-toastify";
const Image_URL = process.env.REACT_APP_Bucket_URL;

const TestSeries = ({ setLoginPopup }) => {
  const notify = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const [description, setDescription] = useState("");
  const [seoDescription, setSeoDescription] = useState("");
  const [isViewPolicy, setIsViewPolicy] = useState(true);
  const [phone, setPhone] = useState(false);
  const [show, setShow] = useState(false);
  var coupon_id = "";
  var paymentData = {
    token: "",
    order: "",
    mid: "",
    amount: "",
  };

  const [loading, setLoading] = useState(false);
  const [mainloading, setMainLoading] = useState(true);
  const [courseDetail, setCourseDetail] = useState("");
  const [course_tags, setTags] = useState([]);
  const [offerList, setOfferList] = useState([]);
  const [offerItem, setOfferItem] = useState("");
  const [teacher, setTeacher] = useState([]);
  const [radioButtonValue, setRadioButtonValue] = useState("");
  const [purchase_status, setPurchaseStatus] = useState(0);
  const [showcode, setShowCode] = useState(false);

  function webhook(final_price) {
    initialize(
      initializeCallback,
      final_price,
      localStorage.getItem("mobile"),
      localStorage.getItem("id")
    );
  }
  var CourseUrl = window.location.href;

  const checkphonecallback = useCallback((response) => {
    if (response.data.status === 200) {
      setLoading(true);
      makePayment(paymentData, myCallback);
      setMainLoading(false);
    } else {
      notify(response.data.message);
    }
  });

  var { stateName, id } = useParams();
  const navigate = useNavigate();
  let url = "test-series/" + stateName + "/" + id;

  useEffect(() => {
    paymentData = {
      token: "",
      order: "",
      mid: "",
      amount: "",
    };
    getData();
    window.scrollTo(0, 0);
    setShow(true);
  }, []);

  //----------Course Detail Data------------//
  function getData() {
    ApiCall("", "get", url, courseCallback);
  }

  const courseCallback = useCallback((response) => {
    // console.log(response.data);

    if (response.data.status === 200 || response.data.status === 301) {
      if (response.data.status === 301) {
        navigate("/test-series/" + response.data.new_url + "/" + id);
      }

      let course = response.data.course;

      if (response.data.offers.length > 0) {
        var myObj = offers(response.data.offers, response.data.course.discount);
        course = _calculateCourse1(course, myObj.selectedoffer);
      } else {
        course.final_price = response.data.course.price;
      }
      setCourseDetail(course);
      setTags(response.data.subcatname);
      setDescription(response.data.course.web_description);
      if (
        response.data.course.sub_heading != "" ||
        response.data.course.sub_heading != null ||
        response.data.course.sub_heading != "null"
      ) {
        setSeoDescription(response.data.course.sub_heading);
      } else {
        setSeoDescription(response.data.course.description);
      }
      setPurchaseStatus(response.data.purchase_status);
      setMainLoading(false);

      setTimeout(() => {
        var offerModelStatus = "false";
        if (
          localStorage.getItem("offer_model_time") != "" &&
          localStorage.getItem("offer_model_time") != null &&
          localStorage.getItem("offer_model_time") != "null"
        ) {
          var expiretime = new Date(
            localStorage.getItem("offer_model_time")
          ).getTime();
          var currentTime = new Date().getTime();
          var remainingDayTime = expiretime - currentTime;
          if (remainingDayTime < 1) {
            offerModelStatus = "true";
          }
        } else {
          offerModelStatus = "true";
        }

        if (offerModelStatus == "true" && response.data.orders_count < 1) {
          setShow(true);
        }
      }, 5000);

      // set datalayer google tags
      window.dataLayer.push({
        event: "page_view",
        send_to: "AW-11012643170",
        value: response.data.course.price,
        items: [
          {
            id: response.data.course.id,
            // 'location_id': 'replace with value',
            google_business_vertical: "education",
          },
        ],
      });
    } else if (response.data.status === 404) {
      navigate("/page-not-found");
    } else {
      console.log("error");
    }
  });
  //-------------------Offer price calculation -----------------//
  const offers = (offerlist, originalprice) => {
    var updated_offer_obj = {};
    offerlist.map((item, index) => {
      item.selected = false;
      if (item.type === "fix_price") {
        var final_price = originalprice - item.fix_price;
      } else {
        let percentage_price = (originalprice * item.percentage) / 100;
        var final_price = originalprice - percentage_price;
      }
      item.final_price = Math.round(final_price);
    });

    offerlist.sort((a, b) => a.final_price - b.final_price);
    setOfferList(offerlist);
    setOfferItem(offerlist[0]);
    var selectedoffer = offerlist[0];
    offerlist[0].selected = true;
    coupon_id = selectedoffer.id;
    updated_offer_obj.offerlist = offerlist;
    updated_offer_obj.selectedoffer = selectedoffer;
    return updated_offer_obj;
  };
  //--------------------End offer price calculation-----------------//
  //--------------------Calculate final price-----------------------//
  const _calculateCourse1 = (courseItem, offerObj) => {
    let course = { ...courseItem };
    let discount = 0,
      final_price = 0;
    let original_price = courseItem.price;
    let offer_discount = 0;
    let selectedOffer = offerObj === null ? null : offerObj;

    if (selectedOffer !== null) {
      original_price = courseItem.discount;
      if (selectedOffer.type === "fix_price") {
        offer_discount = original_price - selectedOffer.fix_price;
        offer_discount = Math.round(offer_discount);
        final_price = selectedOffer.fix_price;
      } else {
        let percentage = selectedOffer.percentage;
        offer_discount = (original_price * percentage) / 100;
        offer_discount = Math.round(offer_discount);
        final_price = original_price - offer_discount;
      }
      setOfferItem(selectedOffer);
    } else {
      course.offer_id = null;
      final_price = courseItem.price; //original_price
    }

    course.final_price = final_price;
    return course;
  };

  //--------------------End final price calculation-------------//
  //--------------------Payment initialize callback----------------//
  const initializeCallback = useCallback((response) => {
    let obj = {
      token: response.token,
      order: response.order,
      mid: response.mid,
      amount: response.amount,
    };

    paymentData = obj;
    if (localStorage.getItem("token") === null) {
      setLoginPopup(true);
      document.body.parentElement.classList.toggle("overflow-hidden");
    } else {
      var data = {
        phone: localStorage.getItem("mobile"),
        course_id: id,
        type: "combo",
        order_id: obj.order,
        offer_id: offerItem.id,
      };
      ApiCall(data, "post", "webhook", checkphonecallback);
    }
  });
  //---------------End callback ----------------------//
  //---------------Update payment--------------------//
  const updatepayment = (response) => {
    var data = {
      status: response.STATUS,
      price: response.TXNAMOUNT,
      order_id: paymentData.order,
    };
    ApiCall(data, "post", "update_payment", updatepaymentcallback);
  };

  const updatepaymentcallback = useCallback((response) => {
    if (response.data.status === 200) {
      var state = {
        txnid: response.data.course_data.txnid,
        amount: response.data.course_data.amount,
        course_name: response.data.course_name,
        transaction_status: response.data.transaction_status,
      };
      navigate("/payment-verification", { state });
    }
    if (response.data.status === 500) {
      var state = {
        txnid: "",
        amount: "",
        course_name: "",
        transaction_status: 0,
      };
      navigate("/payment-verification", { state });
    }
  });
  //-----------------End update payment---------------//

  const myCallback = useCallback((response, status) => {
    if (status) {
      if (response.STATUS === "TXN_SUCCESS") {
        updatepayment(response);
      } else {
        updatepayment(response);
      }
    } else {
      setLoading(false);
    }
  });
  const setRadioButton = (e, item, courseDetail, id) => {
    if (radioButtonValue === e.target.value) {
      setRadioButtonValue("");
      offerList.map((item) => {
        if (item.id === id) {
          item.selected = false;
        } else {
          item.selected = false;
        }
      });
      setOfferList(offerList);
      courseDetail.final_price = courseDetail.price;
      setCourseDetail(courseDetail);
    } else {
      setRadioButtonValue(e.target.value);
      offerList.map((item) => {
        if (item.id === id) {
          item.selected = true;
        } else {
          item.selected = false;
        }
      });
      let course1 = courseDetail;
      course1 = _calculateCourse1(courseDetail, item);
      setCourseDetail(course1);
      coupon_id = id;
    }
  };

  const [testSerie, setTestSerie] = useState("");
  useEffect(() => {
    const body = {
      test_series_id: id,
    };
    ApiCall(body, "post", "test_series_details", test_series_details);
  }, [id]);
  const test_series_details = useCallback((response) => {
    setMainLoading(false);
    if (response.data.status === 200) {
      setTestSerie(response.data.combos);
      // console.log(response.data.combos);
    } else {
      console.log("error");
    }
  });
  if (mainloading === true) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <img
          src={Image_URL + "website/priloader_icon.svg"}
          style={{ alignSelf: "center" }}
        />{" "}
      </div>
    );
  }

  return (
    <>
      <Hero
        webhook={webhook}
        testSerie={testSerie}
        setMainLoading={setMainLoading}
      />
      <FreeMock
        testSerie={testSerie}
        setLoginPopup={setLoginPopup}
        webhook={webhook}
        setMainLoading={setMainLoading}
      />
    </>
  );
};

export default TestSeries;
