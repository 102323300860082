/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { Container, Col, Row, Breadcrumb, Accordion } from "react-bootstrap";
import { Parser } from "html-to-react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ApiCall from "../../api/callApi";
import MainLoaderImage from "./../../assets/img/loading-buffering.gif";
const MediaURL = process.env.REACT_APP_Bucket_URL + "test/";
const URL = process.env.REACT_APP_API_URL_Live;
const Image_URL = process.env.REACT_APP_Bucket_URL;

function RasCourses() {
  const [status, setStatus] = useState(1);
  const [data, setData] = useState([]);
  const [mainloading, setMainLoading] = useState(true);
  const [current_test_id, setCurrentTestId] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (
      localStorage.getItem("token") === "" ||
      localStorage.getItem("token") === "null" ||
      localStorage.getItem("token") === undefined ||
      localStorage.getItem("token") === null
    ) {
      navigate("/");
    } else {
      getData();
    }
  }, []);

  function getData() {
    ApiCall("", "get", "ras_courses", rascoursecallback);
  }

  const rascoursecallback = useCallback((response) => {
    console.log(response.data);
    setMainLoading(false);
    if (response.data.status == "success") {
      setData(response.data.data);
    } else {
      alert(response.data.message);
    }
  });

  // Send download pdf data
  function postData(test_id) {
    ApiCall(
      { test_id: test_id },
      "post",
      "subTestDownloadTime",
      donwloadCallback
    );
  }

  const donwloadCallback = useCallback((response) => {
    if (response.data.status === 200) {
      alert(
        "Your test time has been started from now. You need to upload your answer sheet in  PDF format here before 3 hours."
      );
    } else {
      console.log("error");
    }
  });

  // upload
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("test_id", current_test_id);

    try {
      const USER_TOKEN = localStorage.getItem("token");
      const AuthStr = "Bearer ".concat(USER_TOKEN);

      const response = await axios.post(
        URL + "upload_subjective_test",
        formData,
        {
          headers: { Authorization: AuthStr },
        }
      );

      alert(response.data.message);

      event.target.value = null;
    } catch (error) {
      alert("Error uploading file:", error);
    }
  };

  // const handleTime = () => {
  //   const downloadTimestamp = new Date().toLocaleString();
  //   console.log("File downloaded at:", downloadTimestamp);
  // };

  if (mainloading === true) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <img
          src={Image_URL + "website/priloader_icon.svg"}
          style={{ alignSelf: "center" }}
        />{" "}
      </div>
    );
  }

  return (
    <>
      <section className="pt-5">
        <div className="container custom_container">
          <Row>
            <Col lg={6} className="align-self-center">
              <Breadcrumb className="mb-1">
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item className="text_gradient" active>
                  RAS Courses
                </Breadcrumb.Item>
              </Breadcrumb>
              <Row>
                <Col md={12}>
                  <h3 className="mb-0 f-25mob">RAS Courses</h3>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </section>

      <section className="trending-courses-area py-5">
        <div className="container custom_container">
          <Row>
            <Col lg={12} className="sidebar-area">
              <div className="widget-accordion-inner">
                {status == 0 ? (
                  <h6 className="mt-4 mb-3" style={{ margin: "5%" }}>
                    No RAS course found!. Buy RAS courses from Dhurina
                    App/Website to watch live courses here......
                  </h6>
                ) : (
                  <Accordion defaultActiveKey="0">
                    {data.map((value, key) => {
                      return (
                        <Accordion.Item
                          className="mt-3"
                          eventKey={key.toString()}
                          key={key}
                          expanded={true}
                        >
                          <Accordion.Header>
                            <i className="fa fa-book me-2"></i> {value.name}
                          </Accordion.Header>
                          <Accordion.Body className="widget-select-inner">
                            <p>{Parser().parse(value.description)}</p>

                            <div className="border-bottom my-4"></div>
                            <h6 className="mt-4 mb-3">Subjective Test</h6>
                            {value.tests.map((test, index) => {
                              return (
                                <>
                                  <p key={index} className="mb-0">
                                    <i className="fa fa-download me-2"></i>
                                    <a
                                      href={`${MediaURL}${test.file}`}
                                      target="_blank"
                                      rel="noreferrer"
                                      onClick={() => postData(test.id)}
                                    >
                                      {test.title}
                                    </a>
                                  </p>

                                  <div className="d-flex align-items-center mt-5">
                                    <h6 className="mb-0 me-2">
                                      Upload Answer Sheet
                                    </h6>
                                    <input
                                      id="uploadInput"
                                      type="file"
                                      onChange={handleFileUpload}
                                      onClick={() => setCurrentTestId(test.id)}
                                    />
                                  </div>
                                  {test.download_time != "" &&
                                  test.download_time != null ? (
                                    <p>
                                      Answer key should be uploaded before:{" "}
                                      {test.download_time}
                                    </p>
                                  ) : null}
                                  <div className="border-bottom my-4"></div>
                                </>
                              );
                            })}

                            <div className="border-bottom my-4"></div>
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })}
                  </Accordion>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}

export default RasCourses;
