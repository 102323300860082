import React, { useState } from "react";
import { TestHero } from "../../include/helper/Helper";
import { useParams } from "react-router";
import TestSeries from "./TestSeries";

const Hero = ({ webhook, testSerie, setMainLoading }) => {
  // console.log(testSerie);
  const { id, stateName } = useParams();
  return (
    <div className="bg_hero_ssc mt-5">
      <div className="custom_container container py-5">
        <span className="ff_inter fw-semibold text_gradient mb-0">
          {stateName}
        </span>
        <div className="row mt-4 justify-content-between">
          <div className="col-lg-6">
            <h2 className="ff_inter fw-bold fs-3">{stateName}</h2>
            <ul className="ul_ticks">
              {TestHero.map((item, index) => {
                return (
                  <li
                    key={index}
                    className="ff_inter fw-semibold text-capitalize text_dark_grey mt-2"
                  >
                    {item.detail}
                  </li>
                );
              })}
            </ul>
          </div>

          {testSerie && testSerie.price>0 ? (
            <>
              <div className="col-lg-6 d-flex flex-column align-items-end">
                <div className="d-flex align-items-end">
                  <h4 className="mb-0 text_gradient fw-bold fs-3 mb-0">
                    ₹{testSerie.price}
                  </h4>
                  {testSerie.discount === 0 ? (
                    ""
                  ) : (
                    <>
                      <span className="fs_desc ms-1 text_grey ff_inter  text-decoration-line-through mb-0">
                        {testSerie.discount}
                      </span>
                    </>
                  )}
                </div>
                {testSerie && testSerie.purchase_status === "false" ? (
                  <>
                    <button
                      onClick={() => {
                        setMainLoading(true);
                        webhook(testSerie.price);
                      }}
                      className="bg_gradient ff_inter fw-semibold text-white fs-5 border-0 py-2 px-5 mt-2"
                    >
                      Buy Now
                    </button>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Hero;
