/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { Parser } from "html-to-react";
import { Col, Modal, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import ApiCall from "../../api/callApi";
import { initialize, makePayment } from "../../components/Payment";
import { Carousel } from "react-bootstrap";
import Poster from "../../assets/img/bpsc-crack-img.png";
const COURSE_IMAGE_URL = process.env.REACT_APP_Bucket_URL + "course/";
const OFFERS_IMAGE_URL = process.env.REACT_APP_Bucket_URL + "offers/";
const Image_URL = process.env.REACT_APP_Bucket_URL;

var page = 1;
const SingleCourse = ({ setLoginPopup }) => {
  const notify = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  // const [validity, setValidity] = useState();

  // const validityPeriods = [{ time: 12 }, { time: 6 }];

  const [description, setDescription] = useState("");
  const [seoDescription, setSeoDescription] = useState("");
  const [isViewPolicy, setIsViewPolicy] = useState(true);
  const [phone, setPhone] = useState(false);
  const [show, setShow] = useState(false);
  const togglePolicy = () => {
    setIsViewPolicy(!isViewPolicy);
  };
  var coupon_id = "";
  var paymentData = {
    token: "",
    order: "",
    mid: "",
    amount: "",
  };
  const [courseOffer, setCourseOffer] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mainloading, setMainLoading] = useState(true);
  const [courseDetail, setCourseDetail] = useState("");
  const [course_tags, setTags] = useState([]);
  const [offerList, setOfferList] = useState([]);
  const [offerItem, setOfferItem] = useState("");
  const [teacher, setTeacher] = useState([]);
  const [radioButtonValue, setRadioButtonValue] = useState("");
  const [purchase_status, setPurchaseStatus] = useState(0);
  const [showcode, setShowCode] = useState(false);
  const [targetDateTime, setTargetDateTime] = useState();
  const offerEndTime = new Date(`${courseOffer[0]?.valid_till}`);
  const [offerSectionVisible, setOfferSectionVisible] = useState(0);
  const [price, setPrice] = useState("");
  const [percentage, setPercentage] = useState("");
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  function webhook(final_price) {
    initialize(
      initializeCallback,
      final_price,
      localStorage.getItem("mobile"),
      localStorage.getItem("id")
    );
  }
  var CourseUrl = window.location.href;

  const checkphonecallback = useCallback((response) => {
    if (response.data.status === 200) {
      setLoading(true);
      makePayment(paymentData, myCallback);
    } else {
      notify(response.data.message);
    }
  });

  var { course_url, courseId } = useParams();
  const navigate = useNavigate();
  let url = "course_detail/" + course_url + "/" + courseId;

  useEffect(() => {
    paymentData = {
      token: "",
      order: "",
      mid: "",
      amount: "",
    };
    getData();
    window.scrollTo(0, 0);
    setShow(true);
  }, []);

  //----------Course Detail Data------------//
  function getData() {
    const body = {
      live_classes_count: "",
      recorded_classes_count: "",
      test_count: "",
    };
    ApiCall(body, "get", url, courseCallback);
  }

  const courseCallback = useCallback((response) => {
    if (response.data.status === 200 || response.data.status === 301) {
      if (response.data.status === 301) {
        navigate("/course-detail/" + response.data.new_url + "/" + courseId);
      }

      setTeacher(JSON.parse(response.data.course.faculity));
      let course = response.data.course;

      if (response.data.offers.length > 0) {
        var myObj = offers(response.data.offers, response.data.course.discount);
        course = _calculateCourse1(course, myObj.selectedoffer);
      } else {
        course.final_price = response.data.course.price;
      }
      setCourseDetail(course);

      setCourseOffer(response.data.offers);
      // console.log(response.data.offers);
      // console.log(course);

      setTags(response.data.subcatname);
      setDescription(response.data.course.web_description);
      if (
        response.data.course.sub_heading != "" ||
        response.data.course.sub_heading != null ||
        response.data.course.sub_heading != "null"
      ) {
        setSeoDescription(response.data.course.sub_heading);
      } else {
        setSeoDescription(response.data.course.description);
      }
      setPurchaseStatus(response.data.purchase_status);
      setMainLoading(false);

      setTimeout(() => {
        var offerModelStatus = "false";
        if (
          localStorage.getItem("offer_model_time") != "" &&
          localStorage.getItem("offer_model_time") != null &&
          localStorage.getItem("offer_model_time") != "null"
        ) {
          var expiretime = new Date(
            localStorage.getItem("offer_model_time")
          ).getTime();
          var currentTime = new Date().getTime();
          var remainingDayTime = expiretime - currentTime;
          if (remainingDayTime < 1) {
            offerModelStatus = "true";
          }
        } else {
          offerModelStatus = "true";
        }

        if (offerModelStatus == "true" && response.data.orders_count < 1) {
          setShow(true);
        }
      }, 5000);

      // set datalayer google tags
      window.dataLayer.push({
        event: "page_view",
        send_to: "AW-11012643170",
        value: response.data.course.price,
        items: [
          {
            id: response.data.course.id,
            // 'location_id': 'replace with value',
            google_business_vertical: "education",
          },
        ],
      });
    } else if (response.data.status === 404) {
      navigate("/page-not-found");
    } else {
      console.log("error");
    }
  });
  //-------------------Offer price calculation -----------------//
  const offers = (offerlist, originalprice) => {
    var updated_offer_obj = {};
    offerlist.map((item, index) => {
      item.selected = false;
      if (item.type === "fix_price") {
        var final_price = originalprice - item.fix_price;
      } else {
        let percentage_price = (originalprice * item.percentage) / 100;
        var final_price = originalprice - percentage_price;
      }
      item.final_price = Math.round(final_price);
    });

    offerlist.sort((a, b) => a.final_price - b.final_price);
    setOfferList(offerlist);
    setOfferItem(offerlist[0]);
    var selectedoffer = offerlist[0];
    offerlist[0].selected = true;
    coupon_id = selectedoffer.id;
    updated_offer_obj.offerlist = offerlist;
    updated_offer_obj.selectedoffer = selectedoffer;
    return updated_offer_obj;
  };
  //--------------------End offer price calculation-----------------//
  //--------------------Calculate final price-----------------------//
  const _calculateCourse1 = (courseItem, offerObj) => {
    let course = { ...courseItem };
    let discount = 0,
      final_price = 0;
    let original_price = courseItem.price;
    let offer_discount = 0;
    let selectedOffer = offerObj === null ? null : offerObj;

    if (selectedOffer !== null) {
      original_price = courseItem.discount;
      if (selectedOffer.type === "fix_price") {
        offer_discount = original_price - selectedOffer.fix_price;
        offer_discount = Math.round(offer_discount);
        final_price = selectedOffer.fix_price;
      } else {
        let percentage = selectedOffer.percentage;
        offer_discount = (original_price * percentage) / 100;
        offer_discount = Math.round(offer_discount);
        final_price = original_price - offer_discount;
      }
      setOfferItem(selectedOffer);
    } else {
      course.offer_id = null;
      final_price = courseItem.price; //original_price
    }

    course.final_price = final_price;
    return course;
  };

  //--------------------End final price calculation-------------//
  //--------------------Payment initialize callback----------------//
  const initializeCallback = useCallback((response) => {
    let obj = {
      token: response.token,
      order: response.order,
      mid: response.mid,
      amount: response.amount,
    };

    paymentData = obj;
    if (localStorage.getItem("token") === null) {
      navigate("/login");
    } else {
      var data = {
        phone: localStorage.getItem("mobile"),
        course_id: courseId,
        type: "course",
        order_id: obj.order,
        offer_id: offerItem.id,
      };
      ApiCall(data, "post", "webhook", checkphonecallback);
    }
  });
  //---------------End callback ----------------------//
  //---------------Update payment--------------------//
  const updatepayment = (response) => {
    var data = {
      status: response.STATUS,
      price: response.TXNAMOUNT,
      order_id: paymentData.order,
    };
    ApiCall(data, "post", "update_payment", updatepaymentcallback);
  };

  const updatepaymentcallback = useCallback((response) => {
    if (response.data.status === 200) {
      // console.log(response);
      var state = {
        txnid: response.data.course_data.txnid,
        amount: response.data.course_data.amount,
        course_name: response.data.course_name,
        transaction_status: response.data.transaction_status,
      };
      navigate("/payment-verification", { state });
    }
    if (response.data.status === 500) {
      var state = {
        txnid: "",
        amount: "",
        course_name: "",
        transaction_status: 0,
      };
      navigate("/payment-verification", { state });
    }
  });
  //-----------------End update payment---------------//

  const myCallback = useCallback((response, status) => {
    if (status) {
      if (response.STATUS === "TXN_SUCCESS") {
        updatepayment(response);
      } else {
        updatepayment(response);
      }
    } else {
      setLoading(false);
    }
  });
  const setRadioButton = (e, item, courseDetail, courseId) => {
    if (radioButtonValue === e.target.value) {
      setRadioButtonValue("");
      offerList.map((item) => {
        if (item.id === courseId) {
          item.selected = false;
        } else {
          item.selected = false;
        }
      });
      setOfferList(offerList);
      courseDetail.final_price = courseDetail.price;
      setCourseDetail(courseDetail);
    } else {
      setRadioButtonValue(e.target.value);
      offerList.map((item) => {
        if (item.id === courseId) {
          item.selected = true;
        } else {
          item.selected = false;
        }
      });
      let course1 = courseDetail;
      course1 = _calculateCourse1(courseDetail, item);
      setCourseDetail(course1);
      coupon_id = courseId;
    }
  };
  const [popupupdocShow, setuploaddocPopup] = useState(false);
  const updocpopClose = () => setuploaddocPopup(false);
  const updocpopShow = () => setuploaddocPopup(true);

  function handleSubmitForm(e) {
    e.preventDefault();
    ApiCall(
      { phone: phone, course_id: courseId },
      "post",
      "generate_lead",
      GetMyOfferCallback
    );
  }
  const GetMyOfferCallback = useCallback((response) => {
    if (response.data.status === 200) {
      setPhone("");
      if (response.data.purchase_status == 0) {
        setShowCode(true);
      } else {
        notify("Offer only valid for first time purchase!!");
        setShow(false);
      }
    } else {
      notify("Something went wrong please try again!!");
    }
  });

  const htmlParser = new Parser();
  const htmlString = `<p className="ff_inter fw-normal text_grey fs_xxl pe-lg-5">${courseDetail.web_description}</p>`;
  const parsedDesc = htmlParser.parse(htmlString);

  const [allCourse, setAllCourse] = useState([]);
  const [couponCode, setCouponCode] = useState("");
  const [coupon, setCoupon] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  function applyCouponCode(e) {
    e.preventDefault();
    if (coupon) {
      const body = {
        coupen_code: coupon,
        type_id: courseId,
        type: "course",
      };
      ApiCall(body, "post", "check_coupen_validity", category_data);
    } else {
      // Handle case where couponCode is empty
      console.log("Coupon code is empty");
    }
  }

  const category_data = useCallback((response) => {
    if (response.status === 200) {
      // console.log(response.data.offers.percentage);
      if (response.data.offers) {
        setCouponCode(response.data.offers);
        setOfferSectionVisible("");
        setPercentage(response.data.offers.percentage);
      }
      if (courseOffer[offerSectionVisible]?.type === "fix_price") {
        setPrice(
          Math.round(
            courseOffer[offerSectionVisible]?.fix_price -
              (courseOffer[offerSectionVisible]?.fix_price *
                response.data.offers.percentage) /
                100
          )
        );
      } else if (courseOffer[offerSectionVisible]?.type === "percentage") {
        setPrice(
          Math.round(
            courseOffer[offerSectionVisible]?.final_price -
              (courseOffer[offerSectionVisible]?.final_price *
                response.data.offers.percentage) /
                100
          )
        );
      }
      // else {
      //   setPrice(
      //     Math.round(
      //       courseDetail.price -
      //         (courseDetail.price * response.data.offers.percentage) / 100
      //     )
      //   );
      // }

      setErrorMessage(response.data.message);
    } else {
      console.log("error");
      setPrice(Math.round(courseDetail.price));
    }
  });

  const handleCouponCodeChange = (e) => {
    const uppercaseText = e.target.value.toUpperCase();
    setCoupon(uppercaseText);
  };

  useEffect(() => {
    if (courseOffer[0]?.valid_till) {
      setTargetDateTime(new Date(`${courseOffer[0]?.valid_till}`).getTime());
    }
  }, [courseOffer]);

  // const targetDateTime = new Date("2024-11-01 23:55:00").getTime();
  const [countdown, setCountdown] = useState(calculateCountdown());

  function calculateCountdown() {
    const now = new Date().getTime();
    return Math.max(0, targetDateTime - now);
  }

  useEffect(() => {
    if (targetDateTime) {
      const interval = setInterval(() => {
        setCountdown(calculateCountdown());
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [targetDateTime]);

  const formatTime = (time) => {
    const seconds = Math.floor((time / 1000) % 60);
    const minutes = Math.floor((time / (1000 * 60)) % 60);
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    const days = Math.floor(time / (1000 * 60 * 60 * 24));

    return `${days} ${hours} ${minutes} ${seconds}`;
  };

  useEffect(() => {
    if (courseOffer[offerSectionVisible]?.type === "fix_price") {
      setPercentage("");
      setPrice(courseOffer[offerSectionVisible].fix_price);
    } else if (courseOffer[offerSectionVisible]?.type === "percentage") {
      const discountPercentage = courseOffer[offerSectionVisible].percentage;
      const discountedPrice = Math.round(
        courseDetail.discount -
          (courseDetail.discount * discountPercentage) / 100
      );
      setPercentage(discountPercentage);
      setPrice(discountedPrice);
    } else {
      setPrice(
        Math.round(
          courseDetail.price -
            (courseDetail.price * couponCode.percentage) / 100
        )
      );
    }
  }, [courseOffer, offerSectionVisible]);

  return (
    <div className="custom_container container">
      {/* <ToastContainer /> */}
      <div className="py-5">
        <div className="row">
          <div className="col-lg-8">
            <h2 className="ff_inter fw-semibold fs_8xl mb-5 mb-lg-0 pe-lg-5 course_heading">
              {courseDetail.name}
            </h2>
            <div className="border_light_brown d-lg-none d-block mt-5 mt-lg-0 ">
              <div className="">
                <div className="d-flex align-items-center justify-content-center">
                  <img
                    className="w-100"
                    src={`${COURSE_IMAGE_URL}${courseDetail.image}`}
                    alt={courseDetail.name}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center mt-4">
                <img
                  className="ms-5"
                  src={Image_URL + "website/live_video_icon.png"}
                  alt="live_icon"
                />
                <p className="mb-0 ff_inter fw-semibold text_grey fs_sm ms-1">
                  {courseDetail.live_classes_count} Live Classes
                </p>
              </div>
              <div className="d-flex align-items-center mt-2">
                <img
                  className="ms-5"
                  src={Image_URL + "website/record-icon.png"}
                  alt="record_icon"
                />
                <p className="mb-0 ff_inter fw-semibold text_grey fs_sm ms-1">
                  {courseDetail.recorded_classes_count} Recorded Classes
                </p>
              </div>
              <div className="d-flex align-items-center mt-2">
                <img
                  className="ms-5"
                  src={Image_URL + "website/test_icon.png"}
                  alt="test_icon"
                />
                <p className="mb-0 ff_inter fw-semibold text_grey fs_sm ms-1">
                  {courseDetail.test_count} Test
                </p>
              </div>
              <div className="p-3 mt-3">
                <h2 className="text_gradient fs-5 fw-semibold">Demo Class</h2>
              </div>
              <div className="border_light_brown mx-4 mb-3">
                {courseDetail.youtube_url != null ? (
                  <>
                    <div className="bg_classes text-center p-4">
                      <a target="_blanck" href={courseDetail.youtube_url}>
                        <img
                          src={Image_URL + "website/play_icon.png"}
                          alt="play_btn"
                        />
                      </a>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <p className="ff_inter fw-semibold text_grey px-3 fs_sm mt-2">
                  General English:- The questions will be designed to test ....
                </p>
                <div className="p-3">
                  <span className="mb-0 text_gradient fw-bold fs_3xl mb-0">
                    ₹{price ? price : courseDetail.price}
                  </span>

                  <span className=" fs_desc ms-1 text_grey ff_inter text-decoration-line-through mb-0">
                    ₹{courseDetail.discount}
                  </span>

                  <p className="fs_desc ms-1 fw-bold text_green ff_inter mb-0">
                    {percentage && percentage + "% OFF"}
                  </p>

                  <div className="d-flex gap-1 align-items-center mt-3 mb-1">
                    <input
                      className="w-100 Apply_input rounded-3 p-1"
                      type="text"
                      placeholder=" Apply promo/refferal code "
                      value={coupon}
                      onChange={handleCouponCodeChange}
                    />
                    <button
                      onClick={(e) => applyCouponCode(e)}
                      className="Apply_btn text_gradient px-4 py-1 rounded-3"
                    >
                      Apply
                    </button>
                  </div>
                  {errorMessage && (
                    <>
                      <p style={{ color: "red" }}>{errorMessage}</p>
                    </>
                  )}
                  {courseOffer.map((obj, i) => {
                    return (
                      <>
                        {" "}
                        <div className="my-3">
                          {targetDateTime && new Date() < offerEndTime && (
                            <>
                              <div className=" mt-3 h-100">
                                <div className="">
                                  <div className="bg_logo px-2 py-2 d-flex justify-content-center">
                                    {/* {courseOffer.map((obj, i) => {
                                      return (
                                        <> */}
                                    <img
                                      key={i}
                                      width={70}
                                      className="rounded-5"
                                      src={`${OFFERS_IMAGE_URL}${obj.icon}`}
                                      alt={obj.title}
                                    />
                                    {/* </>
                                      );
                                    })} */}
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center px-1 timer_box rounded-3 py-2 px-2">
                                    <div className="">
                                      <div className="">
                                        {/* {courseOffer.map((obj, i) => {
                                          return (
                                            <> */}
                                        <p className="fs-4 mb-0 fw-semibold ff_inter text_gradient">
                                          {obj.title}
                                        </p>
                                        <p
                                          key={i}
                                          className="fs_12 ff_inter mb-0"
                                        >
                                          {obj.description}
                                        </p>
                                        {/* </>
                                          );
                                        })} */}
                                      </div>
                                      {targetDateTime && (
                                        <div className="d-flex gap-1 my-2 align-items-center">
                                          <div className="bg_green py-2 px-1 text-center rounded-3">
                                            <h6 className="text-white mb-0">
                                              {
                                                formatTime(countdown).split(
                                                  " "
                                                )[0]
                                              }
                                            </h6>
                                            <h6 className="fs_8 text-white mb-0">
                                              DAYS
                                            </h6>
                                          </div>
                                          <div className="bg_green py-2 px-1 text-center rounded-3">
                                            <h6 className="text-white mb-0">
                                              {
                                                formatTime(countdown).split(
                                                  " "
                                                )[1]
                                              }
                                            </h6>
                                            <h6 className="fs_8 text-white mb-0">
                                              HOURS
                                            </h6>
                                          </div>
                                          <div className="bg_green py-2 px-1 text-center rounded-3">
                                            <h6 className="text-white mb-0">
                                              {
                                                formatTime(countdown).split(
                                                  " "
                                                )[2]
                                              }
                                            </h6>
                                            <h6 className="fs_8 text-white mb-0">
                                              MINUTES
                                            </h6>
                                          </div>
                                          <div className="bg_green py-2 px-1 text-center rounded-3">
                                            <h6 className="text-white mb-0">
                                              {
                                                formatTime(countdown).split(
                                                  " "
                                                )[3]
                                              }
                                            </h6>
                                            <h6 className="fs_8 text-white mb-0">
                                              SECONDS
                                            </h6>
                                          </div>
                                        </div>
                                      )}
                                      {/* {targetDateTime && formatTime(countdown)} */}
                                    </div>
                                    <div className="">
                                      <input
                                        type="radio"
                                        id="html"
                                        name="fav_language"
                                        defaultChecked={
                                          i === offerSectionVisible && true
                                        }
                                        value={offerSectionVisible}
                                        onChange={(e) => {
                                          setOfferSectionVisible(i);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    );
                  })}

                  {localStorage.getItem("token") ? (
                    <button
                      onClick={(e) =>
                        webhook(price ? price : courseDetail.price)
                      }
                      className="bg_gradient cursor_pointer border-0 text-white ff_inter fs-6 d-block text-center px-3 py-2 mt-3"
                    >
                      BUY NOW
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setLoginPopup(true);
                        document.body.parentElement.classList.toggle(
                          "overflow-hidden"
                        );
                      }}
                      className="bg_gradient cursor_pointer border-0 text-white ff_inter fs-6 d-block text-center px-3 py-2 mt-3"
                    >
                      BUY NOW
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between flex-wrap  align-items-center mt-lg-5 mt-3 py-2">
              <div>
                <span className="me-3 text_gradient ff_inter fw-bold">
                  Validity
                </span>
                <button className="bg_gradient text-white border-0 px-3 py-1">
                  {courseDetail.validity} Months
                </button>
              </div>
              <a
                href="tel:9812160061"
                className="Apply_btn rounded-3 mt-4 mt-md-0 align-items-center d-flex gap-3 px-3 py-1"
              >
                <div>
                  <img
                    className=""
                    width={40}
                    src={
                      Image_URL +
                      "website/Interactive_Classes_icon/For_Admission_Enquiry_icon.png"
                    }
                    alt=""
                  />
                </div>
                <div>
                  <p className="mb-0 fw-semibold">
                    For Admission Enquiry Call at
                  </p>
                  <a
                    data-rel="external"
                    className="fw-bold fs-6"
                    href="tel:9812160061"
                  >
                    +91 9812160061
                  </a>
                </div>
              </a>
            </div>
            <div className="border mt-3 mb-2 mb-sm-4 course_des"></div>
            <div className="d-none d-md-flex flex-wrap flex-md-nowrap gap-3">
              <div className="Apply_btn w-100 custom_borderdarkgreen_color rounded-3 mt-3 mt-sm-0 align-items-center d-flex gap-2 px-2 py-2">
                <div>
                  <img
                    className=""
                    width={40}
                    src={
                      Image_URL +
                      "website/Interactive_Classes_icon/Experienced-Faculties.png"
                    }
                    alt=""
                  />
                </div>
                <p className="mb-0 fw-semibold fs_14 text-black">
                  Experienced Faculties
                </p>
              </div>
              <div className="Apply_btn  w-100 custom_borderpurple_color rounded-3 mt-3 mt-sm-0 align-items-center d-flex gap-2 px-2 py-2">
                <div>
                  <img
                    className=""
                    width={40}
                    src={
                      Image_URL +
                      "website/Interactive_Classes_icon/InteractiveClasses.png"
                    }
                    alt=""
                  />
                </div>
                <p className="mb-0 fw-semibold text-black">
                  Interactive Classes
                </p>
              </div>
              <div className="Apply_btn custom_bordergreen_color w-100 rounded-3 mt-3 mt-sm-0 align-items-center d-flex gap-2 px-2 py-2">
                <div>
                  <img
                    className=""
                    width={30}
                    src={
                      Image_URL +
                      "website/Interactive_Classes_icon/Pdf&Notes.png"
                    }
                    alt=""
                  />
                </div>
                <p className="mb-0 fw-semibold text-black">Pdf & Notes</p>
              </div>
              <div className="Apply_btn w-100 custom_borderblue_color rounded-3 mt-3 mt-sm-0 align-items-center d-flex gap-2 px-2 py-2">
                <div>
                  <img
                    className=""
                    width={40}
                    src={
                      Image_URL +
                      "website/Interactive_Classes_icon/24_7-Class-Recording.png"
                    }
                    alt=""
                  />
                </div>
                <p className="mb-0 fw-semibold text-black">
                  24*7 Class Recording
                </p>
              </div>
            </div>
            <Carousel className="d-md-none">
              <Carousel.Item>
                <div className="Apply_btn custom_borderdarkgreen_color w-100 rounded-3 mt-3 mt-sm-0 align-items-center d-flex gap-2 px-2 py-3">
                  <div>
                    <img
                      className=""
                      width={30}
                      src={
                        Image_URL +
                        "website/Interactive_Classes_icon/Experienced-Faculties.png"
                      }
                      alt=""
                    />
                  </div>
                  <p className="mb-0 fw-semibold fs_14 text-black">
                    Experienced Faculties
                  </p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="Apply_btn w-100 custom_borderpurple_color rounded-3 mt-3 mt-sm-0 align-items-center d-flex gap-2 px-2 py-3">
                  <div>
                    <img
                      className=""
                      width={30}
                      src={
                        Image_URL +
                        "website/Interactive_Classes_icon/InteractiveClasses.png"
                      }
                      alt=""
                    />
                  </div>
                  <p className="mb-0 fw-semibold text-black">
                    Interactive Classes
                  </p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="Apply_btn w-100 rounded-3 custom_bordergreen_color  mt-3 mt-sm-0 align-items-center d-flex gap-2 px-2 py-3">
                  <div>
                    <img
                      className=""
                      width={30}
                      src={
                        Image_URL +
                        "website/Interactive_Classes_icon/Pdf&Notes.png"
                      }
                      alt=""
                    />
                  </div>
                  <p className="mb-0 fw-semibold text-black">Pdf & Notes</p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="Apply_btn w-100 custom_borderblue_color rounded-3 mt-3 mt-sm-0 align-items-center d-flex gap-2 px-2 py-3">
                  <div>
                    <img
                      className=""
                      width={30}
                      src={
                        Image_URL +
                        "website/Interactive_Classes_icon/24_7-Class-Recording.png"
                      }
                      alt=""
                    />
                  </div>
                  <p className="mb-0 fw-semibold text-black">
                    24*7 Class Recording
                  </p>
                </div>
              </Carousel.Item>
              {/* Add more Carousel.Item for additional slides */}
            </Carousel>
            <div className="border my-md-4 mt-5 mb-4 course_des"></div>
            <div className="course_highlight">
              <div className="bg_light_orange course_des">
                <h3 className="ff_inter text_gradient fw-semibold fs_8xl mb-0 py-3 text-center ">
                  Course Description
                </h3>
              </div>
              {courseDetail ? (
                <>
                  <p className="ff_inter fw-normal text_grey fs_xxl px-4 ms-0">
                    {parsedDesc}
                  </p>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-lg-4 mt-5 mt-lg-0">
            <div className="border_light_brown d-lg-block d-none">
              <div className="">
                <div className="d-flex align-items-center justify-content-center">
                  <img
                    className="w-100"
                    src={`${COURSE_IMAGE_URL}${courseDetail.image}`}
                    alt={courseDetail.name}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center mt-4">
                <img
                  className="ms-5"
                  src={Image_URL + "website/live_video_icon.png"}
                  alt="live_icon"
                />
                <p className="mb-0 ff_inter fw-semibold text_grey fs_sm ms-1">
                  {courseDetail.live_classes_count} Live Classes
                </p>
              </div>
              <div className="d-flex align-items-center mt-2">
                <img
                  className="ms-5"
                  src={Image_URL + "website/record-icon.png"}
                  alt="record_icon"
                />
                <p className="mb-0 ff_inter fw-semibold text_grey fs_sm ms-1">
                  {courseDetail.recorded_classes_count} Recorded Classes
                </p>
              </div>
              <div className="d-flex align-items-center mt-2">
                <img
                  className="ms-5"
                  src={Image_URL + "website/test_icon.png"}
                  alt="test_icon"
                />
                <p className="mb-0 ff_inter fw-semibold text_grey fs_sm ms-1">
                  {courseDetail.test_count} Test
                </p>
              </div>
              <div className="p-3 mt-3">
                <h2 className="text_gradient fs-5 fw-semibold">Demo Class</h2>
              </div>
              <div className="border_light_brown mx-4 mb-3">
                {courseDetail.youtube_url != null ? (
                  <>
                    <div className="bg_classes text-center p-4">
                      <a target="_blanck" href={courseDetail.youtube_url}>
                        <img
                          src={Image_URL + "website/play_icon.png"}
                          alt="play_btn"
                        />
                      </a>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <p className="ff_inter fw-semibold text_grey px-3 fs_sm mt-2">
                  General English:- The questions will be designed to test ....
                </p>
                <div className="p-3">
                  <span className="mb-0 text_gradient fw-bold fs_3xl mb-0">
                    ₹{price ? price : courseDetail.price}
                  </span>

                  <span className=" fs_desc ms-1 text_grey ff_inter text-decoration-line-through mb-0">
                    ₹{courseDetail.discount}
                  </span>

                  <p className="fs_desc ms-1 fw-bold text_green ff_inter mb-0">
                    {percentage && percentage + "% OFF"}
                  </p>

                  <div className="d-flex gap-1 align-items-center mt-3 mb-1">
                    <input
                      className="w-100 Apply_input rounded-3 p-1"
                      type="text"
                      placeholder=" Apply promo/refferal code "
                      value={coupon}
                      onChange={handleCouponCodeChange}
                    />
                    <button
                      onClick={(e) => applyCouponCode(e)}
                      className="Apply_btn text_gradient px-4 py-1 rounded-3"
                    >
                      Apply
                    </button>
                  </div>
                  {errorMessage && (
                    <>
                      <p style={{ color: "red" }}>{errorMessage}</p>
                    </>
                  )}
                  {courseOffer.map((obj, i) => {
                    return (
                      <>
                        {" "}
                        <div className="my-3">
                          {targetDateTime && new Date() < offerEndTime && (
                            <>
                              <div className=" mt-3 h-100">
                                <div className="">
                                  <div className="bg_logo px-2 py-2 d-flex justify-content-center">
                                    {/* {courseOffer.map((obj, i) => {
                                      return (
                                        <> */}
                                    <img
                                      key={i}
                                      width={70}
                                      className="rounded-5"
                                      src={`${OFFERS_IMAGE_URL}${obj.icon}`}
                                      alt={obj.title}
                                    />
                                    {/* </>
                                      );
                                    })} */}
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center px-1 timer_box rounded-3 py-2 px-2">
                                    <div className="">
                                      <div className="">
                                        {/* {courseOffer.map((obj, i) => {
                                          return (
                                            <> */}
                                        <p className="fs-4 mb-0 fw-semibold ff_inter text_gradient">
                                          {obj.title}
                                        </p>
                                        <p
                                          key={i}
                                          className="fs_12 ff_inter mb-0"
                                        >
                                          {obj.description}
                                        </p>
                                        {/* </>
                                          );
                                        })} */}
                                      </div>
                                      {targetDateTime && (
                                        <div className="d-flex gap-1 my-2 align-items-center">
                                          <div className="bg_green py-2 px-1 text-center rounded-3">
                                            <h6 className="text-white mb-0">
                                              {
                                                formatTime(countdown).split(
                                                  " "
                                                )[0]
                                              }
                                            </h6>
                                            <h6 className="fs_8 text-white mb-0">
                                              DAYS
                                            </h6>
                                          </div>
                                          <div className="bg_green py-2 px-1 text-center rounded-3">
                                            <h6 className="text-white mb-0">
                                              {
                                                formatTime(countdown).split(
                                                  " "
                                                )[1]
                                              }
                                            </h6>
                                            <h6 className="fs_8 text-white mb-0">
                                              HOURS
                                            </h6>
                                          </div>
                                          <div className="bg_green py-2 px-1 text-center rounded-3">
                                            <h6 className="text-white mb-0">
                                              {
                                                formatTime(countdown).split(
                                                  " "
                                                )[2]
                                              }
                                            </h6>
                                            <h6 className="fs_8 text-white mb-0">
                                              MINUTES
                                            </h6>
                                          </div>
                                          <div className="bg_green py-2 px-1 text-center rounded-3">
                                            <h6 className="text-white mb-0">
                                              {
                                                formatTime(countdown).split(
                                                  " "
                                                )[3]
                                              }
                                            </h6>
                                            <h6 className="fs_8 text-white mb-0">
                                              SECONDS
                                            </h6>
                                          </div>
                                        </div>
                                      )}
                                      {/* {targetDateTime && formatTime(countdown)} */}
                                    </div>
                                    <div className="">
                                      <input
                                        type="radio"
                                        id="html"
                                        name="fav_language"
                                        defaultChecked={
                                          i === offerSectionVisible && true
                                        }
                                        value={offerSectionVisible}
                                        onChange={(e) => {
                                          setOfferSectionVisible(i);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    );
                  })}

                  {localStorage.getItem("token") ? (
                    <button
                      onClick={(e) =>
                        webhook(price ? price : courseDetail.price)
                      }
                      className="bg_gradient cursor_pointer border-0 text-white ff_inter fs-6 d-block text-center px-3 py-2 mt-3"
                    >
                      BUY NOW
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setLoginPopup(true);
                        document.body.parentElement.classList.toggle(
                          "overflow-hidden"
                        );
                      }}
                      className="bg_gradient cursor_pointer border-0 text-white ff_inter fs-6 d-block text-center px-3 py-2 mt-3"
                    >
                      BUY NOW
                    </button>
                  )}
                </div>
              </div>
            </div>
            {/* <div className="bg_crack_bpsc mt-4 p-3 position-relative col-md-6 col-lg-12">
              <h2 className="text-white ff_inter fw-bold fs_8xl">BPSC CRACK</h2>
              <p className="mb-0 text-white ff_inter fs_8xl crack_bpsc_para">
                krna Hua Bahut Aasan..
              </p>
              <button className="fw-semibold ff_inter fs_sm mt-2 register_btn position-relative border-0 py-1 px-3">
                REGISTER
              </button>
              <img
                className="position-absolute bottom-0 end-0 poster"
                src={Poster}
                alt="bpsc_crack"
              />
            </div> */}
            <Link to="/free-study-material">
              <img
                className="mt-3"
                src={Image_URL + "website/Poster2.png"}
                alt="bpsc_crack"
              />
            </Link>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          var today = new Date();
          today.setHours(today.getHours() + 24);
          localStorage.setItem("offer_model_time", today);
        }}
        className="checkoutpopup"
      >
        <Modal.Header className="ms-3 pb-2 ps-0 border-bottom" closeButton>
          <Modal.Title className="text_gradient fw-bold">
            Latest Offer
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="pt-3 mt-auto" style={{ textAlign: "center" }}>
            <Row>
              <span style={{ fontWeight: "bold", fontSize: 30 }}>
                Get 10% Extra Off
              </span>
            </Row>
          </div>
          <div className="contactform p-0 shadow-none ">
            {showcode === false ? (
              <form onSubmit={(e) => handleSubmitForm(e)}>
                <div className="pt-3 mt-auto" style={{ marginBottom: 20 }}>
                  <Row>
                    <span className="">
                      Plus, get free counselling from our experts for any doubts
                      regarding the product.
                    </span>
                  </Row>
                </div>

                <input
                  type="text"
                  className="form-control"
                  max="10"
                  placeholder="10 Digit Mobile Number"
                  required
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key) || e.target.value.length >= 10) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <div className="border-top pt-3 mt-auto">
                  <Row>
                    <Col xs={12}>
                      <button
                        className="w-100 mt-4 btn-block loginshadowmain ff_inter bg_gradient text-white border-0 fs-5"
                        type="submit"
                        id="get_off"
                      >
                        Get my 10% Off
                      </button>
                    </Col>
                  </Row>
                </div>

                <div className="pt-3 mt-auto">
                  <Row>
                    <p>First-time customers only. One time use.</p>
                  </Row>
                </div>
              </form>
            ) : (
              <div className="border-top pt-3 mt-auto">
                <Row>
                  <Col xs={12}>
                    <button className="w-100 mt-4 btn-block bg_gradient border-0 text-white py-2">
                      Winter10
                    </button>
                  </Col>
                </Row>
                <div className="pt-3 mt-auto">
                  <Row>
                    <p>
                      Download Dhurina App and use above code to get extra 10%
                      off on your first purchase.
                    </p>
                  </Row>
                </div>
                <div className="main-banner-txt banner-inner text-md-start text-center mb-0 mb-sm-5">
                  <div className="download-btn ms-0 ms-sm-2 mt-4">
                    <p className="f-15 text-black-new align-items-center d-flex">
                      <span>Download App Now</span>{" "}
                    </p>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.dhurina"
                      target="_blank"
                      rel="noreferrer"
                      className="downloadappbtnmob mt- mb-3 d-inline-block me-3"
                    >
                      <img
                        alt="Google Play"
                        src={Image_URL + "img/google-play-button.png"}
                      />
                    </a>
                    <a
                      href="https://apps.apple.com/in/app/dhurina/id1479858722"
                      target="_blank"
                      className="downloadappbtnmob"
                      rel="noreferrer"
                    >
                      <img
                        alt="App Store"
                        src={Image_URL + "img/app-store-button.png"}
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SingleCourse;
