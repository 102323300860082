import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Image,
  Breadcrumb,
} from "react-bootstrap";
import Applink from "../../include/applink/googleApple";
import MobappImage from "./../../assets/img/mob-app.png";
import ApiCall from "../../api/callApi";
import { useNavigate } from "react-router-dom";
const IMAGE_URL = process.env.REACT_APP_Bucket_URL + "events/";
const Image_URL = process.env.REACT_APP_Bucket_URL;

function LiveClasses() {
  const [status, setStatus] = useState(1);
  const [data, setData] = useState([]);
  const [mainloading, setMainLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (
      localStorage.getItem("token") === "" ||
      localStorage.getItem("token") === "null" ||
      localStorage.getItem("token") === undefined ||
      localStorage.getItem("token") === null
    ) {
      navigate("/login");
    } else {
      getData();
    }
  }, []);

  function getData() {
    ApiCall("", "get", "live_classes", courseareacallback);
  }

  const courseareacallback = useCallback((response) => {
    setMainLoading(false);
    // console.log(response.data);
    if (response.data.status === "true") {
      setData(response.data.data);
    } else {
      setStatus(0);
    }
  });

  if (mainloading === true) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <img
          src={Image_URL + "website/priloader_icon.svg"}
          style={{ alignSelf: "center" }}
        />{" "}
      </div>
    );
  }

  const RouteChange = (e) => {
    navigate("/search-result?keyword=" + searchInput, {
      state: { course: searchInput },
    });
  };

  return (
    <>
      <section className="breadcrumb-area pb-4 banner-area innerpagesbanner">
        <Container>
          <Row className="justify-content-center">
            <Col lg={12} className="align-self-center">
              <Breadcrumb className="mb-1">
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/my-courses">My Courses</Breadcrumb.Item>
                <Breadcrumb.Item active>Live Classes</Breadcrumb.Item>
              </Breadcrumb>
              <Row>
                <Col md={12}>
                  <h3 className="mb-0 f-25mob">Live Classes</h3>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="trending-courses-area py-5">
        <Container>
          {status == 0 ? (
            <h6 className="mt-4 mb-3" style={{ margin: "5%" }}>
              No live class found!!
            </h6>
          ) : (
            <Row>
              {data.map((value, key) => {
                return (
                  <Col md={3} key={key}>
                    <Link to={"/live-streaming/" + value.id}>
                      <div className="single-course-wrap video-bgdemo">
                        <div className="thumb">
                          {value.live_status == 1 ? (
                            <span className="cat cat-green">Live</span>
                          ) : (
                            <span className="cat cat-blue">Off</span>
                          )}
                          <Image src={`${IMAGE_URL}${value.image}`} alt="img" />
                        </div>
                        <div className="wrap-details">
                          <h6 className="mb-1">{value.title}</h6>
                          <div className="price-wrap pt-0">
                            <Row className="align-items-center">
                              <Col xs={12}>
                                <span className="f-11 mt-1 text-muted fw-bold mob-linefacultyheight">
                                  <i className="far fa-clock"></i> {value.time}
                                </span>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </Col>
                );
              })}
            </Row>
          )}
        </Container>
      </section>

      <section className="pt-3 map-bg bg-theme-red">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col md={3}>
              <Image alt="img" src={MobappImage} />
            </Col>
            <Col md={6} className="downloadappbtnmob mb-4 schoolinner-mob-app">
              <div className="section-title mb-4">
                <h2 className=" text-start text-white pb-2">
                  Download Dhurina App
                </h2>
                <h5 className="text-white fw-medium f-22">
                  Get Amazing Offers, Free Notes and scholarship
                </h5>
              </div>
              <Applink></Applink>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default LiveClasses;
